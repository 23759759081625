export const home = '/';
export const admin = '/admin';
export const allAccts = 'all-accounts';
export const acctBrkdwn = 'account-breakdown';
export const referrals = 'referrals';
export const userMnthBrkdwn = 'user-monthly-breakdown';
export const allCards = 'all-cards';
export const blacklisted = 'blacklisted';
export const purse = 'purse';
export const indCont = 'individual-contributions';
export const tarGrpCon = 'target-group-contributions';
export const coopGrpCon = 'cooperative-group-contributions';
export const allTrans = 'transactions';
export const actRep = 'activity-report';
export const profile = 'all-accounts/:id';
export const groupSavingsView = 'group-savings-view';
export const commission = 'commission';
export const editCommission = 'commission/:id';
