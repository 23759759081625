import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as BackIcon } from '../../assets/icons/back_arrow.svg';
import { ReactComponent as PlusIcon } from '../../assets/icons/plus.svg';
import SearchBar from '../../components/SearchBar';
import SetCommission from '../../components/SetCommission';
import VeiwCommission from '../../components/VeiwCommission';
import Button from '../../components/bits/Button';
import LoadingRoller from '../../components/bits/LoadingRoller';
import { useGetCommissionsQuery } from '../../redux/services/commission-services';

// export type CommissionFormProps = {
//   commissionForName: string;
//   commissionForValue: string;
//   commissionModeValue: string;
//   commissionModeName: string;
//   amount: number;
//   percentage: number;
//   fee: number;
//   range: boolean;
//   max: number;
//   min: number;
// };

const Commission = () => {
  // const [modalOpen, setModalOpen] = useState(false);
  // const [loading, setLoading] = useState(false);
  const [viewCommission, setViewCommission] = useState(false);
  const { data, refetch, isLoading, isSuccess }: any = useGetCommissionsQuery();

  // const [formData, setFormData] = useState<CommissionFormProps>({
  //   commissionForValue: '',
  //   commissionForName: '',
  //   commissionModeValue: '',
  //   commissionModeName: '',
  //   amount: 0,
  //   percentage: 0,
  //   fee: 0,
  //   range: false,
  //   max: 0,
  //   min: 0,
  // });

  // React.useEffect(() => {
  //   refetch();
  // }, [refetch]);

  // if (isSuccess) console.log(data);

  // const handleCommissionModeChange = (e: ChangeEvent<HTMLInputElement>) => {
  //   const { name, value } = e.target;

  //   setFormData(currData => {
  //     return {
  //       ...currData,
  //       commissionModeName: name,
  //       commissionModeValue: value,
  //     };
  //   });
  //   console.log(name, value);
  // };

  // const handleCommissionForChange = (e: ChangeEvent<HTMLInputElement>) => {
  //   const { name, value } = e.target;

  //   setFormData(currData => {
  //     return {
  //       ...currData,
  //       commissionForName: name,
  //       commissionForValue: value,
  //     };
  //   });

  //   console.log(name, value);
  // };

  // const handleSelectChange = (e: ChangeEvent<HTMLInputElement>) => {
  //   const { name, checked } = e.target;

  //   setFormData(currData => {
  //     return {
  //       ...currData,
  //       [name]: checked,
  //     };
  //   });
  // };

  // const handleFormDataChange = (e: ChangeEvent<HTMLInputElement>) => {
  //   const { name, value } = e.target;

  //   setFormData(currData => {
  //     return {
  //       ...currData,
  //       [name]: value,
  //     };
  //   });
  // };

  // const setCommission = async function () {
  //   const res = await addCommission({
  //     commission_for: formData.commissionForValue,
  //     commission_mode: formData.commissionModeValue,
  //     amount: formData.amount,
  //     percentage: formData.percentage,
  //     price_range: formData.range,
  //     maximum: formData.max,
  //     minimum: formData.min,
  //   });

  //   console.log(res);
  // };

  useEffect(() => {
    refetch();
  }, [refetch]);

  return (
    <>
      <Wrapper>
        {isSuccess && (
          <div className='page-h top-header'>
            <h2 style={{ fontWeight: 600 }}>
              {!viewCommission ? 'All Commissions' : 'Set Commission'}
            </h2>
            <div className='search'>
              <SearchBar
                width={'96%'}
                // setCurrPageInfo={setCurrPageInfo}
                // refetch={refetch}
                // value={currPageInfo.searchTerm}
              />
            </div>
          </div>
        )}
        <div className='commission'>
          {isLoading ? (
            <LoadingRoller />
          ) : data?.length > 0 && !viewCommission ? (
            <div className='view_commission'>
              <span className='d-flex justify-content-end py-2'>
                <Button
                  bg='white'
                  color='#33277b'
                  border='#33277b'
                  fontSize='16px'
                  className='me-4 d-flex align-items-center gap-2'
                  onClick={() => setViewCommission(true)}
                >
                  <PlusIcon />
                  <span>Add New Rule</span>
                </Button>
              </span>
              <VeiwCommission
                data={data}
                refetch={refetch}
                setViewCommission={setViewCommission}
              />
            </div>
          ) : (
            <>
              <Button
                bg='white'
                color='#33277b'
                border='#33277b'
                // fontSize='16px'
                className='me-4 d-flex align-items-center gap-2'
                onClick={() => setViewCommission(false)}
              >
                <BackIcon />
                <span>Return</span>
              </Button>
              <SetCommission
                setViewCommission={setViewCommission}
                refetch={refetch}
              />
            </>
          )}
        </div>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  .top-header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    .search {
      margin-left: auto;
      min-width: 500px;
    }
  }

  display: flex;
  flex-direction: column;
  .page-h {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .search-div {
      margin-left: auto;
      min-width: 500px;
    }
  }

  .commission {
    background: #fff;
    padding: 3rem 1rem;
    border-radius: 0.5rem;
    box-shadow: 0px 16px 37px rgba(188, 188, 188, 0.1);

    .select_container {
      padding: 3rem 1rem;

      .select {
        display: grid;
        grid-template-columns: 1fr 3fr;
        grid-gap: 40px;
        align-items: center;
      }
      /*  .checkbox {
        display: block;
        background: #f9fffb;
        width: 30px;
        height: 30px;
        border: 1px solid #bec6df;
        transition: all 0.3s;

        &:focus {
          outline: none;
          border: 0.5px solid #bec6df;
          background-color: none;
        }

        &.checked {
          appearance: none;
          background: red;
        }
      } */

      .range {
        margin-top: 30px;

        h6 {
          color: #909090;
        }

        &__container {
          padding: 2rem 1rem;
          border: 1px solid #d9d9d9;
          border-radius: 8px;
        }

        font-size: 20px;
      }
    }
  }
`;

export default Commission;
