import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import styled from "styled-components";

import { ReactComponent as DashboardIcon } from "../assets/icons/dashboard.svg";
import { ReactComponent as ClosedNavIcon } from "../assets/icons/dropdown.svg";
import { ReactComponent as OpenNavIcon } from "../assets/icons/dropup.svg";
import logo from "../assets/icons/logo2 1.svg";
import { ReactComponent as LogoutIcon } from "../assets/icons/logout.svg";
import { ReactComponent as ActivityReportIcon } from "../assets/icons/paper.svg";
import { ReactComponent as ProfileIcon } from "../assets/icons/profile.svg";
import { ReactComponent as SettingsIcon } from "../assets/icons/settings.svg";
import { ReactComponent as TransactionsIcon } from "../assets/icons/transactions.svg";
import { ReactComponent as SavingsIcon } from "../assets/icons/wallet.svg";
import { logoutUser } from "../helpers/authHelper";
import {
  acctBrkdwn,
  actRep,
  admin,
  allAccts,
  allCards,
  allTrans,
  blacklisted,
  commission,
  coopGrpCon,
  indCont,
  purse,
  referrals,
  tarGrpCon,
  userMnthBrkdwn,
} from "../utils/routes";

import Modal from "./Modal";
import Warning from "./Warning";

type LNavbarProps = {
  setOpen: Function;
};

const LNavbar = ({ setOpen }: LNavbarProps) => {
  const [showList1, setShowList1] = useState<boolean>(false);
  const [showList2, setShowList2] = useState<boolean>(false);
  const [showList3, setShowList3] = useState<boolean>(false);
  const [showList4, setShowList4] = useState<boolean>(false);
  const [logoutOpen, setLogoutOpen] = useState<boolean>(false);

  return (
    <>
      <LNavbar.Wrapper onClick={() => setOpen(false)}>
        <div className="container">
          <Link to={admin} className="nav-logo">
            <img src={logo} alt="Adashi-Logo" />
          </Link>
          <ul>
            <li className="nav-item">
              <NavLink to={admin} className="nav-link">
                <DashboardIcon className="nav-svg" />
                <span className="ms-3">Dashboard</span>
              </NavLink>
            </li>
            <li className="nav-item" onClick={() => setShowList1(!showList1)}>
              <div className="nav-link">
                <ProfileIcon className="nav-svg" />
                <span className="ms-3">Accounts</span>
                {showList1 === true ? (
                  <OpenNavIcon style={{ marginLeft: "3.5rem" }} />
                ) : (
                  <ClosedNavIcon style={{ marginLeft: "3.5rem" }} />
                )}
              </div>
              {showList1 && (
                <ul className="sub-nav">
                  <li>
                    <NavLink to={allAccts} className="nav-link">
                      <ProfileIcon className="nav-svg" />
                      <span className="ms-3">All Accounts</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to={acctBrkdwn} className="nav-link">
                      <ProfileIcon className="nav-svg" />
                      <span className="ms-3">Account Breakdown</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to={referrals} className="nav-link">
                      <ProfileIcon className="nav-svg" />
                      <span className="ms-3">Referrals</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to={userMnthBrkdwn} className="nav-link">
                      <ProfileIcon className="nav-svg" />
                      <span className="ms-3">User Monthly Breakdown</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to={allCards} className="nav-link">
                      <ProfileIcon className="nav-svg" />
                      <span className="ms-3">All Cards</span>
                    </NavLink>
                  </li>
                  {/* <li>
                    <NavLink to={admin} className="nav-link">
                      <ProfileIcon className="nav-svg" />
                      <span className="ms-3">Customer Review</span>
                    </NavLink>
                  </li> */}
                  <li>
                    <NavLink to={blacklisted} className="nav-link">
                      <ProfileIcon className="nav-svg" />
                      <span className="ms-3">Blacklisted Accounts</span>
                    </NavLink>
                  </li>
                </ul>
              )}
            </li>
            <li className="nav-item" onClick={() => setShowList2(!showList2)}>
              <div className="nav-link">
                <SavingsIcon className="nav-svg" />
                <span className="ms-3">Contributions</span>
                {showList2 ? (
                  <OpenNavIcon style={{ marginLeft: "1.35rem" }} />
                ) : (
                  <ClosedNavIcon style={{ marginLeft: "1.35rem" }} />
                )}
              </div>
              {showList2 && (
                <ul className="sub-nav">
                  <li>
                    <NavLink to={purse} className="nav-link">
                      <SavingsIcon className="nav-svg" />
                      <span className="ms-3">Purse</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to={indCont} className="nav-link">
                      <SavingsIcon className="nav-svg" />
                      <span className="ms-3">Individual Plans</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to={tarGrpCon} className="nav-link">
                      <SavingsIcon className="nav-svg" />
                      <span className="ms-3">Target Group Contributions</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to={coopGrpCon} className="nav-link">
                      <SavingsIcon className="nav-svg" />
                      <span className="ms-3">Co-operative Contributions</span>
                    </NavLink>
                  </li>
                </ul>
              )}
            </li>
            <li className="nav-item" onClick={() => setShowList3(!showList3)}>
              <div className="nav-link">
                <TransactionsIcon className="nav-svg" />
                <span className="ms-3">Transactions</span>
                {showList3 ? (
                  <OpenNavIcon style={{ marginLeft: "1.5rem" }} />
                ) : (
                  <ClosedNavIcon style={{ marginLeft: "1.5rem" }} />
                )}
              </div>
              {showList3 && (
                <ul className="sub-nav">
                  <li>
                    <NavLink to={allTrans} className="nav-link">
                      <TransactionsIcon className="nav-svg" />

                      <span className="ms-3">All Transactions</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to={admin} className="nav-link">
                      <TransactionsIcon className="nav-svg" />

                      <span className="ms-3">Record Analysis</span>
                    </NavLink>
                  </li>
                </ul>
              )}
            </li>
            <li className="nav-item">
              <NavLink to={actRep} className="nav-link">
                <ActivityReportIcon className="nav-svg" />
                <span className="ms-3">Activity Report</span>
              </NavLink>
            </li>
            <li className="nav-item" onClick={() => setShowList4(!showList4)}>
              <div className="nav-link">
                <SettingsIcon className="nav-svg" />
                <span className="ms-3">Settings</span>
                {showList4 ? (
                  <OpenNavIcon style={{ marginLeft: "3.85rem" }} />
                ) : (
                  <ClosedNavIcon style={{ marginLeft: "3.85rem" }} />
                )}
              </div>
              {showList4 && (
                <ul className="sub-nav">
                  <li>
                    <NavLink to={admin} className="nav-link">
                      <SettingsIcon className="nav-svg" />
                      <span className="ms-3">Broadcast Message</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to={commission} className="nav-link">
                      <SettingsIcon className="nav-svg" />
                      <span className="ms-3">Set Commission</span>
                    </NavLink>
                  </li>
                </ul>
              )}
            </li>
            <li className="nav-item">
              <NavLink
                to="#"
                className="nav-link logout-btn"
                onClick={() => setLogoutOpen(true)}
              >
                <LogoutIcon className="nav-svg" />
                <span className="ms-3">Logout</span>
              </NavLink>
            </li>
          </ul>
        </div>
      </LNavbar.Wrapper>
      <Modal isShown={logoutOpen} hide={() => {}}>
        <Warning
          warningTitle={"Confirm Logout"}
          warningText={<p>Are you sure you want to logout?</p>}
          closeModal={() => setLogoutOpen(false)}
          confirm={() => logoutUser()}
          confirmText={"OK"}
        />
      </Modal>
    </>
  );
};

LNavbar.Wrapper = styled.div`
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: -webkit-fill-available;

    ul {
      list-style-type: none;
      margin: 0px;
      padding: 0px;
      .sub-nav {
        font-weight: 200;
      }
    }

    .nav-logo {
      margin-bottom: 4rem;
    }

    .nav-item {
      cursor: pointer;
      margin: 1rem 0;

      .nav-link {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: #33277b;
        padding: 0.5rem 1rem;
        margin: 1rem;
        border-radius: 20px;
        font-size: 18px;
        font-style: normal;

        &.logout-btn {
          color: #e20010;
          margin-top: 30px;

          .nav-svg {
            stroke: #e20010;
          }
        }
      }

      .nav-svg {
        // fill: #33277b;
        // stroke: #33277b;
        width: 25px;
        height: 25px;
      }

      .activ {
        color: #fff;
        background: #33277bcc;
        .nav-svg {
          // fill: #fff;
        }
      }

      .nav-link:hover,
      .nav-link:focus,
      .nav-link:active {
        color: #fff;
        background: #33277bcc;
        .nav-svg {
          fill: #fff;
        }

        &.logout-btn:hover,
        &.logout-btn:focus,
        &.logout-btn:active {
          color: #fff;
          background: #e20010;
          .nav-svg {
            fill: none;
            stroke: #fff;
          }
        }
      }
    }
  }

  @media (max-width: 800px) {
    width: 250px;
    background: #ffffff;
    // height: -webkit-fill-available;
    min-height: 100vh;
  }
`;

export default LNavbar;
