import { Skeleton } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import FilterCard from '../../components/FilterCard';
import Pagination, { CurrPageInfoTypes } from '../../components/Pagination';
import SearchBar from '../../components/SearchBar';
import NoData from '../../components/bits/NoData';
import { useGetBlackListedAccountsQuery } from '../../redux/services/dashboard-services';

/* type BlacklistedAccountTypes = {
  name: string;
  email: string;
  offence: string;
  date: string;
}; */

/* const data: BlacklistedAccountTypes[] = [
  {
    name: 'Folake John',
    email: 'folake@gmail.com',
    offence: 'Fraud suspicion',
    date: '03/05/2022',
  },
  {
    name: 'Martha Peters',
    email: 'martha@gmail.com',
    offence: 'False BVN',
    date: '03/05/2022',
  },
]; */

const BlacklistedAccounts = () => {
  // const isLoading = false;

  const [currPageInfo, setCurrPageInfo] = useState<CurrPageInfoTypes>({
    pageNo: 1,
    pageSize: 10,
    pageTotalCount: 10,
    pageCount: 1,
    status: '',
    searchTerm: '',
    startDate: null,
    endDate: null,
    startDateString: '',
    endDateString: '',
  });

  const { data, isLoading, refetch, isSuccess }: any =
    useGetBlackListedAccountsQuery({
      pageNo: currPageInfo.pageNo,
      pageSize: currPageInfo.pageSize,
      status: currPageInfo.status,
      searchTerm: currPageInfo.searchTerm,
      duration:
        currPageInfo.startDate && currPageInfo.endDate
          ? `${moment(currPageInfo.startDate).format('YYYY-MM-DD')},${moment(
              currPageInfo.endDate
            ).format('YYYY-MM-DD')}`
          : '',
    });

  useEffect(() => {
    refetch();
  }, [refetch]);

  console.log(data);
  return (
    <Wrapper>
      {isSuccess && (
        <>
          <div className='top-header'>
            <h2 style={{ fontWeight: 600, marginBottom: '1rem' }}>
              Blacklisted Accounts
            </h2>
            <div className='search'>
              <SearchBar
                width={'90%'}
                setCurrPageInfo={setCurrPageInfo}
                refetch={refetch}
                value={currPageInfo.searchTerm}
              />
            </div>
          </div>
          {data.data?.data.length > 0 && (
            <FilterCard
              startDate={true}
              endDate={true}
              setCurrPageInfo={setCurrPageInfo}
              refetch={refetch}
            />
          )}
        </>
      )}
      <div className='trans-table'>
        {isLoading ? (
          <Skeleton active />
        ) : data.data.data?.length > 0 ? (
          <>
            <table className='table-borderless'>
              <thead className='header'>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Offence</th>
                  <th>Date Blacklisted</th>
                </tr>
              </thead>
              <tbody>
                {data.data.data?.map((item: any, index: number) => {
                  return (
                    <tr key={index}>
                      <td className='text-capitalize'>{`${item.last_name} ${item.name} `}</td>
                      <td>{item.email}</td>
                      <td>{item.offence}</td>
                      <td>{item.blacklisted_date}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </>
        ) : (
          <div className='mt-4 pt-4'>
            <NoData text='There are currently no blacklisted accounts.' />
          </div>
        )}
      </div>
      {isSuccess && (
        <Pagination
          transPerPage={data.data?.per_page}
          totalTrans={data.data?.total}
          currentPage={data.data?.current_page}
          lastPage={data.data?.last_page}
          setCurrPageInfo={setCurrPageInfo}
          // pageLimit={parseInt(data.data?.per_page)}
          currPageInfo={currPageInfo}
          refetch={refetch}
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .top-header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    .search {
      margin-left: auto;
      min-width: 500px;
    }
  }
  .trans-table {
    display: flex;
    flex-direction: column;
    background: #ffffff;
    box-shadow: 0px 16px 37px rgba(188, 188, 188, 0.1);
    border-radius: 15px;
    padding: 2rem 1rem !important;
    font-size: 16px;
    overflow-x: auto;

    table {
      th,
      td {
        padding: 0.5rem;
      }
      td {
        color: #707070;
      }
    }
    .header {
      color: #333333;
      border-top: 1px solid #999999;
      border-bottom: 1px solid #999999;
    }
    .status {
      // width: 20%;
    }

    @media screen and (min-width: 500px) {
      .gd {
        margin: 0rem 2rem 0rem 0rem;
        padding: 0px;
      }
    }

    @media screen and (max-width: 500px) {
      .trans-table {
        overflow-x: auto;
        // width: fit-content;
        table {
          th,
          td {
            // font-size: 12px !important;
          }
        }
      }
    }
  }
`;

export default BlacklistedAccounts;
