import { GetDashBoardSummary } from '../redux/services/dashboard-services';
import DCard from './DCard';

const Revenues = ({ revenue }: any) => {
  const {
    total_revenue,
    total_charge,
    yesterday,
    this_week,
    this_month,
  }: GetDashBoardSummary = revenue;

  return (
    <div className='row'>
      <div className='col-md-6 col-lg-4 col-xl-3 p-3'>
        <DCard
          title='Total Revenue'
          amount={`${total_revenue}`}
          bColor={'#33277B'}
        />
      </div>
      <div className='col-md-6 col-lg-4 col-xl-3 p-3'>
        <DCard title='Total Charge' amount={`${total_charge}`} />
      </div>
      <div className='col-md-6 col-lg-4 col-xl-3 p-3'>
        <DCard title='Yesterday' amount={`${yesterday}`} />
      </div>
      <div className='col-md-6 col-lg-4 col-xl-3 p-3'>
        <DCard title='This Week' amount={`${this_week}`} />
      </div>
      <div className='col-md-6 col-lg-4 col-xl-3 p-3'>
        <DCard title='This Month' amount={`${this_month}`} />
      </div>
    </div>
  );
};

export default Revenues;
