import styled from 'styled-components';

import UsersBarChart from './charts/UsersBarChart';
import UsersDoughnut from './charts/UsersDoughnut';

const UsersAnalytics = ({
  usersAnalytics,
  overviewData,
  setOverviewData,
}: any) => {
  return (
    <UsersAnalytics.Wrapper className='row'>
      <div className='col-md-6 p-4'>
        <UsersBarChart
          overview={usersAnalytics?.overview}
          setOverviewData={setOverviewData}
          overviewData={overviewData}
        />
      </div>
      <div className='col-md-6 p-4'>
        <UsersDoughnut usersAnalytics={usersAnalytics} />
      </div>
    </UsersAnalytics.Wrapper>
  );
};

UsersAnalytics.Wrapper = styled.div`
  .card2-l {
    padding: 3rem;
    // padding-left: 0;
  }
  .card2-r {
    padding: 3rem;
    // // padding-right: 0;
  }
  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .card1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // padding: 1rem;
    background: rgba(207, 232, 222, 0.2);
    border-radius: 20px;
    cursor: pointer;
  }

  .card1:hover,
  .card1:focus,
  .card1:active {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  }
`;

export default UsersAnalytics;
