export function formatNumber(num: string) {
  if (num) return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') || 0;
}

export const format4dpNumber = (number: any) => {
  const roundedValue = Number(number).toFixed(2).toString();

  return roundedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const formatString = (str: string) => {
  return str
    .slice()
    .split('_')
    .map(x => x.replace(/\b\w/g, c => c.toUpperCase()))
    .join(' ');
};
