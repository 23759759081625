import React from 'react';
import styled from 'styled-components';
import { format4dpNumber } from '../helpers/formatNumbers';

/* interface DataType {
  group_name: string;
  no_of_members: string;
  frequency: string;
  target_amount: number;
  start_date: string;
  end_date: string;
  payout_order: number;
  payout_status: string;
} */

/* const data: DataType[] = [
  {
    groupName: 'Individual',
    nOfMembers: 'Group Savings ',
    frequency: 'weekly',
    targetAmt: 300000,
    startDate: '02/04/2022',
    payoutDate: '02/04/2022',
    payOutOrder: 2,
    payStatus: 'Collected',
  },
  {
    groupName: 'Individual',
    nOfMembers: 'Group Savings ',
    frequency: 'weekly',
    targetAmt: 300000,
    startDate: '02/04/2022',
    payoutDate: '02/04/2022',
    payOutOrder: 2,
    payStatus: 'pending',
  },
]; */

const GroupSavings = ({ coop }: any) => {
  return (
    <Wrapper>
      <h4 className='heading'>Cooperative Group Details</h4>
      <table className='table-f'>
        <thead className='header'>
          <tr>
            <th>Group Name</th>
            <th>No of Members</th>
            <th>Frequency</th>
            <th>Target Amount </th>
            <th>Start Date</th>
            <th>Payout Date</th>
            <th>Payout Order</th>
            <th>Payout Status </th>
          </tr>
        </thead>
        <tbody>
          {coop.map((item: any, index: any) => (
            <tr key={index}>
              <td>{item.group_name}</td>
              <td className='text-center'>{item.no_of_members}</td>
              <td className='text-center'>{item.frequency}</td>
              <td className='text-center'>
                {format4dpNumber(item.target_amount)}
              </td>
              <td>{item.start_date}</td>
              <td>{item.end_date}</td>
              <td className='text-center'>{item.payout_order ?? 'None'}</td>
              <td
                className={`btn ${
                  item.payout_status === 'pending' ? 'failed' : 'success'
                }`}
              >
                {item.payout_status}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 1rem 0;
  .heading {
    font-size: 1.6rem;
    font-weight: 600;
    color: #585757;
  }

  .table-f {
    width: 100%;
    /* box-shadow: 0px 16px 37px rgba(188, 188, 188, 0.1); */
    border-radius: 15px;
    text-transform: capitalize;
    padding: 1rem;

    .header {
      color: #707070;
      font-size: 1.1rem;
      border-bottom: 1px solid #707070;
      border-top: 1px solid #707070;
    }
    th {
      padding: 0.5rem;
    }
    td {
      padding: 0.5rem;
      color: #707070;
      font-size: 0.95rem;
      font-weight: 600;
    }

    .options {
      border-radius: 50%;
      width: 20px;
      height: 20px;

      &.option--failed {
        background: #ea0505b2;
      }
      &.option--success {
        background: #059157;
      }
    }

    .btn {
      /* width: 7rem; */
      margin: 0.2rem;
      display: flex;
      align-content: center;
      justify-content: center;

      &.failed {
        color: #f2990a;
        background: #fdf0da;
        border-radius: 5px;
        padding: 0.5rem;
      }
      &.success {
        color: #059157;
        background: rgba(207, 232, 222, 0.5);
        border-radius: 5px;
        padding: 0.5rem;
      }
    }
  }
`;

export default GroupSavings;
