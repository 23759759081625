// import { message } from "antd";
import React, {
  // ChangeEvent,
  // FormEvent,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import acctdp from '../assets/img/acctdp.png';
import backArrow from '../assets/img/arrow-left.png';
import editImg from '../assets/img/edit-img.png';
import { useGetUserProfileQuery } from '../redux/services/auth-services';

// import Button from './bits/Button';
import MembersDetails from './MembersDetails';
import Input from './bits/InputText';
// import LoadingButton from './bits/LoadingButton';
import LoadingRoller from './bits/LoadingRoller';
import NoData from './bits/NoData';
// import Pagination from './Pagination';

interface FormDataProps {
  groupName: string;
  dateCreated: string;
  firstCycleCollectionDate: string;
  expectedMaturityDate: string;
  avatarUrl: string;
  avatarFile: File | string;
}

const GroupSavingsView = () => {
  // const [isLoading, setIsLoading] = useState<boolean>(false);
  // const isLoading = false;
  const navigate = useNavigate();
  const {
    data: userData,
    isLoading: dataLoading,
    refetch,
  }: any = useGetUserProfileQuery();
  const [formData, setFormData] = useState<FormDataProps>({
    avatarUrl: '',
    avatarFile: '',
    groupName: '',
    dateCreated: '',
    firstCycleCollectionDate: '',
    expectedMaturityDate: '',
  });
  // const [updateUser] = useUpdateUserMutation();
  // const [uploadImage] = useUploadImageMutation();

  useLayoutEffect(() => {
    setFormData(currData => {
      return {
        ...currData,
        groupName: userData?.name,
        dateCreated: userData?.date,
        firstCycleCollectionDate: userData?.date,
        avatarUrl: userData?.user_profile.avatar,
        avatarFile: '',
        expectedMaturityDate: userData?.date,
      };
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // let avatarUrl = "";

  const profileData = [
    {
      key: 1,
      name: 'groupName',
      label: 'Group Name',
      value: formData.groupName,
      type: 'text',
      disabled: true,
      required: true,
    },
    {
      key: 2,
      name: 'dateCreated',
      label: 'Date Created',
      value: formData.dateCreated,
      type: 'date',
      disabled: true,
      required: true,
    },
    {
      key: 3,
      name: 'firstCycleCollectionDate',
      label: 'First Cycle Collection Date',
      value: formData.firstCycleCollectionDate,
      type: 'date',
      disabled: true,
      required: true,
    },
    {
      key: 4,
      name: 'expectedMaturityDate',
      label: 'Expected Maturity Date',
      value: formData.expectedMaturityDate,
      type: 'date',
      disabled: true,
      required: true,
    },
  ];

  // const handleFile = (e: ChangeEvent<HTMLInputElement>) => {
  //   if (e.target.files?.length) {
  //     setFormData1({
  //       ...formData1,
  //       avatarUrl: URL.createObjectURL(e.target.files[0]),
  //       avatarFile: e.target.files[0],
  //     });
  //   }
  // };

  // const handleProfileChange = (e: ChangeEvent<HTMLInputElement>) => {
  //   const { name, value } = e.target;

  //   setFormData1({
  //     ...formData1,
  //     [name]: value,
  //   });
  // };

  // const handleKinChange = (e: ChangeEvent<HTMLInputElement>) => {
  //   const { name, value } = e.target;

  //   setFormData2({
  //     ...formData2,
  //     [name]: value,
  //   });
  // };

  // let user = userData();

  // const submitHandler = async (
  //   e: FormEvent<HTMLFormElement>
  // ): Promise<void> => {
  //   e.preventDefault();
  //   try {
  //     setIsLoading(true);
  //     if (formData1.avatarFile) {
  //       const imgUploadData = new FormData();
  //       imgUploadData.append("image", formData1.avatarFile);
  //       const res: any = await uploadImage(imgUploadData);
  //       if (res?.data?.status === "success") {
  //         // console.log("success");
  //         avatarUrl = res.data?.data?.url;
  //       } else {
  //         message.error(res?.error?.data?.message);
  //       }
  //       setIsLoading(false);
  //     }

  //     const res: any = await updateUser({
  //       firstname: formData1.firstName,
  //       lastname: formData1.lastName,
  //       address: formData1.address,
  //       avatar: avatarUrl,
  //       next_of_kin_name: formData2.fullName,
  //       next_of_kin_number: formData2.phoneNo,
  //       next_of_kin_email: formData2.email,
  //       next_of_kin_relationship: formData2.relationship,
  //       date_of_birth: formData1.dob,
  //       meta: "{'extra': 'valid'}",
  //     });
  //     if (res?.data?.status === "success") {
  //       localStorage.setItem("user", JSON.stringify(res?.data?.data));
  //       message.success("User profile updated successfully");
  //     } else {
  //       message.error(res?.error?.data?.message);
  //     }
  //     setIsLoading(false);
  //   } catch (error: any) {
  //     message.error(error?.data?.message);
  //     setIsLoading(false);
  //   }
  // };

  return (
    <Wrapper>
      <div className='icons-container col-md-10 mx-auto'>
        <div className='icon-back'>
          <img src={backArrow} alt='' onClick={() => navigate(-1)} />
        </div>
        <div className='icons '>
          <h4>View Group Savings</h4>
          <div className='icons-btn'>
            <span>Edit</span>
            <img src={editImg} alt='' />
          </div>
        </div>
      </div>
      <section className='form-container col-md-10 mx-auto'>
        {dataLoading ? (
          <LoadingRoller />
        ) : userData ? (
          <>
            <div className='form-inner'>
              <div className='user-info'>
                <div className='icon'>
                  {formData.avatarUrl ? (
                    <img
                      src={formData.avatarUrl}
                      alt='profile icon'
                      className='profile-icon'
                    />
                  ) : (
                    <img
                      src={acctdp}
                      alt='profile icon'
                      className='profile-icon'
                    />
                  )}
                </div>
                <div className='form-body'>
                  {profileData.map((profile, id) => (
                    <div key={id} className='profile'>
                      <p className='label'>{profile.label}</p>
                      {
                        <Input
                          key={profile.key}
                          name={profile.name}
                          type={profile.type}
                          value={
                            profile.type === 'date'
                              ? profile?.value?.toString().slice(0, 10) || ''
                              : profile.value
                          }
                          placeholder={profile.label}
                          // onChange={handleProfileChange}
                          disabled={profile.disabled}
                          required={profile.required}
                        />
                      }
                    </div>
                  ))}
                </div>
              </div>
              <hr className='line' />
              <MembersDetails />
            </div>
          </>
        ) : (
          <div className='mt-4 pt-4'>
            <NoData text='Please check your internet connection or reload the page.' />
          </div>
        )}
      </section>
      {/* <Pagination count={1} page={1} total={100} totalPerPage={3} /> */}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;

  .icons-container {
    border-radius: 15px;
    width: 100%;

    .icon-back {
      padding: 1rem 0 2rem 0;
      cursor: pointer;
    }

    .icons {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.5rem 0;

      h4 {
        font-weight: bold;
        color: #2b2929;
        font-size: 1.8rem;
      }
      .icons-btn {
        background: #059157;
        padding: 0.8rem 1rem;
        border-radius: 7px;
        color: #fff;
        font-size: 1.4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.15rem;
        cursor: pointer;
        transition: all 0.3s ease-in;
        &:hover {
          background: #005733;
        }
      }
    }
  }

  .form-container {
    background: #ffffff;
    border-radius: 15px;
    padding: 2rem;
    width: 100%;
    .user-info {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .icon {
        margin-right: 4rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        width: 250px;
        height: 250px;
        position: relative;

        .profile-icon {
          width: 250px;
          height: 250px;
          border-radius: 100%;
        }

        .edit-icon {
          cursor: pointer;
          position: absolute;
          z-index: 10;
          top: 5px;
          right: 0;
        }
      }
    }

    .form-body {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      // width: 80%;
      .profile {
        width: 47%;
        margin-bottom: 1rem;
      }
      .label {
        margin: 0.5rem 0;
        font-weight: bold;
        // font-size: 16px;
        color: #33277b;
      }
    }

    .kin-body {
      display: flex;
      justify-content: flex-start;
      align-items: start;
      flex-wrap: wrap;
      gap: 1.5rem;

      /* width: 80%; */

      .profile {
        width: 30%;
        margin-bottom: 1rem;
      }
      .label {
        margin: 0.5rem 0;
        font-weight: bold;
        // font-size: 16px;
        color: #33277b;
      }
    }

    .kin {
      margin-top: 2rem;
      // font-size: 16px;
      color: #33277b;
    }
  }
`;
export default GroupSavingsView;
