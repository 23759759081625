import { Skeleton } from 'antd';
import moment from 'moment';
import { Fragment, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import DownloadCard from '../../components/DownloadCard';
// import FilterCard from '../../components/FilterCard';
import Pagination, { CurrPageInfoTypes } from '../../components/Pagination';
import SearchBar from '../../components/SearchBar';
import NoData from '../../components/bits/NoData';
import { useGetUserBreakdownQuery } from '../../redux/services/dashboard-services';

/* type UserMonthlyBreakdownTypes = {
  month: string;
  year: number;
  totalUsers: number;
  newUsers: number;
}; */

/* const data: UserMonthlyBreakdownTypes[] = [
  {
    month: 'January',
    year: 2022,
    totalUsers: 6,
    newUsers: 2,
  },
  {
    month: 'February',
    year: 2022,
    totalUsers: 6,
    newUsers: 2,
  },
  {
    month: 'March',
    year: 2022,
    totalUsers: 6,
    newUsers: 2,
  },
  {
    month: 'April',
    year: 2022,
    totalUsers: 6,
    newUsers: 2,
  },
  {
    month: 'May',
    year: 2022,
    totalUsers: 6,
    newUsers: 2,
  },
];
 */
const UserMonthlyBreakdown = () => {
  const [currPageInfo, setCurrPageInfo] = useState<CurrPageInfoTypes>({
    pageNo: 1,
    pageSize: 10,
    pageTotalCount: 10,
    pageCount: 1,
    // status: '',
    searchTerm: '',
    startDate: null,
    endDate: null,
    startDateString: '',
    endDateString: '',
  });

  const { data, isLoading, refetch, isSuccess }: any = useGetUserBreakdownQuery(
    {
      pageNo: currPageInfo.pageNo,
      pageSize: currPageInfo.pageSize,
      // status: currPageInfo.status,
      searchTerm: currPageInfo.searchTerm,
      duration:
        currPageInfo.startDate && currPageInfo.endDate
          ? `${moment(currPageInfo.startDate).format('YYYY-MM-DD')},${moment(
              currPageInfo.endDate
            ).format('YYYY-MM-DD')}`
          : '',
    }
  );

  useEffect(() => {
    refetch();
  }, [refetch]);

  const componentRef = useRef<HTMLDivElement | null>(null);

  return (
    <Wrapper ref={componentRef}>
      <div className='top-header'>
        <h2 style={{ fontWeight: 600, marginBottom: '1rem' }}>
          User Monthly Breakdown
        </h2>
        <div className='search' data-html2canvas-ignore='true'>
          <SearchBar
            width={'95%'}
            setCurrPageInfo={setCurrPageInfo}
            refetch={refetch}
            value={currPageInfo.searchTerm}
          />
        </div>
      </div>
      {/* <FilterCard
        startDate={true}
        endDate={true}
        setCurrPageInfo={setCurrPageInfo}
        refetch={refetch}
      /> */}
      <div className='trans-table'>
        {isLoading ? (
          <Skeleton active />
        ) : data.data?.data.length > 0 ? (
          <>
            <div className='download-card'>
              <DownloadCard
                csv={[data?.data]}
                excel={data.data?.data}
                pdf={true}
                print={true}
                componentRef={componentRef}
                documentTitle='User Monthly Breakdown'
              />
            </div>
            <table className='table-borderless'>
              <thead className='header'>
                <tr>
                  <th>Month</th>
                  <th>Year</th>
                  <th className='text-center'>Total Users</th>
                  <th className='text-center'>New Users</th>
                </tr>
              </thead>
              <tbody>
                {data.data?.data?.map((item: any, index: number) => {
                  return (
                    <tr key={index}>
                      <td>{item.month}</td>
                      <td>{item.year}</td>
                      <td className='text-center'>{item.total_user}</td>
                      <td className='text-center'>{item.new_user}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </>
        ) : (
          <div className='mt-4 pt-4'>
            <NoData
              text='You have not recent transactions. You can perform one '
              link='/user/savings'
            />
          </div>
        )}
      </div>
      {isSuccess && (
        <div className='pag-btn' data-html2canvas-ignore='true'>
          <Pagination
            transPerPage={data.data?.per_page}
            totalTrans={data.data?.total}
            currentPage={data.data?.current_page}
            lastPage={data.data?.last_page}
            setCurrPageInfo={setCurrPageInfo}
            // pageLimit={parseInt(data.data?.per_page)}
            currPageInfo={currPageInfo}
            refetch={refetch}
          />
        </div>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 1rem;
  .top-header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    .search {
      margin-left: auto;
      min-width: 500px;
    }
  }

  .trans-table {
    display: flex;
    flex-direction: column;
    background: #ffffff !important;
    box-shadow: 0px 16px 37px rgba(188, 188, 188, 0.1);
    border-radius: 15px;
    padding: 2rem 1rem !important;
    font-size: 16px;
    overflow-x: auto;

    table {
      th,
      td {
        padding: 0.5rem;
      }
      td {
        color: #707070;
      }
    }
    .header {
      color: #333333;
      border-top: 1px solid #999999;
      border-bottom: 1px solid #999999;
    }
    .status {
      // width: 20%;
    }

    @media screen and (min-width: 500px) {
      .gd {
        margin: 0rem 2rem 0rem 0rem;
        padding: 0px;
      }
    }

    @media screen and (max-width: 500px) {
      .trans-table {
        overflow-x: auto;
        // width: fit-content;
        table {
          th,
          td {
            // font-size: 12px !important;
          }
        }
      }
    }
  }

  @media print {
    .search,
    .download-card,
    .pag-btn {
      display: none;
    }
    .trans-table {
      box-shadow: none;
    }
  }
`;

export default UserMonthlyBreakdown;
