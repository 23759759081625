import React, { ChangeEvent } from 'react';
import styled, { CSSProperties } from 'styled-components';

import Search from '../assets/icons/search.svg';

import { CurrPageInfoTypes } from './Pagination';
import Input from './bits/InputText';

type SearchBarprops = {
  // width?: string;
  width?: CSSProperties['width'];
  setCurrPageInfo?: any;
  refetch?: any;
  value?: string;
};

const SearchBar = (props: SearchBarprops) => {
  const { width, refetch, setCurrPageInfo, value } = props;

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    setCurrPageInfo((currInfo: CurrPageInfoTypes) => {
      return {
        ...currInfo,
        searchTerm: val,
        pageNo: 1,
      };
    });
    refetch();
  };

  return (
    <Wrapper width={width}>
      <div className='label'>
        <img src={Search} alt='searchbar' />
        <Input
          className='ms-3'
          name='search'
          placeholder='What do you want to do today?'
          type='search'
          value={value}
          onChange={handleSearchChange}
          autoFocus={true}
        />
      </div>
    </Wrapper>
  );
};

/* const Wrapper = styled.div`
  width: 100%;
  // margin-top: 2rem;
  .label {
    // width: ${(props: SearchBarprops) =>
    //   props.width ? props.width : '60%'};
    position: relative;
    img {
      position: absolute;
      z-index: 5;
      top: 25%;
      left: 6%;
    }
    input {
      padding-left: 3rem;
      /* margin-bottom: 1.5rem; */
//   }
// }

// @media screen and (max-width: 500px) {
//   .label {
//     width: 100%;
//   }
// }
// `;

const Wrapper = styled.div<SearchBarprops>`
  width: 100%;
  .label {
    /* width: 90%; */
    width: ${({ width }) => (width ? width : '100%')};
    position: relative;
    img {
      position: absolute;
      z-index: 5;
      top: 25%;
      left: 8%;
    }
    input {
      padding-left: 3rem;
      /* margin-bottom: 1.5rem; */
    }
  }

  @media screen and (max-width: 500px) {
    .label {
      width: 100%;
    }
  }
`;

export default SearchBar;
