import { message } from 'antd';
import moment from 'moment';
// import { message } from "antd";
import React, {
  ChangeEvent,
  FormEvent,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import acctdp from '../assets/img/acctdp.png';
import backArrow from '../assets/img/arrow-left.png';
import editImg_2 from '../assets/img/edit-img-2.png';
import editImg from '../assets/img/edit-img.png';
// import { userData } from "../helpers/authHelper";
import { format4dpNumber } from '../helpers/formatNumbers';
import { useUploadImageMutation } from '../redux/services/auth-services';
import {
  useGetSingleAccountsQuery,
  useUpdateAccountMutation,
} from '../redux/services/dashboard-services';

import GroupSavings from './GroupSavings';
import Savings from './Savings';
import TargetSavings from './TargetSavings';
import Button from './bits/Button';
import Input from './bits/InputText';
import LoadingButton from './bits/LoadingButton';
import LoadingRoller from './bits/LoadingRoller';
import NoData from './bits/NoData';

interface FormDataProps {
  firstName: string;
  lastName: string;
  email: string;
  phoneNo: string;
  address: string;
  dob: string;
  referralLink: string;
  avatarUrl: string;
  avatarFile: File | string;
  bvn?: string;
}

const Profile = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // const isLoading = false;

  // const params = useParams();
  // const id: string = params.id ?? '';
  const { id } = useParams<{ id: any }>();

  const {
    data: userData,
    refetch,
    isLoading: dataLoading,
    isSuccess,
  }: any = useGetSingleAccountsQuery(id);

  const [uploadImage] = useUploadImageMutation();
  const [updateUser] = useUpdateAccountMutation();

  const navigate = useNavigate();

  /*  const {
    data: userData,
    isLoading: dataLoading,
    refetch,
  }: any = useGetUserProfileQuery(); */
  const [formData1, setFormData1] = useState<FormDataProps>({
    firstName: '',
    lastName: '',
    email: '',
    phoneNo: '',
    address: '',
    dob: '',
    referralLink: 'https://google.com',
    avatarUrl: '',
    avatarFile: '',
    bvn: '',
    // gender: "male"
  });

  const [formData2, setFormData2] = useState({
    fullName: '',
    relationship: '',
    email: '',
    phoneNo: '',
    address: '22, Berkeley Street, Onikan',
  });

  const [formData3, setFormData3] = useState({
    balance: '',
    lastTopUp: '',
    lastSavedAmt: '',
    lastWithdrawalDate: '',
    lastWithdrawalAmount: '',
  });

  useLayoutEffect(() => {
    setFormData1(currData => {
      return {
        ...currData,
        firstName: userData?.data?.user?.name,
        lastName: userData?.data?.user?.last_name,
        email: userData?.data?.user?.email,
        phoneNo: userData?.data?.user?.phone,
        address: userData?.data?.user?.address,
        dob: userData?.data?.user?.user_profile.date_of_birth,
        bvn: userData?.data?.user?.bvn,
        referralLink: 'https://google.com',
        avatarUrl: userData?.data?.user?.user_profile.avatar,
        avatarFile: '',
      };
    });

    setFormData2(currData => {
      return {
        ...currData,
        fullName: userData?.data?.user?.user_profile.next_of_kin_name,
        relationship:
          userData?.data?.user?.user_profile.next_of_kin_relationship,
        email: userData?.data?.user?.user_profile.next_of_kin_email,
        phoneNo: userData?.data?.user?.user_profile.next_of_kin_number,
        address: userData?.data?.user?.user_profile.address,
      };
    });

    setFormData3(currData => {
      return {
        ...currData,
        balance: userData?.data?.stash?.balance,
        lastTopUp: userData?.data?.stash?.last_top_up_date,
        lastSavedAmt: userData?.data?.stash?.last_amount_saved,
        lastWithdrawalDate: userData?.data?.stash?.last_withdrawal_date,
        lastWithdrawalAmount: userData?.data?.stash?.last_withdrawal_amount,
      };
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let avatarUrl = '';

  const profileData = [
    {
      key: 1,
      name: 'firstName',
      label: 'First Name',
      value: formData1.firstName,
      type: 'text',
      disabled: false,
      required: false,
    },
    {
      key: 2,
      name: 'lastName',
      label: 'Last Name',
      value: formData1.lastName,
      type: 'text',
      disabled: false,
      required: false,
    },
    {
      key: 3,
      name: 'email',
      label: 'Email Address',
      value: formData1.email,
      type: 'email',
      disabled: true,
      required: false,
    },
    {
      key: 4,
      name: 'dob',
      label: 'Date of Birth',
      value: formData1.dob,
      type: 'date',
      disabled: false,
      required: false,
    },
    {
      key: 5,
      name: 'address',
      label: 'Address',
      value: formData1.address,
      type: 'text',
      disabled: false,
      required: false,
    },
    {
      key: 6,
      name: 'phoneNo',
      label: 'Phone Number',
      value: formData1.phoneNo,
      type: 'tel',
      disabled: false,
      required: false,
    },

    {
      key: 7,
      name: 'bvnNo',
      label: 'BVN',
      value: formData1.bvn,
      type: 'tel',
      disabled: true,
      required: false,
    },
  ];

  const nextOfKinData = [
    {
      key: 1,
      name: 'fullName',
      label: 'Full Name',
      value: formData2.fullName,
      type: 'text',
      disabled: false,
      required: false,
    },
    {
      key: 2,
      name: 'relationship',
      label: 'Last Name',
      value: formData2.relationship,
      type: 'text',
      disabled: false,
      required: false,
    },
    {
      key: 3,
      name: 'email',
      label: 'Email Address',
      value: formData2.email,
      type: 'email',
      disabled: false,
      required: false,
    },
    {
      key: 4,
      name: 'phoneNo',
      label: 'Phone Number',
      value: formData2.phoneNo,
      type: 'tel',
      disabled: false,
      required: false,
    },
    {
      key: 5,
      name: 'address',
      label: 'Address',
      value: formData2.address,
      type: 'text',
      disabled: false,
      required: false,
    },
  ];
  const stashData = [
    {
      key: 1,
      name: 'balance',
      label: 'Balance',
      value: format4dpNumber(formData3.balance),
      type: 'text',
      disabled: true,
      required: false,
    },
    {
      key: 2,
      name: 'lastTopUp',
      label: 'Last Top Up Date',
      value: moment(formData3.lastTopUp).format('YYYY-MM-DD'),
      type: 'date',
      disabled: true,
      required: false,
    },
    {
      key: 3,
      name: 'lastSavedAmt',
      label: 'Amount Last Saved',
      value: format4dpNumber(formData3.lastSavedAmt),
      type: 'text',
      disabled: true,
      required: false,
    },
    {
      key: 4,
      name: 'lastWithdrawalDate',
      label: 'Last Withdrawal Date',
      value: moment(formData3.lastWithdrawalDate).format('YYYY-MM-DD'),
      type: 'date',
      disabled: true,
      required: false,
    },
    {
      key: 5,
      name: 'lastWithdrawalAmount',
      label: 'Last Withdrawal Amount',
      value: format4dpNumber(formData3.lastWithdrawalAmount),
      type: 'text',
      disabled: true,
      required: false,
    },
  ];

  const handleFile = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length) {
      setFormData1({
        ...formData1,
        avatarUrl: URL.createObjectURL(e.target.files[0]),
        avatarFile: e.target.files[0],
      });
    }
  };

  const handleProfileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setFormData1({
      ...formData1,
      [name]: value,
    });
  };

  const handleKinChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setFormData2({
      ...formData2,
      [name]: value,
    });
  };

  /*  const handleAll = (e: any) => {
    handleFile(e);
    handleProfileChange(e);
    handleKinChange(e);
  }; */

  // let user = userData();

  const submitHandler = async (
    e: FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();
    try {
      setIsLoading(true);
      if (formData1.avatarFile) {
        const imgUploadData = new FormData();
        imgUploadData.append('image', formData1.avatarFile);
        const res: any = await uploadImage(imgUploadData);
        if (res?.data?.status === 'success') {
          // console.log("success");
          avatarUrl = res.data?.data?.url;
        } else {
          message.error(res?.error?.data?.message);
        }
        setIsLoading(false);
      }

      const res: any = await updateUser({
        name: formData1.firstName,
        last_name: formData1.lastName,
        address: formData1.address,
        avatar: formData1.avatarUrl,
        phone: formData1.phoneNo,
        next_of_kin_name: formData2.fullName,
        next_of_kin_number: formData2.phoneNo,
        next_of_kin_email: formData2.email,
        next_of_kin_relationship: formData2.relationship,
        date_of_birth: formData1.dob,
        meta: "{'extra': 'valid'}",
        id: id,
      });
      if (res?.data?.status === 'success') {
        // localStorage.setItem('userDetails', JSON.stringify(res?.data?.data));
        message.success('User profile updated successfully');
      } else {
        message.error(res?.error?.data?.message);
      }
      setIsLoading(false);
    } catch (error: any) {
      message.error(error?.data?.message);
      setIsLoading(false);
    }
  };

  return (
    <Wrapper>
      <div className='icons-container col-md-10 mx-auto'>
        <div className='icon-back'>
          <img src={backArrow} alt='' onClick={() => navigate(-1)} />
        </div>
        <div className='icons '>
          <h4>View Account</h4>
          <div className='icons-btn'>
            <span>Edit</span>

            <img src={editImg} alt='' />
          </div>
        </div>
      </div>
      <section className='form-container col-md-10 mx-auto'>
        {dataLoading ? (
          <LoadingRoller />
        ) : userData ? (
          <>
            <form className='form-inner' onSubmit={e => submitHandler(e)}>
              <div className='user-info'>
                <div className='icon'>
                  {formData1.avatarUrl ? (
                    <img
                      src={formData1.avatarUrl}
                      alt='profile icon'
                      className='profile-icon'
                    />
                  ) : (
                    <img
                      src={acctdp}
                      alt='profile icon'
                      className='profile-icon'
                    />
                  )}
                  <label htmlFor='img-upload' className='edit-icon'>
                    <img src={editImg_2} alt='edit-icon' />
                  </label>
                </div>
                <input
                  type='file'
                  id='img-upload'
                  onChange={handleFile}
                  accept='image/*'
                  className='i-none'
                />
                <div className='form-body'>
                  {profileData.map((profile, id) => (
                    <div key={id} className='profile'>
                      <p className='label'>{profile.label}</p>
                      {
                        <Input
                          key={profile.key}
                          name={profile.name}
                          type={profile.type}
                          value={
                            profile.type === 'date'
                              ? profile?.value?.toString().slice(0, 10) || ''
                              : profile.value
                          }
                          placeholder={profile.label}
                          onChange={handleProfileChange}
                          disabled={profile.disabled}
                          required={profile.required}
                        />
                      }
                    </div>
                  ))}
                </div>
              </div>
              <hr className='line' />

              <div className='kin'>
                <h5>
                  <b>Next of kin</b>
                </h5>
              </div>
              <div className='kin-body'>
                {nextOfKinData.map((kin, id) => (
                  <div key={id} className='profile'>
                    <p className='label'>{kin.label}</p>
                    {
                      <Input
                        key={kin.key}
                        name={kin.name}
                        type={kin.type}
                        value={kin.value}
                        placeholder={kin.label}
                        onChange={handleKinChange}
                        disabled={kin.disabled}
                        required={kin.required}
                      />
                    }
                  </div>
                ))}
              </div>
              <hr className='line' />
              <div className='kin'>
                <h5>
                  <b>Purse</b>
                </h5>
              </div>
              <div className='kin-body'>
                {stashData.map((kin, id) => (
                  <div key={id} className='profile'>
                    <p className='label'>{kin.label}</p>
                    {
                      <Input
                        key={kin.key}
                        name={kin.name}
                        type={kin.type}
                        value={kin.value}
                        placeholder={kin.label}
                        onChange={handleKinChange}
                        disabled={kin.disabled}
                        required={kin.required}
                      />
                    }
                  </div>
                ))}
              </div>
              <hr className='line' />
              <div className='mt-2 col-sm-12 px-0 col-md-4 ml-auto'>
                <Button type='submit' className='mr-4 mb-2'>
                  {isLoading ? <LoadingButton /> : 'Save Changes'}
                </Button>
              </div>
            </form>

            {isSuccess && (
              <>
                {userData?.data?.user?.saving_cycle.length > 0 && (
                  <>
                    <hr className='line' />
                    <Savings savings={userData?.data?.user?.saving_cycle} />
                  </>
                )}
                {userData?.data?.target.length > 0 && (
                  <>
                    <hr className='line' />
                    <TargetSavings target={userData?.data?.target} />
                  </>
                )}
                {userData?.data?.coop.length > 0 && (
                  <>
                    <hr className='line' />
                    <GroupSavings coop={userData?.data?.coop} />
                  </>
                )}
              </>
            )}
          </>
        ) : (
          <div className='mt-4 pt-4'>
            <NoData text='Please check your internet connection or reload the page.' />
          </div>
        )}
      </section>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;

  .i-none {
    display: none;
  }

  .icons-container {
    border-radius: 15px;
    width: 100%;

    .icon-back {
      padding: 1rem 0 2rem 0;
      cursor: pointer;
    }

    .icons {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.5rem 0;

      h4 {
        font-weight: bold;
        color: #2b2929;
        font-size: 1.8rem;
      }
      .icons-btn {
        background: #059157;
        padding: 0.8rem 1rem;
        border-radius: 7px;
        color: #fff;
        font-size: 1.4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.15rem;
        cursor: pointer;
        transition: all 0.3s ease-in;
        &:hover {
          background: #005733;
        }
      }
    }
  }

  .form-container {
    background: #ffffff;
    border-radius: 15px;
    padding: 2rem;
    width: 100%;
    .user-info {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .icon {
        margin-right: 4rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        width: 250px;
        height: 250px;
        position: relative;

        .profile-icon {
          width: 250px;
          height: 250px;
          border-radius: 100%;
        }

        .edit-icon {
          cursor: pointer;
          position: absolute;
          z-index: 10;
          top: 5px;
          right: 0;
        }
      }
    }

    .form-body {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      // width: 80%;
      .profile {
        width: 47%;
        margin-bottom: 1rem;
      }
      .label {
        margin: 0.5rem 0;
        font-weight: bold;
        // font-size: 16px;
        color: #33277b;
      }
    }

    .kin-body {
      display: flex;
      justify-content: flex-start;
      align-items: start;
      flex-wrap: wrap;
      gap: 1.5rem;

      /* width: 80%; */

      .profile {
        width: 30%;
        margin-bottom: 1rem;
      }
      .label {
        margin: 0.5rem 0;
        font-weight: bold;
        // font-size: 16px;
        color: #33277b;
      }
    }

    .kin {
      margin-top: 2rem;
      // font-size: 16px;
      color: #33277b;
    }
  }
`;
export default Profile;
