import moment from 'moment';
import styled from 'styled-components';

import usePagination from '../helpers/usePagination';

export type CurrPageInfoTypes = {
  pageNo: number;
  pageSize: number;
  status?: string | number;
  type?: string;
  startDate: moment.Moment | null;
  startDateString?: string;
  endDate: moment.Moment | null;
  endDateString?: string;
  pageCount: number;
  pageTotalCount: number;
  searchTerm?: string;
};

type PaginationProps = {
  transPerPage: number;
  totalTrans: number;
  // paginationHandler: (num: number) => void;
  setCurrPageInfo: Function;
  currPageInfo: CurrPageInfoTypes;
  currentPage: number;
  lastPage: number;
  // pageLimit: number;
  refetch: Function;
};

const Pagination = ({
  transPerPage,
  totalTrans,
  setCurrPageInfo,
  currentPage,
  currPageInfo,
  // pageLimit,
  lastPage,
  refetch,
}: PaginationProps) => {
  const currPage = currPageInfo.pageNo;

  const paginationRange = usePagination({
    currentPage: currPage,
    totalCount: totalTrans,
    siblingCount: 1,
    pageSize: transPerPage,
  });

  if (currentPage === 0 || (paginationRange && paginationRange.length < 1)) {
    return null;
  }

  const prevPage = () => {
    setCurrPageInfo((currInfo: CurrPageInfoTypes) => {
      return {
        ...currInfo,
        pageNo: currInfo.pageNo - 1,
        pageCount: currInfo.pageCount - 10,
        pageTotalCount: currInfo.pageTotalCount - 10,
      };
    });

    refetch();
  };

  const nextPage = () => {
    setCurrPageInfo((currInfo: CurrPageInfoTypes) => {
      return {
        ...currInfo,
        pageNo: currInfo.pageNo + 1,
        pageCount: currInfo.pageCount + 10,
        pageTotalCount: currInfo.pageTotalCount + 10,
      };
    });

    refetch();
  };

  /* const changePage = (page: number) => {
    setCurrPageInfo((currInfo: CurrPageInfoTypes) => {
      return {
        ...currInfo,
        pageNo: page,
      };
    });
    refetch();
  }; */

  return (
    <Wrapper>
      <div className='pagination'>
        <div>
          Showing {currPageInfo.pageCount} -{' '}
          {currPageInfo.pageTotalCount < totalTrans
            ? currPageInfo.pageTotalCount
            : totalTrans}{' '}
          of {totalTrans}
        </div>
        <div className='pag-btn'>
          <span
            onClick={prevPage}
            className={`prev ${currPage === 1 ? `disabled` : ''}`}
          >
            Prev
          </span>
          <div className='count'>
            <span className=''>{currentPage}</span>
          </div>
          <span
            onClick={nextPage}
            className={`next ${currPage === lastPage ? `disabled` : ''}`}
          >
            Next
          </span>
        </div>
      </div>
    </Wrapper>
  );
};

export default Pagination;

const Wrapper = styled.div`
  width: 100%;
  .pagination {
    display: flex;
    align-items: center;
    padding: 1rem;
    justify-content: space-between;
    font-size: 1.1rem;
    color: #707070;

    .pag-btn {
      display: flex;
      gap: 0.5rem;
      align-items: center;

      .prev,
      .next {
        cursor: pointer;

        &.disabled {
          pointer-events: none;
          box-shadow: none;
          color: #acacac;
        }
      }

      .count {
        display: flex;
        background: #ffffff;
        padding: 1rem;
        height: 2rem;
        align-items: center;
        justify-content: center;
        font-size: 1.3rem;
        cursor: default;
      }
      .next {
        color: #707070;
      }
    }
  }
`;
