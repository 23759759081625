import { Skeleton, message } from 'antd';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { formatString } from '../helpers/formatNumbers';
import {
  useGetCommissionQuery,
  useUpdateCommissionMutation,
} from '../redux/services/commission-services';
import { commission } from '../utils/routes';

import Congratulations from './Congratulations';
import Modal from './Modal';
import Warning from './Warning';
import Button from './bits/Button';
import Checkbox from './bits/Checkbox';
import CustomInput from './bits/CustomInput';
import { Label } from './bits/Text';
// import NewSelect from './bits/NewSelect';

export type CommissionFormProps = {
  commissionForValue: string;
  commissionForName: string;
  commissionModeValue: string;
  commissionModeName: string;
  amount: number;
  percentage: number;
  fee: number;
  range: boolean;
  max: number;
  min: number;
};

const EditCommission = ({ setViewCommission }: any) => {
  const { id } = useParams<{ id: any }>();
  const navigate = useNavigate();
  const { data, refetch }: any = useGetCommissionQuery(id);
  const [updateCommission] = useUpdateCommissionMutation();

  const [currentModal, setCurrentModal] = useState(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<CommissionFormProps>({
    commissionForName: '',
    commissionForValue: '',
    commissionModeName: '',
    commissionModeValue: '',
    amount: 0,
    percentage: 0,
    fee: 0,
    range: false,
    max: 0,
    min: 0,
  });

  const populateState = (updatedData: any) => {
    setFormData(currData => {
      return {
        ...currData,
        commissionForValue: updatedData.commission_for,
        commissionModeValue: updatedData.commission_mode,
        amount: parseFloat(updatedData.amount),
        percentage: parseInt(updatedData.percentage),
        commissionForName: formatString(updatedData.commission_for),
        commissionModeName: formatString(updatedData.commission_mode),
        range: updatedData.price_range,
        min: parseInt(updatedData.minimum),
        max: parseInt(updatedData.maximum),
      };
    });
  };

  useEffect(() => {
    data && populateState(data?.data);
    refetch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch, data]);

  // const handleCommissionModeChange = (e: ChangeEvent<HTMLInputElement>) => {
  //   const { name, value } = e.target;

  //   setFormData(currData => {
  //     return {
  //       ...currData,
  //       commissionModeName: name,
  //       commissionModeValue: value,
  //     };
  //   });
  //   console.log(name, value);
  // };

  // const handleCommissionForChange = (e: ChangeEvent<HTMLInputElement>) => {
  //   const { name, value } = e.target;

  //   setFormData(currData => {
  //     return {
  //       ...currData,
  //       commissionForName: name,
  //       commissionForValue: value,
  //     };
  //   });

  //   console.log(name, value);
  // };

  // const handleSelectChange = (e: ChangeEvent<HTMLInputElement>) => {
  //   const { name, checked } = e.target;

  //   setFormData((currData: any) => {
  //     return {
  //       ...currData,
  //       [name]: checked,
  //       min: '',
  //       max: '',
  //     };
  //   });
  // };

  const handleFormDataChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setFormData(currData => {
      return {
        ...currData,
        [name]: value,
      };
    });
  };

  const handlePercentageChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { min, max, name } = e.target;
    let value = Number.parseInt(e.target.value, 10);

    if (value < parseInt(min) || isNaN(value)) value = 0;
    if (value > parseInt(max)) value = parseInt(max);
    setFormData((currData: any) => {
      return {
        ...currData,
        [name]: value,
      };
    });
  };

  const UpdateCommission = async function (): Promise<void> {
    try {
      setIsLoading(true);
      const res: any = await updateCommission({
        id: id,
        commission_for: formData.commissionForValue,
        commission_mode: formData.commissionModeValue,
        amount: formData.amount,
        percentage: formData.percentage,
        price_range: formData.range,
        maximum: formData.max,
        minimum: formData.min,
      });

      if (res?.data?.status === 'success') {
        setIsLoading(false);
        message.success(res.data.message);
        setCurrentModal(2);
      } else if (res.error) {
        setIsLoading(false);
        message.error(res.error?.data?.message);
      } else {
        setIsLoading(false);
        message.error(res.data.message);
      }
    } catch (error: any) {
      setIsLoading(false);
      message.error(error?.data?.message);
    }
  };

  const submitHandler = (e: any) => {
    e.preventDefault();
    setCurrentModal(1);
  };

  return (
    <>
      <Wrapper>
        <h2 style={{ fontWeight: 600 }}>Edit Commission</h2>
        {isLoading ? (
          <Skeleton active />
        ) : (
          data?.data && (
            <form onSubmit={submitHandler}>
              <div className='select'>
                <Label>Commission For</Label>
                <CustomInput
                  type='text'
                  disabled={true}
                  // value={formData.commissionModeValue}
                  value={formatString(formData?.commissionForValue)}
                />
                {/* <NewSelect
              placeholder='Select One'
              // placeholder={formData.commissionModeValue}
              // name="frequencyValue"
              fullWidth
              options={[
                { name: 'Service Charge', value: 'service_charge' },
                { name: 'Penalty', value: 'penalty_fee' },
                { name: 'Referral Bonus', value: 'referral_bonus' },
              ]}
              selected={{
                name: formData.commissionForName,
                value: formData.commissionForValue,
              }}
              onChange={handleCommissionForChange}
              required
              // disabled
            /> */}
              </div>

              <div className='select'>
                <Label>Commission Mode</Label>
                <CustomInput
                  type='text'
                  disabled={true}
                  // value={data?.data?.commission_mode}
                  value={formatString(formData.commissionModeValue)}
                />

                {/* <NewSelect
              placeholder='Select Mode'
              // name="frequencyValue"
              fullWidth
              options={[
                {
                  name: 'Set By Percentage Amount',
                  value: 'percentage',
                },
                { name: 'Set By Fixed Amount', value: 'fixed' },
                { name: 'Set Fee At N0.00', value: 'fee' },
              ]}
              selected={{
                name: formData.commissionModeName,
                value: formData.commissionModeValue,
              }}
              onChange={handleCommissionModeChange}
              required
              // disabled
            /> */}
              </div>

              {formData?.commissionModeValue === 'percentage' && (
                <div className='select'>
                  <Label>Percentage Value (%)</Label>
                  <CustomInput
                    fullWidth
                    type='number'
                    min={1}
                    max={100}
                    placeholder='/100'
                    name='percentage'
                    // onChange={handleFormDataChange}
                    onChange={handlePercentageChange}
                    // value={data?.data?.percentage}
                    value={
                      formData.percentage && formData.percentage > 0
                        ? formData.percentage
                        : ''
                    }
                    autoFocus
                    required
                  />
                </div>
              )}

              {formData?.commissionModeValue === 'fixed' && (
                <div className='select'>
                  <Label>Amount in Naira (₦)</Label>
                  <CustomInput
                    fullWidth
                    type='number'
                    placeholder='Enter Amount'
                    name='amount'
                    onChange={handleFormDataChange}
                    value={
                      formData.amount && formData.amount > 0
                        ? formData.amount
                        : ''
                    }
                    required
                    autoFocus
                  />
                </div>
              )}

              {formData?.commissionModeValue === 'fee' && (
                <div className='select'>
                  <Label>Amount in Naira (₦)</Label>
                  <CustomInput
                    fullWidth
                    type='text'
                    placeholder='N0.00'
                    name='fee'
                    onChange={handleFormDataChange}
                    value={formData.fee && formData.fee > 0 ? formData.fee : ''}
                    disabled
                    required
                    autoFocus
                  />
                </div>
              )}

              {data?.data?.price_range === 1 && (
                <>
                  <div className='select mt-2'>
                    <Label htmlFor='range'>
                      Set Mode for Specific Price Range
                    </Label>
                    <Checkbox
                      id='range'
                      name='range'
                      value={data?.data?.price_range}
                      checked={data?.data?.price_range}
                      // onChange={handleSelectChange}
                      disabled
                    />
                  </div>

                  <div className='range'>
                    <h6>Commission</h6>
                    <div className='range__container'>
                      <div className='select'>
                        <Label>Minimum Value</Label>
                        <CustomInput
                          fullWidth
                          type='number'
                          placeholder='Enter amount'
                          name='min'
                          onChange={handleFormDataChange}
                          value={
                            formData.min && formData.min > 0 ? formData.min : ''
                          }
                          required
                          autoFocus
                        />
                      </div>

                      <div className='select'>
                        <Label>Maximum Value</Label>
                        <CustomInput
                          fullWidth
                          type='number'
                          placeholder='Enter amount'
                          name='max'
                          onChange={handleFormDataChange}
                          value={
                            formData.max && formData.max > 0 ? formData.max : ''
                          }
                          required
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}

              <div className='d-flex justify-content-end gap-2 mt-2'>
                <Button
                  bg='white'
                  color='#33277b'
                  border='#33277b'
                  fontSize='16px'
                  type='submit'
                >
                  Update
                </Button>
                <Button
                  bg='#F24357'
                  fontSize='16px'
                  onClick={() => navigate(`../${commission}`)}
                >
                  Cancel
                </Button>
              </div>
            </form>
          )
        )}
      </Wrapper>
      {currentModal === 1 && (
        <Modal isShown={true}>
          <Warning
            warningTitle={'Warning'}
            warningText={
              "You're about to edit this commission. Are you sure you want to proceed?"
            }
            confirmText={'Proceed'}
            confirm={() => UpdateCommission()}
            closeModal={() => setCurrentModal(0)}
          />
        </Modal>
      )}
      {currentModal === 2 && (
        <Modal isShown={true}>
          <Congratulations
            warningTitle={'Congratulations'}
            warningText={'Commission has been updated'}
            confirmText={'Close'}
            closeModal={() => {
              setCurrentModal(0);
              navigate(`../${commission}`);
              // setViewCommission(false);
              // refetch();
            }}
          />
        </Modal>
      )}
    </>
  );
};

const Wrapper = styled.div`
  form {
    border-radius: 0.5rem;
    box-shadow: 0px 16px 37px rgba(188, 188, 188, 0.1);
    background: #fff;
    padding: 4rem 2rem;
  }
  .select {
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-gap: 40px;
    align-items: center;
  }

  .range {
    margin-top: 30px;

    h6 {
      color: #909090;
    }

    &__container {
      padding: 2rem 1rem;
      border: 1px solid #d9d9d9;
      border-radius: 8px;
    }

    font-size: 20px;
  }
`;

export default EditCommission;
