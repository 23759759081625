import { Skeleton } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import Moment from "react-moment";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

// import { ReactComponent as ActiveIcon } from '../../assets/icons/c-active.svg';
// import { ReactComponent as InactiveIcon } from '../../assets/icons/c-inactive.svg';
import { ReactComponent as ShowIcon } from "../../assets/icons/eye-icon.svg";
import { ReactComponent as NairaIcon } from "../../assets/icons/naira-icon.svg";
import FilterCard from "../../components/FilterCard";
import Pagination, { CurrPageInfoTypes } from "../../components/Pagination";
import SearchBar from "../../components/SearchBar";
import Button from "../../components/bits/Button";
import NoData from "../../components/bits/NoData";
import { format4dpNumber } from "../../helpers/formatNumbers";
import {
  // GetAllAccounts,
  useActiveBlackListedAccountMutation,
  useAddBlackListedAccountMutation,
  useGetAllAccountsQuery,
} from "../../redux/services/dashboard-services";
import { allAccts } from "../../utils/routes";

/* type AccountData = {
  userName: string;
  email: string;
  stash: number;
  source: string;
  created_at: string;
  status: string;
}; */
/* 
const data: AccountData[] = [
  {
    userName: 'James Ward-Prowse',
    email: 'tolu@gmail.com',
    stash: 0,
    source: 'web',
    created_at: '02/04/2022',
    status: 'inactive',
  },
  {
    userName: 'Folashade Promise',
    email: 'tolu@gmail.com',
    stash: 0,
    source: 'mobile',
    created_at: '04/04/2022',
    status: 'active',
  },
  {
    userName: 'Nkechi Ciroma',
    email: 'tolu@gmail.com',
    stash: 0,
    source: 'web',
    created_at: '02/04/2022',
    status: 'inactive',
  },
  {
    userName: 'Lawal Ibrahim',
    email: 'tolu@gmail.com',
    stash: 0,
    source: 'mobile',
    created_at: '02/04/2022',
    status: 'active',
  },
  {
    userName: 'Adekunle Chukwu',
    email: 'tolu@gmail.com',
    stash: 0,
    source: 'web',
    created_at: '02/04/2022',
    status: 'inactive',
  },
  {
    userName: 'Kolapo Bassey',
    email: 'tolu@gmail.com',
    stash: 0,
    source: 'mobile',
    created_at: '02/04/2022',
    status: 'active',
  },
  {
    userName: 'James Ward-Prowse',
    email: 'tolu@gmail.com',
    stash: 0,
    source: 'web',
    created_at: '02/04/2022',
    status: 'inactive',
  },
  {
    userName: 'Folashade Promise',
    email: 'tolu@gmail.com',
    stash: 0,
    source: 'mobile',
    created_at: '04/04/2022',
    status: 'active',
  },
  {
    userName: 'Nkechi Ciroma',
    email: 'tolu@gmail.com',
    stash: 0,
    source: 'web',
    created_at: '02/04/2022',
    status: 'inactive',
  },
]; */

const AllAccounts = () => {
  /* const [isChecked, setIsChecked] = useState({
    checked: false,
    checkAll: false,
  }); */

  const [currPageInfo, setCurrPageInfo] = useState<CurrPageInfoTypes>({
    pageNo: 1,
    pageSize: 10,
    pageTotalCount: 10,
    pageCount: 1,
    status: "",
    searchTerm: "",
    startDate: null,
    endDate: null,
    startDateString: "",
    endDateString: "",
  });

  const navigate = useNavigate();

  const { data, isLoading, refetch, isSuccess }: any = useGetAllAccountsQuery({
    pageNo: currPageInfo.pageNo,
    pageSize: currPageInfo.pageSize,
    status: currPageInfo.status,
    searchTerm: currPageInfo.searchTerm,
    duration:
      currPageInfo.startDate && currPageInfo.endDate
        ? `${moment(currPageInfo.startDate).format("YYYY-MM-DD")},${moment(
            currPageInfo.endDate
          ).format("YYYY-MM-DD")}`
        : "",
  });

  const [addBlackListedAccount] = useAddBlackListedAccountMutation();
  const [activeBlackListedAccount] = useActiveBlackListedAccountMutation();

  useEffect(() => {
    refetch();
  }, [refetch]);

  const [selectedIds, setSelectedIds] = useState<any>([]);
  const [state, setState] = useState<{ selections: string[] }>({
    selections: [],
  });

  function handleChange(key: string) {
    let sel = state.selections;
    let find = sel.indexOf(key);
    if (find > -1) {
      sel.splice(find, 1);
    } else {
      sel.push(key);
    }

    setState({
      selections: sel,
    });

    setSelectedIds(sel);

    // const selIds = sel.filter(item => item.id);
    // console.log(selIds);
  }

  // const [checkedState, setCheckedState] = useState<any>(
  //   isSuccess && Array.from({ length: data?.data.data.length }, () => false)
  // );

  // const [checkedState, setCheckedState] = useState({
  //   checkedItems: new Map(),
  // });

  // const handleChange = (index: any) => {
  //   const updateCheckedState = checkedState.map((item: any, pos: number) => {
  //     return index === pos ? !item : item;
  //   });
  //   console.log(checkedState);

  //   setCheckedState(updateCheckedState);

  //   // const { name, checked } = e.target;

  //   // setCheckedState(prevState => ({
  //   //   checkedItems: prevState.checkedItems.set(name, checked),
  //   // }));

  //   // let ids = [...selectedIds, name];

  //   // if (selectedIds.includes(name)) {
  //   //   ids = ids.filter(id => id !== name);
  //   // }

  //   // setSelectedIds(ids);
  //   // console.log(selectedIds);
  // };

  const deactivateBlacklistedAccount = async (): Promise<void> => {
    try {
      await addBlackListedAccount({
        ids: selectedIds,
      });
    } catch (error) {
      console.log(error);
    }
    refetch();
    window.location.reload();
  };

  const activateBlackListedAccount = async (): Promise<void> => {
    try {
      await activeBlackListedAccount({
        ids: selectedIds,
      });
    } catch (error) {
      console.log(error);
    }
    refetch();
    window.location.reload();
  };

  return (
    <Wrapper>
      {isSuccess && (
        <>
          <div className="page-h top-header">
            <h2 style={{ fontWeight: 600 }}>Accounts</h2>
            <div className="search">
              <SearchBar
                width={"90%"}
                setCurrPageInfo={setCurrPageInfo}
                refetch={refetch}
                value={currPageInfo.searchTerm}
              />
            </div>
          </div>
          <FilterCard
            startDate={true}
            endDate={true}
            statusOptions={[
              { name: "Status", value: "" },
              { name: "Active", value: 1 },
              { name: "Inactive ", value: 0 },
            ]}
            setCurrPageInfo={setCurrPageInfo}
            refetch={refetch}
          />
        </>
      )}

      <div className="trans-table">
        {isLoading ? (
          <Skeleton active />
        ) : data.data?.data.length > 0 ? (
          <>
            <table className="table align-middle">
              <thead className="header">
                <tr>
                  <th>
                    <input
                      type="checkbox"
                      className="checkbox"
                      /*                      onChange={() => {
                        setIsChecked(prevState => ({
                          ...prevState,
                          checkAll: !prevState.checkAll,
                          checked: !prevState.checked,
                      // /*   }));
                      }} */
                      // checked={isChecked.checkAll && isChecked.checked} */
                      // onChange={e => {
                      // let checked = e.target.checked;
                      /*   setIsChecked(
                          isChecked.map((d: any) => {
                            d.select = checked;
                            return d;
                          })
                        );
                        console.log(checked); */
                      // }}
                    />
                  </th>
                  <th>Customer's Name</th>
                  <th>Email</th>
                  <th>Purse</th>
                  {/* <th>Source</th> */}
                  <th>Date Registered</th>
                  <th className="text-center">Status</th>
                  <th>View</th>
                </tr>
              </thead>
              <tbody>
                {data.data.data?.map((item: any, index: number) => {
                  return (
                    <tr key={index}>
                      <td>
                        <input
                          type="checkbox"
                          className="checkbox"
                          id={`name-${index}`}
                          name={item.id}
                          // checked={checkedState[index]}
                          // value={`${item.name}-${item.last_name}-${index}`}
                          // onChange={() => {
                          //   handleChange(index);
                          //   setSelectedIds([...selectedIds, item?.id]);
                          // }}
                          checked={state.selections.includes(item.id)}
                          onChange={() => handleChange(item.id)}
                        />
                      </td>

                      <td className="text-capitalize">
                        <label htmlFor={`name-${index}`}>
                          {`${item.last_name} ${item.name}`}
                        </label>
                      </td>
                      <td>{item.email}</td>
                      <td>
                        <div className="amt">
                          <NairaIcon
                          // style={{
                          //   width: '13px',
                          //   height: '14px',
                          //   marginInlineEnd: '0.25rem',
                          // }}
                          />
                          {format4dpNumber(item.wallet.balance)}
                        </div>
                      </td>
                      {/* <td>{item.source ?? 'Desktop'} </td> */}
                      <td>
                        <Moment format="DD/MM/YYYY">
                          {item.wallet.created_at}
                        </Moment>
                      </td>
                      <td className="text-center">
                        {/* {item.status === 1 ? <ActiveIcon /> : <InactiveIcon />} */}
                        {item.status === 1 ? (
                          <span className="active">Active</span>
                        ) : (
                          <span className="inactive">Inactive</span>
                        )}
                      </td>
                      <td
                        className="status"
                        onClick={() => navigate(`../${allAccts}/${item.id}`)}
                      >
                        <ShowIcon />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </>
        ) : (
          <div className="mt-4 pt-4">
            <NoData
              text="There are no existing account."
              //   link="/user/savings"
            />
          </div>
        )}
      </div>

      {isSuccess && (
        <>
          <div className="ft">
            <Button
              fontSize="16px"
              className="me-4"
              onClick={activateBlackListedAccount}
              disabled={selectedIds.length === 0 ? true : false}
            >
              Reactivate
            </Button>
            <Button
              border="#F12F2F"
              color="#F12F2F"
              fontSize="16px"
              bg="transparent"
              className="me-4"
              disabled={selectedIds.length === 0 ? true : false}
              onClick={deactivateBlacklistedAccount}
            >
              Deactivate
            </Button>
          </div>
          <Pagination
            transPerPage={data.data?.per_page}
            totalTrans={data.data?.total}
            currentPage={data.data?.current_page}
            lastPage={data.data?.last_page}
            setCurrPageInfo={setCurrPageInfo}
            // pageLimit={parseInt(data.data?.per_page)}
            currPageInfo={currPageInfo}
            refetch={refetch}
          />
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .top-header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    .search {
      margin-left: auto;
      min-width: 500px;
    }
  }

  display: flex;
  flex-direction: column;
  .page-h {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .search-div {
      margin-left: auto;
      min-width: 500px;
    }
  }

  .status {
    cursor: pointer;
  }
  .ft {
    margin-top: 1rem;
    /* .ft-btn {
      background-color: transparent;
      // color: #f12f2f;
      //   border: #F12F2F;
      font-size: 16px;
    } */
  }

  .trans-table {
    display: flex;
    flex-direction: column;
    background: #ffffff;
    box-shadow: 0px 16px 37px rgba(188, 188, 188, 0.1);
    border-radius: 15px;
    padding: 2rem 1rem !important;
    font-size: 16px;
    overflow-x: auto;

    .amt {
      display: flex;
      gap: 0.2rem;
      align-items: center;
    }
    table {
      th {
        padding: 0.5rem;
      }
      td {
        padding: 0.5rem;
        color: #707070;

        .inactive {
          color: #ffffff;
          /* background: rgba(234, 5, 5, 0.13); */
          background: #ea0505b2;
          border-radius: 5px;
          padding: 0.5rem;
        }
        .active {
          /* color: #059157; */
          /* background: rgba(207, 232, 222, 0.2); */
          background: #059157;
          color: #ffffff;
          border-radius: 5px;
          padding: 0.5rem;
        }
      }

      .checkbox {
        height: 1.3rem;
        width: 1.3rem;
        display: flex;
      }
    }
  }
  .header {
    color: #333333;
    border-top: 1px solid #999999;
    border-bottom: 2px solid #999999;
  }
  .status {
    // width: 20%;
  }

  @media screen and (min-width: 500px) {
    .gd {
      margin: 0rem 2rem 0rem 0rem;
      padding: 0px;
    }
  }

  @media screen and (max-width: 500px) {
    .trans-table {
      overflow-x: auto;
      // width: fit-content;
      table {
        th,
        td {
          // font-size: 12px !important;
        }
      }
    }
  }
`;

export default AllAccounts;
