import { message } from 'antd';
import React, { ChangeEvent, useState } from 'react';
import styled from 'styled-components';

import { useAddCommissionMutation } from '../redux/services/commission-services';

// import SearchBar from './SearchBar';
import Congratulations from './Congratulations';
import Modal from './Modal';
import Warning from './Warning';
import Button from './bits/Button';
import Checkbox from './bits/Checkbox';
import CustomInput from './bits/CustomInput';
import CustomSelect from './bits/CustomSelect';
// import LoadingButton from './bits/LoadingButton';
import LoadingButton from './bits/LoadingButton';
import { Label } from './bits/Text';

export type CommissionFormProps = {
  commissionForName: string;
  commissionForValue: string;
  commissionModeValue: string;
  commissionModeName: string;
  amount: number;
  percentage: number;
  fee: number;
  range: boolean;
  max?: number;
  min?: number;
};

const SetCommission = ({ setViewCommission, refetch }: any) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [currentModal, setCurrentModal] = useState<number>(0);
  const [addCommission] = useAddCommissionMutation();

  const [formData, setFormData] = useState<CommissionFormProps>({
    commissionForValue: '',
    commissionForName: '',
    commissionModeValue: '',
    commissionModeName: '',
    amount: 0,
    percentage: 0,
    fee: 0,
    range: false,
    max: 1000000,
    min: 0,
  });

  const disabledButton =
    (formData.commissionForValue && formData.commissionModeValue) !== '' &&
    formData.commissionForValue !== 'Select One' &&
    formData.commissionModeValue !== 'Select Mode';

  const handleCommissionModeChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setFormData(currData => {
      return {
        ...currData,
        commissionModeName: name,
        commissionModeValue: value,
      };
    });
  };

  const handleCommissionForChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setFormData(currData => {
      return {
        ...currData,
        commissionForName: name,
        commissionForValue: value,
      };
    });
  };

  const handleSelectChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;

    setFormData((currData: any) => {
      return {
        ...currData,
        [name]: checked,
        min: '',
        max: '',
      };
    });
  };

  const handleFormDataChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setFormData(currData => {
      return {
        ...currData,
        [name]: value,
      };
    });
  };

  const handlePercentageChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { min, max, name } = e.target;
    let value = Number.parseInt(e.target.value, 10);

    if (value < parseInt(min) || isNaN(value)) value = 0;
    if (value > parseInt(max)) value = parseInt(max);
    setFormData((currData: any) => {
      return {
        ...currData,
        [name]: value,
      };
    });
  };

  const AddCommission = async function (): Promise<void> {
    try {
      setIsLoading(true);
      if (!formData.range) {
        formData.min = 0;
        formData.max = 1000000;
      }
      const res: any = await addCommission({
        commission_for: formData.commissionForValue,
        commission_mode: formData.commissionModeValue,
        amount: formData.amount,
        percentage: formData.percentage,
        price_range: formData.range,
        maximum: formData.max,
        minimum: formData.min,
      });

      if (res?.data?.status === 'success') {
        setIsLoading(false);
        message.success(res.data.message);
        setCurrentModal(2);
      } else if (res.error) {
        message.error(res.error.data.message);
        setIsLoading(false);
      } else {
        message.error(res.data?.message);
        setIsLoading(false);
      }
    } catch (error: any) {
      message.error(error?.data?.message);
      setIsLoading(false);
    }
  };

  const submitHandler = (e: any) => {
    e.preventDefault();
    setCurrentModal(1);
  };

  return (
    <>
      <Wrapper onSubmit={submitHandler}>
        <div className='select'>
          <Label>Commission For</Label>
          <CustomSelect
            placeholder='Select One'
            fullWidth
            options={[
              { name: 'Service Charge', value: 'service_charge' },
              { name: 'Penalty', value: 'penalty_fee' },
              { name: 'Referral Bonus', value: 'referral_bonus' },
            ]}
            onChange={handleCommissionForChange}
            required
          />
        </div>
        <div className='select'>
          <Label>Commission Mode</Label>
          <CustomSelect
            placeholder='Select Mode'
            fullWidth
            options={[
              {
                name: 'Set By Percentage Amount',
                value: 'percentage',
              },
              { name: 'Set By Fixed Amount', value: 'fixed' },
              { name: 'Set Fee At N0.00', value: 'fee' },
            ]}
            onChange={handleCommissionModeChange}
            required
          />
        </div>

        {formData.commissionModeValue === 'percentage' && (
          <div className='select'>
            <Label>Percentage Value (%)</Label>
            <CustomInput
              fullWidth
              type='number'
              min={1}
              max={100}
              placeholder='/100'
              name='percentage'
              // onChange={handleFormDataChange}
              onChange={handlePercentageChange}
              value={
                formData.percentage && formData.percentage > 0
                  ? formData.percentage
                  : ''
              }
              autoFocus
              required
            />
          </div>
        )}

        {formData.commissionModeValue === 'fixed' && (
          <div className='select'>
            <Label>Amount in Naira (₦)</Label>
            <CustomInput
              fullWidth
              type='number'
              placeholder='Enter Amount'
              name='amount'
              onChange={handleFormDataChange}
              value={
                formData.amount && formData.amount > 0 ? formData.amount : ''
              }
              required
              autoFocus
            />
          </div>
        )}

        {formData.commissionModeValue === 'fee' && (
          <div className='select'>
            <Label>Amount in Naira (₦)</Label>
            <CustomInput
              fullWidth
              type='text'
              placeholder='N0.00'
              name='fee'
              onChange={handleFormDataChange}
              value={formData.fee && formData.fee > 0 ? formData.fee : ''}
              disabled
              required
              autoFocus
            />
          </div>
        )}

        <div className='select mt-2'>
          <Label htmlFor='range'>Set Mode for Specific Price Range</Label>
          <Checkbox
            id='range'
            name='range'
            value={formData.range}
            onChange={handleSelectChange}
          />
        </div>

        {formData.range && (
          <div className='range'>
            <h6>Commission</h6>
            <div className='range__container'>
              <div className='select'>
                <Label>Minimum Value</Label>
                <CustomInput
                  fullWidth
                  type='number'
                  placeholder='Enter amount'
                  name='min'
                  onChange={handleFormDataChange}
                  value={formData.min && formData.min > 0 ? formData.min : ''}
                  required
                  autoFocus
                />
              </div>

              <div className='select'>
                <Label>Maximum Value</Label>
                <CustomInput
                  fullWidth
                  type='number'
                  placeholder='Enter amount'
                  name='max'
                  onChange={handleFormDataChange}
                  value={formData.max && formData.max > 0 ? formData.max : ''}
                  required
                />
              </div>
              {/* <div className='d-flex justify-content-end'>
                    <Button bg='#F24357'>Delete</Button>
                  </div>
                 */}
            </div>
          </div>
        )}
        <div className='d-flex justify-content-end py-2'>
          <Button
            bg='#534ABA'
            type='submit'
            // onClick={() => setModalOpen(true)}
            disabled={!disabledButton}
          >
            Save Changes
          </Button>
        </div>
      </Wrapper>

      {currentModal === 1 && (
        <Modal isShown={true}>
          <Warning
            warningTitle={'Warning'}
            warningText={
              "You're about to set a commission. Are you sure you want to proceed?"
            }
            confirmText={isLoading ? <LoadingButton /> : 'Proceed'}
            confirm={() => AddCommission()}
            closeModal={() => setCurrentModal(0)}
          />
        </Modal>
      )}
      {currentModal === 2 && (
        <Modal isShown={true}>
          <Congratulations
            warningTitle={'Congratulations'}
            warningText={'Changes have been made'}
            confirmText={'Close'}
            closeModal={() => {
              setCurrentModal(0);
              setViewCommission(false);
              refetch();
            }}
          />
        </Modal>
      )}
    </>
  );
};

const Wrapper = styled.form`
  padding: 3rem 1rem;

  .select {
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-gap: 40px;
    align-items: center;
  }

  .range {
    margin-top: 30px;

    h6 {
      color: #909090;
    }

    &__container {
      padding: 2rem 1rem;
      border: 1px solid #d9d9d9;
      border-radius: 8px;
    }

    font-size: 20px;
  }
`;

export default SetCommission;
