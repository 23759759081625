import { Skeleton } from "antd";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useRef } from "react";

import { ReactComponent as NairaIcon } from "../assets/icons/naira-icon.svg";
import { format4dpNumber } from "../helpers/formatNumbers";
import { admin, allTrans } from "../utils/routes";

import Pagination from "./Pagination";

import creditImg from "./../assets/img/credit.png";
import debitImg from "./../assets/img/debit.png";
import NoData from "./bits/NoData";
import Button from "./bits/Button";

type RecentTrans = {
  planName: string;
  created_at: string;
  savingsType: string;
  status: string;
  amount: string;
  reference: string;
  type: string;
  name: string;
};

/* const data: RecentTrans[] = [
  {
    planName: 'Rent',
    created_at: 'Jan 12, 2022',
    savingsType: 'Individual Savings',
    status: 'success',
    amount: '20000.0000',
    reference: 'WT-20220429165120480381947',
    type: 'debit',
    userName: 'James Taiwo',
  },
  {
    planName: 'School Fees',
    created_at: 'Feb 14, 2022',
    savingsType: 'Target Group Savings',
    status: 'failed',
    amount: '10000.0000',
    reference: 'WT-20211202163844920640075',
    type: 'credit',
    userName: 'Martins Fred',
  },
  {
    planName: 'Dream Car',
    created_at: 'Mar 23, 2022',
    savingsType: 'Cooperative Group Savings',
    status: 'success',
    amount: '2500.0000',
    reference: 'WT-20211202163844920640075',
    type: 'debit',
    userName: 'Ayo Hauwa',
  },
  {
    planName: 'Wedding',
    created_at: 'Jan 12, 2022',
    savingsType: 'Individual Savings',
    status: 'success',
    amount: '130000.0000',
    reference: 'WT-20211202163844920640075',
    type: 'debit',
    userName: 'Edith Chidi',
  },
  {
    planName: 'Rent',
    created_at: 'Feb 14, 2022',
    savingsType: 'Target Group Savings',
    status: 'failed',
    amount: '4000.0000',
    reference: 'WT-20211202163844920640075',
    type: 'credit',
    userName: 'Toyosi Brown',
  },
]; */

const RecentTransactions = ({ recentTransactions, type }: any) => {
  //   const { data, isLoading }: any = useTransactionHistoryQuery({
  //     page: 1,
  //     pageSize: 3,
  //   });

  // if (data) console.log(data);

  return (
    <Wrapper className="col-12">
      <div className="trans-table">
        {type === "recent" && <h3>Recent Transactions</h3>}
        {recentTransactions?.length > 0 ? (
          <>
            <table className="table align-middle">
              <thead className="header">
                <tr>
                  <th></th>
                  <th>Transaction ID</th>
                  <th>Date</th>
                  <th>Name</th>
                  <th>Plan Name</th>
                  <th>Plan Type</th>
                  <th>Amount</th>
                  <th className="text-center status">
                    <div className="gd">Status</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {recentTransactions?.map((item: any, index: number) => {
                  const isCardVer: boolean =
                    item.reference.split("-")[0] === "CV";
                  const isWalletTrans: boolean =
                    item.reference.split("-")[0] === "WT";
                  const isWithdrawal: boolean =
                    item.reference.split("-")[0] === "ptba";
                  return (
                    <tr key={index}>
                      <td>
                        <div className="icon align-middle">
                          {item.type === "debit" ? (
                            <img src={debitImg} alt="debit" />
                          ) : (
                            <img src={creditImg} alt="credit" />
                          )}
                        </div>
                      </td>
                      <td>{item?.reference}</td>
                      <td>
                        <Moment format="MMM DD, YYYY">
                          {item?.created_at}
                        </Moment>
                      </td>
                      <td>{item?.name}</td>
                      <td>
                        {isCardVer
                          ? "Card Verification"
                          : isWalletTrans
                          ? "Wallet Transaction"
                          : isWithdrawal
                          ? "Withdrawal"
                          : `${item?.transactionable?.name}`}
                      </td>
                      <td>{item?.savingsType ?? "None"}</td>
                      <td>
                        <div className="amt">
                          {/* {item.amount && `₦ ${formatNumber(parseFloat(item.amount))}`} */}
                          <NairaIcon
                            style={{
                              width: "13px",
                              height: "14px",
                              marginInlineEnd: "0.25rem",
                            }}
                          />
                          {format4dpNumber(item?.amount)}
                        </div>
                      </td>
                      <td className="text-center status">
                        <div
                          className={`${
                            item.status === "success"
                              ? "good"
                              : item?.status === "processing"
                              ? "processing"
                              : "bad"
                          }`}
                        >
                          {item?.status}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </>
        ) : (
          <div className="mt-4 pt-4">
            <NoData
              text="You have not recent transactions. You can perform one "
              link="/user/savings"
            />
          </div>
        )}
        <Link
          to={allTrans}
          style={{
            textDecoration: "none",
            color: "#059157",
            display: "block",
            textAlign: "right",
            marginRight: ".8rem",
          }}
        >
          See All
        </Link>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .trans-table {
    width: 100%;
    // font-size: 16px;
    background: #ffffff;
    box-shadow: 0px 16px 37px rgba(188, 188, 188, 0.1);
    border-radius: 15px;
    text-transform: capitalize;
    padding: 1rem;

    table {
      th {
        padding: 0.5rem;
      }
      td {
        color: #33277b;
        padding: 0.5rem;
      }
    }
    .header {
      color: #47486b99;
      border-top: 2px solid #000;
    }
    .status {
      width: 15%;
    }
    .bad {
      color: #ea0505b2;
      background: rgba(234, 5, 5, 0.15);
      border-radius: 5px;
      padding: 0.5rem;
    }
    .good {
      color: #059157;
      background: rgba(207, 232, 222, 0.2);
      border-radius: 5px;
      padding: 0.5rem;
    }
    .processing {
      color: #f3b756;
      background: rgba(207, 232, 222, 0.2);
      border-radius: 5px;
      padding: 0.5rem;
    }
    .amt {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    /*  @media screen and (min-width: 500px) {
    .gd {
       margin: 0rem 2rem 0rem 0rem;
      padding: 0px;   
    }
    .good,
    .bad,
    .processing {
      margin-right: 2rem; 
    }
  } */

    @media screen and (max-width: 500px) {
      .trans-table {
        overflow-x: auto;
        margin-top: 0 !important;
        padding-top: 0 !important;
        table {
          min-width: 550px;
          th,
          td {
            padding: 0.28rem;
            img {
              height: 25px;
              width: 25px;
            }
          }
        }
      }
    }
  }
  // display: flex;
  // flex-direction: column;
  // background: #FFFFFF;
  // box-shadow: 0px 16px 37px rgba(188, 188, 188, 0.1);
  // border-radius: 15px;

  // padding: 1rem;
  //   .trans-table {
  //     font-size: 16px;
  //     overflow-x: auto;
  //     .opt {
  //       h3 {
  //         font-weight: 600;
  //       }
  //       border: none !important;
  //       display: flex;
  //       flex-direction: row;
  //       justify-content: space-between;
  //       align-items: flex-start;
  //       margin-inline-start: auto;
  //       margin-bottom: 1rem;
  //       .opt-btn {
  //         margin-inline-start: auto;
  //         padding: 1rem;
  //         height: 60px !important;
  //       }
  //     }
  //     table {
  //     },
  //       th, td {
  //       padding: 0.5rem;
  //       font-size: 14.75px;
  //       }
  //       td {
  //         color: #707070;

  //       }
  //     }
  //     .header{
  //       color: #333333;
  //       border-top: 1px solid #999999 ;
  //       border-bottom: 2px solid #999999  !important;
  //     }
  //     .status {
  //       text-transform: capitalize;
  //     }
  //     .bad {
  //       color: #ea0505b2;
  //       background: rgba(234, 5, 5, 0.15);
  //       border-radius: 5px;
  //       padding: 0.5rem;
  //     }
  //     .good {
  //       color: #059157;
  //       background: rgba(207, 232, 222, 0.2);
  //       border-radius: 5px;
  //       padding: 0.5rem;
  //     }
  //     .processing {
  //       color: #F3B756;
  //       background: rgba(207, 232, 222, 0.2);
  //       border-radius: 5px;
  //       padding: 0.5rem;
  //     }
  //     .amt {
  //       display: flex;
  //       flex-direction row;
  //       align-items: center;
  //     }

  //     @media screen and (min-width: 500px) {
  //       .gd {
  //         margin: 0rem 2rem 0rem 0rem;
  //         padding: 0px;
  //       }
  //       .good,
  //       .bad,
  //       .processing {
  //         margin-right: 2rem;
  //       }
  //     }

  //     @media screen and (max-width: 500px) {
  //       .trans-table {
  //         overflow-x: auto;
  //         // width: fit-content;
  //         table {
  //         },
  //           th,
  //           td {
  //             // font-size: 12px !important;
  //           }
  //         }
  //       }
  //     }

  //   }
`;

export default RecentTransactions;
