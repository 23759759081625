import { Skeleton } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as ActiveIcon } from '../../assets/icons/c-active.svg';
import { ReactComponent as InactiveIcon } from '../../assets/icons/c-inactive.svg';
import FilterCard from '../../components/FilterCard';
import Pagination, { CurrPageInfoTypes } from '../../components/Pagination';
import SearchBar from '../../components/SearchBar';
import NoData from '../../components/bits/NoData';
import {
  GetAllCards,
  useGetAllCardsQuery,
} from '../../redux/services/dashboard-services';

/* type CardDataTypes = {
  userName: string;
  phone: string;
  email: string;
  bank: string;
  cardBrand: string;
  last4: number;
  expiryDate: string;
  status: string;
};
 */
const AllCards = () => {
  // const isLoading = false;

  /* const data: CardDataTypes[] = [
    {
      userName: 'Femi Chukwudi',
      phone: '07012345678',
      email: 'tolu@gmail.com',
      bank: 'GTBank',
      cardBrand: 'MasterCard',
      last4: 4081,
      expiryDate: '02/04/2022',
      status: 'active',
    },
    {
      userName: 'Femi Chukwudi',
      phone: '07012345678',
      email: 'tolu@gmail.com',
      bank: 'GTBank',
      cardBrand: 'MasterCard',
      last4: 4081,
      expiryDate: '02/04/2022',
      status: 'inactive',
    },
    {
      userName: 'Femi Chukwudi',
      phone: '07012345678',
      email: 'tolu@gmail.com',
      bank: 'GTBank',
      cardBrand: 'MasterCard',
      last4: 4081,
      expiryDate: '02/04/2022',
      status: 'inactive',
    },
    {
      userName: 'Femi Chukwudi',
      phone: '07012345678',
      email: 'tolu@gmail.com',
      bank: 'GTBank',
      cardBrand: 'MasterCard',
      last4: 4081,
      expiryDate: '02/04/2022',
      status: 'active',
    },
    {
      userName: 'Femi Chukwudi',
      phone: '07012345678',
      email: 'tolu@gmail.com',
      bank: 'GTBank',
      cardBrand: 'MasterCard',
      last4: 4081,
      expiryDate: '02/04/2022',
      status: 'inactive',
    },
  ]; */
  const [data, setData] = useState<GetAllCards[] | any>([]);
  const [currPageInfo, setCurrPageInfo] = useState<CurrPageInfoTypes>({
    pageNo: 1,
    pageSize: 10,
    pageTotalCount: 10,
    pageCount: 1,
    status: '',
    searchTerm: '',
    startDate: null,
    endDate: null,
    startDateString: '',
    endDateString: '',
  });
  const {
    data: db = [],
    isLoading,
    isSuccess,
    refetch,
  }: any = useGetAllCardsQuery({
    pageNo: currPageInfo.pageNo,
    pageSize: currPageInfo.pageSize,
    status: currPageInfo.status,
    searchTerm: currPageInfo.searchTerm,
    duration:
      currPageInfo.startDate && currPageInfo.endDate
        ? `${moment(currPageInfo.startDate).format('YYYY-MM-DD')},${moment(
            currPageInfo.endDate
          ).format('YYYY-MM-DD')}`
        : '',
  });

  useEffect(() => {
    if (isSuccess) {
      const {
        data: { cards: data },
      }: any = db;
      setData(data);
    }
    refetch();
  }, [isSuccess, db, refetch]);

  return (
    <Wrapper>
      {isSuccess && (
        <>
          <div className='page-h top-header'>
            <h2 style={{ fontWeight: 600 }}>All Cards</h2>
            <div className='search'>
              <SearchBar
                width={'90%'}
                setCurrPageInfo={setCurrPageInfo}
                refetch={refetch}
                value={currPageInfo.searchTerm}
              />
            </div>
          </div>
          <FilterCard
            statusOptions={[
              { name: 'Status', value: '' },
              { name: 'Success', value: 1 },
              { name: 'Failed ', value: 0 },
            ]}
            setCurrPageInfo={setCurrPageInfo}
            refetch={refetch}
          />
        </>
      )}

      <div className='trans-table'>
        {isLoading ? (
          <Skeleton active />
        ) : data ? (
          <>
            <table className='table'>
              <thead className='header'>
                <tr>
                  <th>User</th>
                  <th>Phone</th>
                  <th>Email</th>
                  <th>Bank</th>
                  <th>Card Brand</th>
                  <th>Last 4 Digits</th>
                  <th>Expiry Date</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {data?.data?.map((item: any, index: number) => {
                  return (
                    <tr key={index}>
                      <td>{item.user.name}</td>
                      <td>{item.user.phone}</td>
                      <td style={{ wordBreak: 'break-all' }}>{item.email}</td>
                      <td>{item.bank}</td>
                      <td>{item.brand}</td>
                      <td className='text-center'>{item.last4}</td>
                      <td className='text-center'>
                        {/* <Moment format='DD/MM/YYYY'>{item.expiryDate}</Moment> */}
                        {item.exp_month}/{item.exp_year}
                      </td>
                      <td className='text-center'>
                        {item.user.status === 1 ? (
                          <ActiveIcon />
                        ) : (
                          <InactiveIcon />
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </>
        ) : (
          <div className='mt-4 pt-4'>
            <NoData
              text='There are no existing cards.'
              //   link="/user/savings"
            />
          </div>
        )}
      </div>
      {isSuccess && (
        <Pagination
          transPerPage={data?.per_page}
          totalTrans={data?.total}
          currentPage={data?.current_page}
          lastPage={data?.last_page}
          setCurrPageInfo={setCurrPageInfo}
          // pageLimit={parseInt(data?.per_page)}
          currPageInfo={currPageInfo}
          refetch={refetch}
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .top-header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    .search {
      margin-left: auto;
      min-width: 500px;
    }
  }

  .page-h {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .search-div {
      margin-left: auto;
      min-width: 500px;
    }
  }

  .trans-table {
    display: flex;
    flex-direction: column;
    background: #ffffff;
    box-shadow: 0px 16px 37px rgba(188, 188, 188, 0.1);
    border-radius: 15px;
    padding: 2rem 1rem !important;
    font-size: 16px;
    overflow-x: auto;

    table {
      th {
        padding: 0.5rem;
      }
      td {
        padding: 0.5rem;
        color: #707070;
        text-transform: capitalize;
      }
    }
    .header {
      color: #333333;
      border-top: 1px solid #999999;
      border-bottom: 2px solid #999999;
    }
    .status {
      // width: 20%;
    }

    @media screen and (min-width: 500px) {
      .gd {
        margin: 0rem 2rem 0rem 0rem;
        padding: 0px;
      }
    }

    @media screen and (max-width: 500px) {
      .trans-table {
        overflow-x: auto;
        // width: fit-content;
        table {
          th,
          td {
            // font-size: 12px !important;
          }
        }
      }
    }
  }
`;

export default AllCards;
