import { DatePicker } from 'antd';
import React, { ChangeEvent, useState } from 'react';
import styled from 'styled-components';
import 'antd/dist/antd.css';

import { ReactComponent as DropDown } from '../assets/icons/ArrowDown2.svg';
import { ReactComponent as Filter } from '../assets/icons/Filter2.svg';
import { ReactComponent as Calender } from '../assets/icons/calendar.svg';
import { ReactComponent as ResetBtn } from '../assets/icons/reset.svg';

import { CurrPageInfoTypes } from './Pagination';
import Select, { DataTypes } from './bits/Select';

interface FilterCardProps {
  status?: boolean;
  source?: boolean;
  startDate?: boolean;
  endDate?: boolean;
  emails?: boolean;
  frequency?: boolean;
  data?: string;
  setCurrPageInfo: Function;
  refetch: Function;
  statusOptions?: DataTypes[];
  transactionOptions?: DataTypes[];
}

const FilterCard = ({
  source,
  status,
  startDate,
  endDate,
  emails,
  data,
  frequency,
  statusOptions,
  transactionOptions,
  setCurrPageInfo,
  refetch,
}: FilterCardProps) => {
  /*  const [date, setDate] = useState({
    startDate: null,
    endDate: null,
  }); */

  const [show, setShow] = useState({
    count: 0,
    isVisible: false,
  });

  const { count, isVisible } = show;

  const showOptions = (count: number) =>
    setShow(prevState => ({
      ...prevState,
      count,
      isVisible: !prevState.isVisible,
    }));

  const handleStartDateChange = (date: any, dateString: any) => {
    setCurrPageInfo((currInfo: CurrPageInfoTypes) => {
      return {
        ...currInfo,
        startDate: date,
        startDateString: dateString,
      };
    });
  };

  const handleEndDateChange = (date: any, dateString: any) => {
    setCurrPageInfo((currInfo: CurrPageInfoTypes) => {
      return {
        ...currInfo,
        endDate: date,
        endDateString: dateString,
      };
    });
  };

  const handleStatusChange = (e: ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    console.log(typeof val);
    setCurrPageInfo((currInfo: CurrPageInfoTypes) => {
      return {
        ...currInfo,
        status: val,
        pageNo: 1,
      };
    });
    refetch();
  };

  const handleTypeChange = (e: ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    setCurrPageInfo((currInfo: CurrPageInfoTypes) => {
      return {
        ...currInfo,
        type: val,
        pageNo: 1,
      };
    });
    refetch();
  };

  /* const handleSurceChange = (e: ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    setCurrPageInfo((currInfo: CurrPageInfoTypes) => {
      return {
        ...currInfo,
        source: val,
        pageNo: 1,
      };
    });
    refetch();
  }; */

  const resetFilterOptions = () => {
    setCurrPageInfo((currInfo: CurrPageInfoTypes) => {
      return {
        ...currInfo,
        type: '',
        status: '',
        pageNo: 1,
        pageCount: 1,
        pageTotalCount: 10,
        startDate: null,
        endDate: null,
      };
    });
    refetch();
  };

  return (
    <Wrapper>
      <div className='input-container'>
        <div className='filter__items' onClick={() => showOptions(0)}>
          <div className='filter__icons'>
            <Filter />
          </div>
          <div className='filter__text'>Filter by</div>
        </div>
        {frequency && (
          <div className='filter__items' onClick={() => showOptions(2)}>
            <div className='filter__text'>Frequency</div>
            <div className='filter__icons'>
              <DropDown className='calender__icon' />
            </div>
            {isVisible && count === 2 && (
              <div className='filter__options'>
                <span>Weekly</span>
                <span>Monthly</span>
              </div>
            )}
          </div>
        )}
        {startDate && (
          <div className='filter__items' onClick={() => showOptions(0)}>
            <div className='filter__icons'>
              <Calender className='calender__icon' />
            </div>
            <div className='filter__text'>
              <DatePicker
                placeholder='Start Date'
                bordered={false}
                suffixIcon={<DropDown className='dropdown__icon' />}
                onChange={handleStartDateChange}
              />
            </div>
          </div>
        )}
        {endDate && (
          <div className='filter__items' onClick={() => showOptions(0)}>
            <div className='filter__icons'>
              <Calender className='calender__icon' />
            </div>
            <div className='filter__text'>
              <DatePicker
                placeholder='End Date'
                bordered={false}
                suffixIcon={<DropDown className='dropdown__icon' />}
                onChange={handleEndDateChange}
              />
            </div>
          </div>
        )}

        {transactionOptions && (
          <div className='filter__items'>
            <Select
              // placeholder='Transaction Type'
              options={transactionOptions}
              onChange={handleTypeChange}
            />
          </div>
        )}

        {statusOptions && (
          <div className='filter__items'>
            <Select
              // placeholder='Status'
              options={statusOptions}
              onChange={handleStatusChange}
            />
          </div>
        )}
        {source && (
          <div className='filter__items' onClick={() => showOptions(2)}>
            <div className='filter__text'>Source</div>
            <div className='filter__icons'>
              <DropDown className='calender__icon' />
            </div>
            {isVisible && count === 2 && (
              <div className='filter__options'>
                <span>Desktop</span>
                <span>Mobile</span>
              </div>
            )}
          </div>
        )}
        {emails && (
          <div className='filter__items' onClick={() => showOptions(3)}>
            <div className='filter__text'>Email</div>
            <div className='filter__icons'>
              <DropDown className='calender__icon' />
            </div>
            {isVisible && count === 3 && (
              <div className='filter__options email__option'>
                {/* {data.data?.map((item: any) => ( */}
                <span>Email</span>
                {/* ))} */}
              </div>
            )}
          </div>
        )}
        <div
          className='filter__items'
          // onClick={() => showOptions(0)}
          onClick={resetFilterOptions}
        >
          <div className='filter__icons'>
            <ResetBtn className='resetBtn__icon' />
          </div>
          <div className='filter__text reset__btn'>Reset Filter</div>
        </div>
      </div>
    </Wrapper>
  );
};

export default FilterCard;

const Wrapper = styled.div`
  padding: 1rem 0;
  display: flex;

  .input-container {
    display: flex;
    align-content: center;
    outline: none;
    border: 1.3px solid #7b7b7b;
    border-radius: 5px;
    padding: 0.2rem 0;
    gap: 0.1rem;

    .filter__items {
      display: flex;
      align-items: center;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      padding: 0.5rem 1rem;
      border-right: 1.5px solid #7b7b7b;
      cursor: pointer;
      position: relative;
      top: 0;

      .filter__options {
        position: absolute;
        top: 45px;
        left: 10px;
        display: flex;
        flex-direction: column;
        /* gap: 1rem; */
        font-size: 1.1rem;
        color: #3f3f3f;
        background: #fff;
        padding: 10px;
        box-shadow: 0 0 4px #7b7b7b;
        width: 150px;
        border-radius: 0.2rem;

        &.email__option {
          width: 20rem;
        }
        & > span {
          padding: 8px;
          background: rgba(255, 255, 255, 0.5);

          &:first-child:hover {
            border-top: none;
            background: rgba(191, 213, 204, 0.5);
            color: #059157;
            border-radius: 0.2rem;
          }

          &:last-child:hover {
            color: #f12f2f;
            border-radius: 5px;
            background: rgba(238, 108, 108, 0.258);
          }
        }
      }
      &:first-child {
        cursor: context-menu;
      }

      &:last-child {
        border-right: none;
      }

      .filter__icons {
        .calender__icon {
          margin-right: -10px;
        }
      }

      .filter__text {
        color: #7b7b7b;
        font-weight: 500;
        font-size: 1.2rem;
        line-height: 24px;

        &.reset__btn {
          color: #f12f2f;
        }

        .ant-picker {
          background: transparent;
          color: #7b7b7b;
          width: 9rem;

          .dropdown__icon {
            margin-right: -10px;
          }
          .ant-picker-input > input,
          .ant-picker-input > input::placeholder {
            font-size: 1.1rem;
            color: #7b7b7b;
            cursor: pointer;
          }
        }
      }
    }
  }
`;
