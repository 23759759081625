import { message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { format4dpNumber, formatString } from '../helpers/formatNumbers';
import { useDeleteCommissionMutation } from '../redux/services/commission-services';
import { commission } from '../utils/routes';

import Congratulations from './Congratulations';
import Modal from './Modal';
import Warning from './Warning';
import Button from './bits/Button';
import Checkbox from './bits/Checkbox';
import CustomInput from './bits/CustomInput';
// import LoadingButton from './bits/LoadingButton';
import LoadingButton from './bits/LoadingButton';
import { Label } from './bits/Text';

// export type CommissionFormProps = {
//   commissionFor: string;
//   commissionModeValue: string;
//   commissionModeName: string;
//   amount: number;
//   percentage: number;
//   fixedAmount: number;
//   range: boolean;
//   max: number;
//   min: number;
// };

const VeiwCommission = ({ data, refetch, setViewCommission }: any) => {
  // const { data, refetch, isLoading, isSuccess }: any = useGetCommissionsQuery();

  // const { id } = useParams<{ id: any }>();
  const navigate = useNavigate();

  const [isDelLoading, setDelLoading] = useState<boolean>(false);
  const [currentModal, setCurrentModal] = useState<number>(0);

  const [deleteCommission] = useDeleteCommissionMutation();

  useEffect(() => {
    refetch();
  }, [refetch]);

  const DeleteCommission = async (id: any): Promise<void> => {
    try {
      setDelLoading(true);
      const res: any = await deleteCommission(id);
      if (res?.data?.status === 'success') {
        setDelLoading(false);
        message.success(res.data.message);
        setCurrentModal(2);
      } else if (res.error) {
        setDelLoading(false);
        message.error(res.error?.data?.message);
      } else {
        setDelLoading(false);
        message.error(res.data.message);
      }
    } catch (error: any) {
      setDelLoading(false);
      message.error(error?.data?.message);
    }
  };

  const submitHandler = (e: any) => {
    e.preventDefault();
    setCurrentModal(1);
  };

  return (
    <Wrapper>
      {data?.map((item: any) => (
        <>
          <div key={item.id} className='view_commission'>
            <h5>Commission</h5>
            <div className='range__container'>
              <div>
                <div className='select'>
                  <Label>Set Commission</Label>
                  <CustomInput
                    type='text'
                    disabled={true}
                    value={formatString(item?.commission_for)}
                  />
                </div>
                <div className='select'>
                  <Label>Commission Mode</Label>
                  <CustomInput
                    type='text'
                    disabled={true}
                    value={formatString(item?.commission_mode)}
                  />
                </div>

                {item?.commission_mode === 'percentage' ? (
                  <div className='select'>
                    <Label>Percentage Value (%)</Label>
                    <CustomInput
                      type='text'
                      disabled={true}
                      value={Number.parseInt(item?.percentage)}
                      // value={`${Number.parseInt(item?.percentage)}%`}
                    />
                  </div>
                ) : (
                  <div className='select'>
                    <Label>Amount in Naira (₦)</Label>
                    <CustomInput
                      type='text'
                      disabled={true}
                      value={format4dpNumber(Number.parseFloat(item?.amount))}
                    />
                  </div>
                )}

                {/* {item?.amount && (
                <div className='select'>
                  <Label>Amount in Naira (N)</Label>
                  <CustomInput
                    type='number'
                    disabled={true}
                    value={item?.amount}
                  />
                </div>
              )} */}

                {item.price_range === 1 && (
                  <>
                    <div className='select mt-2'>
                      <Label htmlFor='range'>
                        Set Mode for Specific Price Range
                      </Label>
                      <Checkbox
                        id='range'
                        name='range'
                        value={item?.price_range}
                        checked={item?.price_range}
                        // onChange={handleSelectChange}
                        disabled={true}
                      />
                    </div>

                    <div className='range'>
                      <div className='select'>
                        <Label>Minimum Value</Label>
                        <CustomInput
                          type='text'
                          disabled={true}
                          value={format4dpNumber(
                            Number.parseFloat(item?.minimum)
                          )}
                        />
                      </div>

                      <div className='select'>
                        <Label>Maximum Value</Label>
                        <CustomInput
                          type='text'
                          disabled={true}
                          value={format4dpNumber(
                            Number.parseFloat(item?.maximum)
                          )}
                        />
                      </div>
                    </div>
                  </>
                )}
                <div className='d-flex justify-content-end gap-2'>
                  <Button
                    bg='white'
                    color='#33277b'
                    border='#33277b'
                    fontSize='16px'
                    onClick={() => {
                      navigate(`../${commission}/${item?.id}`);
                      setViewCommission(true);
                    }}
                  >
                    Edit
                  </Button>
                  <Button
                    bg='#F24357'
                    fontSize='16px'
                    // onClick={() => DeleteCommission(item.id)}
                    onClick={submitHandler}
                  >
                    Delete
                  </Button>
                </div>
              </div>
            </div>
          </div>
          {currentModal === 1 && (
            <Modal isShown={true}>
              <Warning
                warningTitle={'Warning'}
                warningText={
                  "You're about to delete this commission. Are you sure you want to proceed?"
                }
                confirmText={isDelLoading ? <LoadingButton /> : 'Proceed'}
                confirm={() => DeleteCommission(item.id)}
                closeModal={() => setCurrentModal(0)}
              />
            </Modal>
          )}
          {currentModal === 2 && (
            <Modal isShown={true}>
              <Congratulations
                warningTitle={'Congratulations'}
                warningText={'Commission has been deleted'}
                confirmText={'Close'}
                closeModal={() => {
                  setCurrentModal(0);
                  setViewCommission(false);
                  refetch();
                }}
              />
            </Modal>
          )}
        </>
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  border-radius: 0.5rem;
  /* box-shadow: 0px 16px 37px rgba(188, 188, 188, 0.1); */
  padding: 2rem 5rem;

  h5 {
    color: #909090;
  }

  .select {
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-gap: 40px;
    align-items: center;
  }

  .range {
    margin-top: 30px;

    &__container {
      padding: 2rem 1rem;
      border: 1px solid #d9d9d9;
      border-radius: 8px;
    }

    font-size: 20px;
  }

  .view_commission {
    margin-bottom: 4rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export default VeiwCommission;
