import { Skeleton } from 'antd';
import Moment from 'react-moment';
import styled from 'styled-components';

import { ReactComponent as NairaIcon } from '../../assets/icons/naira-icon.svg';
import NoData from '../../components/bits/NoData';
// import Pagination from '../../components/Pagination';
import { format4dpNumber } from '../../helpers/formatNumbers';

type ReferralType = {
  referredUser: string;
  referredUserEmail: string;
  referrer: string;
  referrerEmail: string;
  dateReferred: string;
  referralsBonus: number;
  bonusPaymentDate: string;
  paymentStatus: string;
};

const data: ReferralType[] = [
  {
    referredUser: 'Lanre Tosin',
    referredUserEmail: 'lanre@gmail.com',
    referrer: 'Tayo Tosin',
    referrerEmail: 'tayo@gmail.com',
    dateReferred: '04/02/2021',
    referralsBonus: 1000,
    bonusPaymentDate: '05/04/2022',
    paymentStatus: 'collected',
  },
  {
    referredUser: 'Lanre Tosin',
    referredUserEmail: 'lanre@gmail.com',
    referrer: 'Tayo Tosin',
    referrerEmail: 'tayo@gmail.com',
    dateReferred: '04/02/2021',
    referralsBonus: 1000,
    bonusPaymentDate: '05/04/2022',
    paymentStatus: 'pending',
  },
  {
    referredUser: 'Lanre Tosin',
    referredUserEmail: 'lanre@gmail.com',
    referrer: 'Tayo Tosin',
    referrerEmail: 'tayo@gmail.com',
    dateReferred: '04/02/2021',
    referralsBonus: 1000,
    bonusPaymentDate: '05/04/2022',
    paymentStatus: 'pending',
  },
  {
    referredUser: 'Lanre Tosin',
    referredUserEmail: 'lanre@gmail.com',
    referrer: 'Tayo Tosin',
    referrerEmail: 'tayo@gmail.com',
    dateReferred: '04/02/2021',
    referralsBonus: 1000,
    bonusPaymentDate: '05/04/2022',
    paymentStatus: 'collected',
  },
  {
    referredUser: 'Lanre Tosin',
    referredUserEmail: 'lanre@gmail.com',
    referrer: 'Tayo Tosin',
    referrerEmail: 'tayo@gmail.com',
    dateReferred: '04/02/2021',
    referralsBonus: 1000,
    bonusPaymentDate: '05/04/2022',
    paymentStatus: 'collected',
  },
];

const Referrals = () => {
  const isLoading = false;

  return (
    <Wrapper>
      <h2 style={{ fontWeight: 600, marginBottom: '1rem' }}>Referrals</h2>
      <div className='trans-table'>
        {isLoading ? (
          <Skeleton active />
        ) : data?.length > 0 ? (
          <>
            <table className='table-borderless'>
              <thead className='header'>
                <tr>
                  <th>Referred User</th>
                  <th>Referred User’s Email</th>
                  <th>Referrer</th>
                  <th>Referrer’s Email</th>
                  <th>Date Referred</th>
                  <th>Referrals Bonus Amount</th>
                  <th>Bonus Payment Date</th>
                  <th className='text-center status'>
                    <div className='gd'>Payment Status</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {data?.map((item: any, index: number) => {
                  return (
                    <tr key={index}>
                      <td>{item.referredUser}</td>
                      <td
                        style={{
                          textTransform: 'lowercase',
                          wordBreak: 'break-all',
                        }}
                      >
                        {item.referredUserEmail}
                      </td>
                      <td style={{ width: '10%', textAlign: 'center' }}>
                        {item.referrer}
                      </td>
                      <td style={{ textTransform: 'lowercase' }}>
                        {item.referrerEmail}
                      </td>
                      <td>
                        <Moment format='DD/MM/YYYY'>{item.dateReferred}</Moment>
                      </td>
                      <td>
                        <div className='amt'>
                          <NairaIcon
                            style={{
                              width: '13px',
                              height: '14px',
                              marginInlineEnd: '0.25rem',
                            }}
                          />
                          {format4dpNumber(item.referralsBonus)}
                        </div>
                      </td>
                      {/* <td>{item.referralsBonus}</td> */}
                      <td>
                        <Moment format='DD/MM/YYYY'>
                          {item.bonusPaymentDate}
                        </Moment>
                      </td>

                      <td className='text-center status'>
                        <div
                          className={`${
                            item.paymentStatus === 'collected'
                              ? 'good'
                              : item.paymentStatus === 'pending'
                              ? 'processing'
                              : 'bad'
                          }`}
                        >
                          {item.paymentStatus}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </>
        ) : (
          <div className='mt-4 pt-4'>
            <NoData
              text='You have not recent transactions. You can perform one '
              link='/user/savings'
            />
          </div>
        )}
      </div>
      {/* <Pagination page={1} total={100} totalPerPage={5} count={1} /> */}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .trans-table {
    display: flex;
    flex-direction: column;
    background: #ffffff;
    box-shadow: 0px 16px 37px rgba(188, 188, 188, 0.1);
    border-radius: 15px;
    padding: 2rem 1rem !important;
    font-size: 16px;
    overflow-x: auto;

    table {
      th,
      td {
        padding: 0.5rem;
      }
      td {
        color: #707070;
        text-transform: capitalize;
      }
      th {
        border-left: 1px solid #333333;
        /* padding-left: 1em;
        margin-left: .5em;  */
      }
      th:first-child {
        border-left: none;
      }
    }
    .header {
      color: #333333;
      border-top: 1px solid #999999;
      border-bottom: 1px solid #999999;
    }
    .status {
      // text-transform: capitalize;
    }
    .bad {
      color: #ea0505b2;
      background: rgba(234, 5, 5, 0.15);
      border-radius: 5px;
      padding: 0.5rem;
    }
    .good {
      color: #059157;
      background: rgba(207, 232, 222, 0.2);
      border-radius: 5px;
      padding: 0.5rem;
    }
    .processing {
      color: #f0590a;
      background: #fdf0da;
      border-radius: 5px;
      padding: 0.5rem;
    }
    .amt {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    @media screen and (min-width: 500px) {
      .gd {
        margin: 0rem 2rem 0rem 0rem;
        padding: 0px;
      }
      .good,
      .bad,
      .processing {
        margin-right: 2rem;
      }
    }

    @media screen and (max-width: 500px) {
      .trans-table {
        overflow-x: auto;
        // width: fit-content;
        table {
          th,
          td {
            // font-size: 12px !important;
          }
        }
      }
    }
  }
`;

export default Referrals;
