import React from "react";
import styled from "styled-components";

import { ReactComponent as GroupPrpIcon } from "../assets/icons/group-purple.svg";
import { ReactComponent as ShareIconWhite } from "../assets/icons/share-icon-white.svg";
import { ReactComponent as ShareIcon } from "../assets/icons/share-icon.svg";
import { ReactComponent as StocksDownIcon } from "../assets/icons/stocks-down.svg";
import { ReactComponent as StocksUpIcon } from "../assets/icons/stocks-up.svg";
import { ReactComponent as SavingsIcon } from "../assets/icons/wallet-card.svg";
import { format4dpNumber, formatNumber } from "../helpers/formatNumbers";

type DLCardProps = {
  title: string;
  amount?: number | string;
  count?: string;
  percentChange?: number;
  duration?: string;
  bColor?: string;
  image: string;
  RealUsersAmount?: number;
  RealUsersCount?: string;
  VirCommAmount?: number;
  VirUserCount?: string;
  minHeight?: string;
};

const DLCard = ({
  title,
  amount,
  percentChange,
  duration,
  count,
  bColor,
  image,
  RealUsersAmount,
  RealUsersCount,
  VirCommAmount,
  VirUserCount,
  minHeight,
}: DLCardProps) => {
  return (
    <Wrapper color={bColor} minHeight={minHeight}>
      <div className="top-row">
        <p className="title">{title}</p>
        <span className="top-icon">
          {image === "wallet-white" ? (
            <SavingsIcon className="icon" />
          ) : image === "group-purple" ? (
            <GroupPrpIcon className="icon" />
          ) : image === "share-icon" ? (
            <ShareIcon className="icon" />
          ) : image === "share-icon-white" ? (
            <ShareIconWhite />
          ) : (
            <SavingsIcon className="icon" />
          )}
        </span>
      </div>
      {amount && (
        <h4 style={{ color: bColor ? "#ffffff" : bColor }}>
          ₦ {format4dpNumber(amount)}
        </h4>
      )}
      {count && (
        <h4 style={{ color: bColor ? "#ffffff" : bColor }}>
          {formatNumber(count)}
        </h4>
      )}

      <div className="users">
        <div className="users__section">
          <div className="users__option">
            {(RealUsersAmount || RealUsersCount) && <span>Real Users</span>}
            <div>
              {RealUsersAmount && (
                <span className="user__option-amount">
                  ₦{format4dpNumber(RealUsersAmount)}
                </span>
              )}
              {RealUsersCount && (
                <span className="user__option-count">
                  {formatNumber(RealUsersCount)}
                </span>
              )}
            </div>
          </div>
          <div className="users__option">
            <div>
              {VirCommAmount && <span>Virtual Community</span>}
              {VirUserCount && <span>Virtual Users</span>}
            </div>
            <div>
              {VirCommAmount && (
                <span className="user__option-amount">
                  ₦{format4dpNumber(VirCommAmount)}
                </span>
              )}
              {VirUserCount && (
                <span className="user__option-count">
                  {formatNumber(VirUserCount)}
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
      {percentChange && (
        <div className="btm-row">
          {percentChange >= 0 ? (
            <span style={{ color: "#20B35B", marginInlineEnd: "0.5rem" }}>
              <StocksUpIcon className="me-1 arrow" />
              {Math.abs(percentChange)}%
            </span>
          ) : (
            <span style={{ color: "#F12F2F", marginInlineEnd: "0.5rem" }}>
              <StocksDownIcon className="me-1" />
              {Math.abs(percentChange)}%
            </span>
          )}
          {percentChange > 0 ? "Up" : "Down"} from {duration}
        </div>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: flex-start; */
  justify-content: space-between;
  gap: 0.7rem;
  background: ${({ color }: any) => (color ? color : "#ffffff")};
  color: ${({ color }: any) => (color ? "white" : "##33277B")};
  box-shadow: 0px 16px 37px rgba(188, 188, 188, 0.1);
  border-radius: 15px;
  padding: 1rem;
  min-height: ${(props: { minHeight: any }) =>
    props.minHeight ? props.minHeight : "230px"};

  .top-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: -webkit-fill-available;
    .title {
      margin: 0;
    }
    .top-icon {
      background: ${({ color }: any) =>
        color ? "rgba(255, 255, 255, 0.1)" : "rgba(51, 39, 123, 0.1);"};
      border-radius: 10px;
      padding: 0.6rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 0.5rem;
      width: 40px;
      height: 40px;
      .icon {
        width: 30px;
        height: 30px;
      }
    }
  }

  .users {
    width: 100%;
    display: flex;
    flex-direction: column;
    /* margin-top: 1rem; */

    .users__section {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 1rem;

      .users__option {
        font-size: 0.9rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        /* gap: 0.3rem; */

        .user__option-count,
        .user__option-amount {
          font-size: 1rem;
        }
        .user__option-count {
          font-weight: 600;
        }
      }
    }
  }
  .btm-row {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: end;
    width: -webkit-fill-available;
    margin-bottom: 0.5rem;
  }
`;

export default DLCard;
