import { baseApi } from './base-service';

export interface GetDashBoardSummary {
  total_savings?: number;
  total_transaction?: string;
  total_transaction_users?: string;
  total_purse_balance?: number;
  total_individual_savings?: number;
  total_target?: number;
  total_withdrawal?: number;
  total_transaction_virtual?: string;
  total_coop?: string;
  total_coop_users?: number;
  total_coop_virtual?: number;
  total_revenue?: number;
  total_charge?: number;
  yesterday?: number;
  this_week?: number;
  this_month?: number;
  total_users?: string;
  today_users?: string;
  yesterday_users?: string;
  this_week_users?: string;
  this_month_users?: string;
  complete_registration: number;
  incomplete_registration: number;
}

export interface GetAllAccounts {
  name: string;
  last_name: string;
  email: string;
  wallet: {
    created_at: string;
    status: string;
    amount: string;
    user_id: string;
  };
  source: string;
}

export interface GetAllCards {
  user: {
    name: string;
    last_name: string;
    phone: string;
    email: string;
    status: number;
  };
  bank: string;
  brand: string;
  last4: string;
  exp_month: string;
  exp_year: string;
}

export interface GetOtherCards {
  page?: string;
  value?: number;
}

export interface GetAccountBreakdown {
  central_valut: string;
  actual_amount: string;
  virtual_community_contribution: string;
  locked_accounts: string;
  individual_savings: string;
  coop_savings: string;
  target_group_savings: string;
}

export interface GetUserBreakdown {
  month: string;
  year: string;
  total_user: number;
  new_user: number;
}

export interface GetPurse {
  total_savings: string;
  total_users: number;
  wallet: {
    name: string;
    last_name: string;
    email: string;
    phone: string;
    status: number;
    wallet: {
      created_at: string;
      updated_at: string;
      balance: string;
    };
  }[];
}

export interface GetIndContribution {
  total_individual_savings: string;
  total_users: string;
  name?: string;
  email?: string;
  plan?: string;
  target_amount?: string;
  created_at?: string;
  amount?: string;
  balance?: string;
  owner_name: string;
  owner_email: string;
}

// interface GetIndContributionSort {
//   start_date?: string;
//   end_date?: string;
//   status?: string;
// }

export interface GetTargetContribution {
  total_group: string;
  total_users: string;
  total_target_group_contribution: string;
  target_group_savings: {
    no_of_participants: number;
    name: string;
    plan: string;
    owner_email: string;
    target_amount: string;
    start_date: string;
    end_date: string;
    created_at: string;
    owner_name: string;
    status: string;
  }[];
}

export interface GetCoopContribution {
  name: string;
  owner_email: string;
  owner_name: string;
  no_of_participants: number;
  total_coop: string;
  total_coop_users: string;
  total_coop_virtual: string;
  total_users: string;
  amount: string;
  plan: string;
  start_date: string;
  created_at: string;
  end_date: string;
  status: string;
  group_status?: string;
}

export interface GetAllTransactions {
  total_transaction: string;
  today_transaction: string;
  yesterday_transaction: string;
  this_week_transaction: string;
  last_week_transaction: string;

  transactions: {
    owner_name: string;
    owner_email: string;
    reference: string;
    amount: string;
    created_at: string;
    type: string;
    status: string;
    transactionable_type: string;
  }[];
}

interface GetAllInfo {
  pageNo?: number;
  pageSize?: number;
  status?: string | number;
  duration?: string;
  type?: string;
  searchTerm?: string;
}

interface GetBlacklistedAccount {
  name: string;
  email: string;
  offence: string;
  date: string;
}

export interface GetOverview {
  month?: number;
  year?: number;
}

interface BlacklistedAccount {
  ids: string[];
}

const dashboardServiceApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getDashBoardSummary: builder.query<GetDashBoardSummary, GetOverview>({
      query: ({ year, month }) => ({
        url: `/api/v1/admin?year=${year}&month=${month}`,
      }),
      transformResponse: (response: any) => response.data,
    }),
    getAllAccounts: builder.query<GetAllAccounts, GetAllInfo>({
      query: ({ pageNo, pageSize, duration, status, searchTerm }) => ({
        url: `/api/v1/admin/all-account/${searchTerm}?filter[created_between]=${duration}&filter[status]=${status}&size=${pageSize}&page=${pageNo}`,
      }),
    }),
    getSingleAccounts: builder.query<void, string>({
      query: id => ({
        url: `/api/v1/admin/all-account/user/${id}`,
      }),
    }),
    updateAccount: builder.mutation<any, any>({
      query: body => ({
        url: `/api/v1/admin/all-account/user/${body.id}`,
        method: 'PUT',
        body: body,
      }),
    }),
    getAccountBreakdown: builder.query<GetAccountBreakdown, void>({
      query: () => ({
        url: `/api/v1/admin/account-breakdown`,
      }),
    }),
    getAllCards: builder.query<GetAllCards, GetAllInfo>({
      query: ({ pageNo, pageSize, duration, status, searchTerm }) => ({
        url: `/api/v1/admin/cards/${searchTerm}?filter[status]=${status}&filter[created_between]=${duration}&page=${pageNo}&size=${pageSize}`,
      }),
    }),
    getOtherCards: builder.query<void, GetOtherCards>({
      query: ({ page, value }) => ({
        url: `/api/v1/admin/cards/?${page}=${value}`,
      }),
    }),
    getUserBreakdown: builder.query<GetUserBreakdown, GetAllInfo>({
      query: ({ pageNo, pageSize, searchTerm }) => ({
        url: `/api/v1/admin/user-breakdown/${searchTerm}?size=${pageSize}&page=${pageNo}`,
      }),
    }),
    getPurse: builder.query<GetPurse, GetAllInfo>({
      query: ({ pageNo, pageSize, duration, status, searchTerm }) => ({
        url: `/api/v1/admin/purse/${searchTerm}?filter[created_between]=${duration}&page=${pageNo}&size=${pageSize}`,
      }),
    }),
    getIndContribution: builder.query<GetIndContribution, GetAllInfo>({
      query: ({ pageNo, pageSize, duration, status, searchTerm }) => ({
        url: `/api/v1/admin/individual-plan/${searchTerm}?filter[created_between]=${duration}&filter[status]=${status}&size=${pageSize}&page=${pageNo}`,
      }),
    }),
    getTargetContribution: builder.query<GetTargetContribution, GetAllInfo>({
      query: ({ pageNo, pageSize, duration, status, searchTerm }) => ({
        url: `/api/v1/admin/target-plan/${searchTerm}?filter[created_between]=${duration}&filter[status]=${status}&size=${pageSize}&page=${pageNo}`,
      }),
    }),
    getCoopContribution: builder.query<GetCoopContribution[], GetAllInfo>({
      query: ({ pageNo, pageSize, duration, status, searchTerm }) => ({
        url: `/api/v1/admin/coop-plan/${searchTerm}?filter[created_between]=${duration}&filter[status]=${status}&size=${pageSize}&page=${pageNo}`,
      }),
    }),
    getAllTransactions: builder.query<GetAllTransactions, void>({
      query: () => ({
        url: `/api/v1/admin/all-transaction`,
      }),
    }),
    getAllTransactionsHistory: builder.query<GetAllTransactions, GetAllInfo>({
      query: ({ pageNo, pageSize, duration, status, type }) => ({
        url: `/api/v1/admin/all-transaction?filter[type]=${type}&filter[status]=${status}&filter[created_between]=${duration}&size=${pageSize}&page=${pageNo}`,
      }),
    }),
    getBlackListedAccounts: builder.query<GetBlacklistedAccount, GetAllInfo>({
      query: ({ pageNo, pageSize, duration, searchTerm }) => ({
        url: `/api/v1/admin/blacklisted/${searchTerm}?filter[created_between]=${duration}&size=${pageSize}&page=${pageNo}`,
      }),
    }),
    addBlackListedAccount: builder.mutation<any, BlacklistedAccount>({
      query: body => ({
        url: `/api/v1/admin/all-account/user/deactivate`,
        method: 'POST',
        body,
      }),
    }),
    activeBlackListedAccount: builder.mutation<any, BlacklistedAccount>({
      query: body => ({
        url: `/api/v1/admin/all-account/user/activate`,
        method: 'POST',
        body,
      }),
    }),
    activityReport: builder.query<any, any>({
      query: ({pageNo, pageSize}) => ({
        url: `/api/v1/admin/auditTrail?page=${pageNo}&size=${pageSize}`,
        method: 'GET',
        transformResponse: (response: any) => response

      }),
    })
  }),
  overrideExisting: false,
});

export const {
  useGetDashBoardSummaryQuery,
  useGetAllAccountsQuery,
  useGetAllCardsQuery,
  useGetOtherCardsQuery,
  useGetAccountBreakdownQuery,
  useGetUserBreakdownQuery,
  useGetPurseQuery,
  useGetTargetContributionQuery,
  useGetIndContributionQuery,
  useGetCoopContributionQuery,
  useGetAllTransactionsQuery,
  useGetSingleAccountsQuery,
  useGetAllTransactionsHistoryQuery,
  useGetBlackListedAccountsQuery,
  useAddBlackListedAccountMutation,
  useActiveBlackListedAccountMutation,
  useUpdateAccountMutation,
  useActivityReportQuery
} = dashboardServiceApi;
