import styled from "styled-components";

import { GetDashBoardSummary } from "../redux/services/dashboard-services";

import DLCard from "./DLCard";

const DLanding = ({ dashboard }: any) => {
  const {
    total_savings,
    total_transaction,
    total_transaction_users,
    total_transaction_virtual,
    total_coop,
    total_coop_users,
    total_coop_virtual,
    total_purse_balance,
    total_individual_savings,
    total_target,
    total_withdrawal,
  }: GetDashBoardSummary = dashboard;

  return (
    <Wrapper className="row">
      <div className="col-md-6 col-lg-4 col-xl-4 p-3">
        <DLCard
          title="Total Savings"
          amount={total_savings}
          percentChange={5.5}
          duration={"last week"}
          bColor={"#33277B"}
          image="wallet-white"
        />
      </div>
      <div className="col-md-6 col-lg-4 col-xl-4 p-3">
        <DLCard
          title="Total Transactions
            Count"
          count={total_transaction}
          percentChange={3.2}
          duration={"last week"}
          bColor={"#33277B"}
          image="wallet-white"
          RealUsersCount={total_transaction_users}
          VirUserCount={total_transaction_virtual}
        />
      </div>
      <div className="col-md-6 col-lg-4 col-xl-4 p-3">
        <DLCard
          title="Total Cooperative Contributions"
          amount={total_coop}
          percentChange={-3.7}
          duration={"last week"}
          bColor={"#33277B"}
          image="wallet-white"
          RealUsersAmount={total_coop_users}
          VirCommAmount={total_coop_virtual}
        />
      </div>
      <div className="col-md-6 col-lg-4 col-xl-4 p-3">
        <DLCard
          title="Total Purse Balance"
          amount={total_purse_balance}
          percentChange={5.5}
          duration={"last week"}
          bColor={"#33277B"}
          image="wallet-white"
        />
      </div>
      <div className="col-md-6 col-lg-4 col-xl-4 p-3">
        <DLCard
          title="Total Individual Plan Contributions"
          amount={total_individual_savings}
          percentChange={5.5}
          duration={"last week"}
          bColor={"#33277B"}
          image="wallet-white"
        />
      </div>
      <div className="col-md-6 col-lg-4 col-xl-4 p-3">
        <DLCard
          title="Total Target Group Contributions"
          amount={total_target}
          percentChange={5.5}
          duration={"last week"}
          bColor={"#33277B"}
          image="wallet-white"
        />
      </div>
      <div className="col-md-6 col-lg-4 col-xl-4 p-3">
        <DLCard
          title="Total Withdrawals"
          amount={total_withdrawal}
          percentChange={5.5}
          duration={"last week"}
          bColor={"#33277B"}
          image="wallet-white"
        />
      </div>
    </Wrapper>
  );
};

export default DLanding;

const Wrapper = styled.div`
  @media screen and (max-width: 1340px) {
    /* gap: 1rem; */
    .col-lg-4 {
      flex: 0 0 auto;
      min-width: 15rem;
    }
  }
`;
