import { baseApi } from './base-service';

export interface CommissionRequest {
  commission_for: string;
  commission_mode: string;
  price_range?: boolean;
  percentage?: number;
  amount?: number;
  minimum?: number;
  maximum?: number;
}

const commissionServices = baseApi.injectEndpoints({
  endpoints: builder => ({
    addCommission: builder.mutation<any, CommissionRequest>({
      query: body => ({
        url: `/api/v1/admin/commission/`,
        method: 'POST',
        body,
      }),
      // transformResponse: (response: any) => response.data,
    }),
    getCommissions: builder.query<CommissionRequest, void>({
      query: () => ({
        url: `/api/v1/admin/commission/`,
      }),
      transformResponse: (response: any) => response.data,
    }),
    getCommission: builder.query<CommissionRequest, string>({
      query: id => ({
        url: `/api/v1/admin/commission/${id}`,
      }),
      // transformResponse: (response: any) => response.data,
    }),
    deleteCommission: builder.mutation<void, string>({
      query: id => ({
        url: `/api/v1/admin/commission/${id}`,
        method: 'DELETE',
      }),
      // transformResponse: (response: any) => response.data,
    }),
    updateCommission: builder.mutation<any, any>({
      query: body => ({
        url: `/api/v1/admin/commission/${body.id}`,
        method: 'PUT',
        body: body,
      }),
      // transformResponse: (response: any) => response.data,
    }),
  }),
});

export const {
  useAddCommissionMutation,
  useGetCommissionsQuery,
  useGetCommissionQuery,
  useUpdateCommissionMutation,
  useDeleteCommissionMutation,
} = commissionServices;
