import { Skeleton } from "antd";
import moment from 'moment';
import { useState } from "react";
import Moment from "react-moment";
import styled from "styled-components";

import Pagination, { CurrPageInfoTypes }  from "../../components/Pagination";
import NoData from "../../components/bits/NoData";
import { useActivityReportQuery } from "../../redux/services/dashboard-services";

// type ActivityReportTypes = {
//   email: string;
//   actions: string;
//   date: string;
//   time: string;
// };

// const data: ActivityReportTypes[] = [
//   {
//     email: 'tolu@email.com',
//     actions: 'Account Login',
//     date: 'Jan 12, 2022',
//     time: '22:03',
//   },
//   {
//     email: 'tolu@email.com',
//     actions: 'Account Update',
//     date: 'Jan 12, 2022',
//     time: '22:03',
//   },
// ];

const ActivityReport = () => {
  const [currPageInfo, setCurrPageInfo] = useState<CurrPageInfoTypes>({
    pageNo: 1,
    pageSize: 10,
    pageTotalCount: 10,
    pageCount: 1,
    startDate: null,
    endDate: null,
  });
  const { data, isLoading, isSuccess,refetch } = useActivityReportQuery({       pageNo: currPageInfo.pageNo,
    pageSize: currPageInfo.pageSize,
});
  return (
    <Wrapper>
      <h2 style={{ fontWeight: 600, marginBottom: "1rem" }}>Activity Report</h2>
      <div className="trans-table">
        {isLoading ? (
          <Skeleton active />
        ) : data?.data?.data?.length > 0 ? (
          <>
            <table className="table-borderless">
              <thead className="header">
                <tr>
                  <th>Email</th>
                  <th>Action(s) Performed</th>
                  <th>Date</th>
                  <th>Time</th>
                </tr>
              </thead>
              <tbody>
                {data.data?.data?.map((item: any, index: number) => {
                  return (
                    <tr key={index}>
                      <td>{item.email}</td>
                      <td>{item.action}</td>
                      <td>
                        <Moment format="MMM DD, YYYY">{item.created_at}</Moment>
                      </td>
                      {/* <td>{item.time}</td> */}
                      <td>
                        <Moment format="HH:mm:ss a">{item.created_at}</Moment>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </>
        ) : (
          <div className="mt-4 pt-4">
            <NoData text="You have no recent activities." />
          </div>
        )}
      </div>
      {isSuccess && (
        <Pagination
          transPerPage={currPageInfo.pageSize}
          totalTrans={data.data?.data?.length}
          currentPage={currPageInfo.pageNo}
          lastPage={data.data?.data?.lastPage}
          setCurrPageInfo={setCurrPageInfo}
          // pageLimit={parseInt(currPageInfo.pageSize)}
          currPageInfo={currPageInfo}
          refetch={refetch}
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .trans-table {
    display: flex;
    flex-direction: column;
    background: #ffffff;
    box-shadow: 0px 16px 37px rgba(188, 188, 188, 0.1);
    border-radius: 15px;
    padding: 2rem 1rem !important;
    font-size: 16px;
    overflow-x: auto;

    table {
      th,
      td {
        padding: 0.5rem;
      }
      td {
        color: #707070;
      }
    }
    .header {
      color: #333333;
      border-top: 1px solid #999999;
      border-bottom: 1px solid #999999;
    }
    .status {
      // width: 20%;
    }

    @media screen and (min-width: 500px) {
      .gd {
        margin: 0rem 2rem 0rem 0rem;
        padding: 0px;
      }
    }

    @media screen and (max-width: 500px) {
      .trans-table {
        overflow-x: auto;
        // width: fit-content;
        table {
          th,
          td {
            // font-size: 12px !important;
          }
        }
      }
    }
  }
`;

export default ActivityReport;
