import { Skeleton } from 'antd';
import React, { Fragment, useEffect, useRef } from 'react';
import styled from 'styled-components';
// import { useReactToPrint } from 'react-to-print';

import DownloadCard from '../../components/DownloadCard';
import { format4dpNumber } from '../../helpers/formatNumbers';
import { useGetAccountBreakdownQuery } from '../../redux/services/dashboard-services';

const AccountBreakdown = () => {
  const { data = [], isLoading, refetch }: any = useGetAccountBreakdownQuery();

  useEffect(() => {
    refetch();
  }, [refetch]);

  const componentRef = useRef<HTMLDivElement | null>(null);

  return (
    <Wrapper ref={componentRef}>
      <h2 style={{ fontWeight: 600, marginBottom: '1rem' }}>
        Account Breakdown
      </h2>
      <div className='trans-table'>
        <div className='download-card'>
          <DownloadCard
            csv={[data?.data]}
            excel={[data?.data]}
            pdf={true}
            print={true}
            componentRef={componentRef}
            documentTitle='Account Breakdown'
          />
        </div>

        <table className='table-borderless align-middle'>
          <thead className='header'>
            <tr>
              <th>Type</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr className='loading text-center'>
                <td>
                  <Skeleton />
                </td>
              </tr>
            ) : (
              <>
                <tr>
                  <td>Central Vault</td>
                  <td>{format4dpNumber(data.data?.central_valut)}</td>
                </tr>
                <tr>
                  <td>Total Real Contributions</td>
                  <td>{format4dpNumber(data.data?.actual_amount)}</td>
                </tr>
                <tr>
                  <td>Virtual Community Contribution</td>
                  <td>
                    {format4dpNumber(data.data?.virtual_community_contribution)}
                  </td>
                </tr>
                <tr>
                  <td>Locked Accounts</td>
                  <td>{format4dpNumber(data.data?.locked_accounts)}</td>
                </tr>
                <tr>
                  <td>Individual Savings</td>
                  <td>{format4dpNumber(data.data?.individual_savings)}</td>
                </tr>
                <tr>
                  <td>Target Group Savings</td>
                  <td>{format4dpNumber(data.data?.coop_savings)}</td>
                </tr>
                <tr>
                  <td>Cooperative Group Savings</td>
                  <td>{format4dpNumber(data.data?.target_group_savings)}</td>
                </tr>
              </>
            )}
          </tbody>
        </table>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 1rem;

  .trans-table {
    display: flex;
    flex-direction: column;
    background: #ffffff;
    box-shadow: 0px 16px 37px rgba(188, 188, 188, 0.1);
    border-radius: 15px;
    padding: 2rem 1rem !important;
    font-size: 16px;
    overflow-x: auto;

    .loading {
      text-align: right;
      width: 100%;
    }
    table {
      th,
      td {
        padding: 0.5rem;
        width: 50%;
      }
      td {
        color: #707070;
      }

      th:nth-of-type(2),
      td:nth-of-type(2) {
        border-left: 1px solid #333333;
        padding-left: 1rem;
        margin-left: 0.5em;
      }
    }
    .header {
      color: #333333;
      border-top: 1px solid #999999;
      border-bottom: 1px solid #999999;
    }
    .status {
      // width: 20%;
    }

    @media screen and (min-width: 500px) {
      .gd {
        margin: 0rem 2rem 0rem 0rem;
        padding: 0px;
      }
    }

    @media screen and (max-width: 500px) {
      .trans-table {
        overflow-x: auto;
        // width: fit-content;
        table {
          th,
          td {
            // font-size: 12px !important;
          }
        }
      }
    }
  }
  @media print {
    .download-card {
      display: none;
    }
    .trans-table {
      box-shadow: none;
    }
  }
`;

export default AccountBreakdown;
