import React from 'react';
import styled from 'styled-components';
import { format4dpNumber } from '../helpers/formatNumbers';

interface DataType {
  type: string;
  planName: string;
  targetAmt: number;
  amtSaved: number;
  frequency: string;
  payoutDate: string;
  startDate: string;
  lastSavedAmt: number;
  lastSavedDate: string;
  status: string;
}

const data: DataType[] = [
  {
    type: 'Individual',
    planName: 'Group Savings ',
    targetAmt: 300000,
    amtSaved: 10000,
    frequency: 'weekly',
    payoutDate: '02/05/2022',
    startDate: '02/04/2022',
    lastSavedAmt: 3000,
    lastSavedDate: '04/04/2022',
    status: 'unlocked',
  },
  {
    type: 'Individual',
    planName: 'Individual',
    targetAmt: 300000,
    amtSaved: 10000,
    frequency: 'weekly',
    payoutDate: '02/05/2022',
    startDate: '02/04/2022',
    lastSavedAmt: 3000,
    lastSavedDate: '04/04/2022',
    status: 'locked',
  },
  {
    type: 'Individual',
    planName: 'Individual',
    targetAmt: 300000,
    amtSaved: 10000,
    frequency: 'weekly',
    payoutDate: '02/05/2022',
    startDate: '02/04/2022',
    lastSavedAmt: 3000,
    lastSavedDate: '04/04/2022',
    status: 'locked',
  },
];

const TargetSavings = ({ target }: any) => {
  return (
    <Wrapper>
      <h4 className='heading'>Target Contribution Details</h4>
      <table className='table-f'>
        <thead className='header'>
          <tr>
            {/* <th>Saving Type</th> */}
            <th>Plan Name</th>
            <th>Target Amount</th>
            <th>Amount Saved</th>
            <th>Frequency</th>
            <th>Start Date</th>
            <th>Payout Date</th>
            <th>Amount Last Saved</th>
            {/* <th>Last Saving Date </th> */}
            <th className='text-center'>Status </th>
          </tr>
        </thead>
        <tbody>
          {target.map((item: any, index: any) => (
            <tr key={index}>
              {/* <td>{item.type}</td> */}
              <td>{item.plan_name}</td>
              <td className='text-center'>
                {format4dpNumber(item.target_amount)}
              </td>
              <td className='text-center'>
                {format4dpNumber(item.amount_saved)}
              </td>
              <td>{item.plan}</td>
              <td>{item.start_date}</td>
              <td>{item.end_date}</td>
              <td className='text-center'>
                {format4dpNumber(item.amount_last_saved)}
              </td>
              {/* <td>{item.end_date}</td> */}
              <td
                className={`btn ${
                  item.group_status === 'matured' ||
                  item.group_status === 'active'
                    ? 'success'
                    : item.group_status === 'paused'
                    ? 'paused'
                    : 'failed'
                }`}
              >
                {item.group_status}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Wrapper>
  );
};

export default TargetSavings;

const Wrapper = styled.div`
  padding: 1rem 0;
  .heading {
    font-size: 1.6rem;
    font-weight: 600;
    color: #585757;
  }

  .btn {
    /* width: 7rem; */
    margin: 0.2rem;
    display: flex;
    align-content: center;
    justify-content: center;
    &.failed {
      color: #e2000fe2;
      background: #e2000f14;
      border-radius: 5px;
      padding: 0.5rem;
    }
    &.paused {
      color: #f3e300;
      background: rgba(238, 255, 88, 0.247);
      border-radius: 5px;
      padding: 0.5rem;
    }
    &.success {
      color: #059157;
      background: rgba(207, 232, 222, 0.5);
      border-radius: 5px;
      padding: 0.5rem;
    }
  }

  .table-f {
    width: 100%;
    /* box-shadow: 0px 0px 4px rgba(188, 188, 188, 0.1); */
    border-radius: 15px;
    text-transform: capitalize;
    padding: 1rem;

    .header {
      color: #707070;
      font-size: 1.1rem;
      border-bottom: 1px solid #707070;
      border-top: 1px solid #707070;
    }
    th {
      padding: 0.5rem;
    }
    td {
      padding: 0.5rem;
      color: #707070;
      font-size: 0.95rem;
      font-weight: 600;
    }
  }
`;
