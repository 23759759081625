import styled from 'styled-components';

import { format4dpNumber, formatNumber } from '../helpers/formatNumbers';

type DCardProps = {
  title: string;
  amount?: string | number;
  count?: string;
  bColor?: string;
};

const DCard = ({ title, amount, count, bColor }: DCardProps) => {
  return (
    <Wrapper color={bColor}>
      <p>{title}</p>
      {amount && (
        <h4 style={{ color: bColor ? '#ffffff' : bColor }}>
          ₦ {format4dpNumber(amount)}
        </h4>
      )}
      {count && (
        <h4 style={{ color: bColor ? '#ffffff' : bColor }}>
          {formatNumber(count)}
        </h4>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  background: ${({ color }: any) => (color ? color : '#ffffff')};
  color: ${({ color }: any) => (color ? 'white' : '##33277B')};
  box-shadow: 0px 16px 37px rgba(188, 188, 188, 0.1);
  border-radius: 15px;
  p {
    margin-bottom: 1.5rem;
  }
`;

export default DCard;
