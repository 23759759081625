import { Skeleton } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import Moment from 'react-moment';
import styled from 'styled-components';

import DLCard from '../../components/DLCard';
import FilterCard from '../../components/FilterCard';
import Pagination, { CurrPageInfoTypes } from '../../components/Pagination';
import SearchBar from '../../components/SearchBar';
import NoData from '../../components/bits/NoData';
import { format4dpNumber } from '../../helpers/formatNumbers';
import { useGetIndContributionQuery } from '../../redux/services/dashboard-services';

// type IndSavingsTypes = {
//   name: string;
//   email: string;
//   planName: string;
//   targetAmt: number;
//   startDate: string;
//   lastSavedAmt: number;
//   frequency: string;
//   balance: number;
//   status: string;
// };

/* const data: IndSavingsTypes[] = [
  {
    name: 'Folashade',
    email: 'fola@gmail.com',
    planName: 'Shopping for V.S.',
    targetAmt: 300000,
    startDate: '02/04/2022',
    lastSavedAmt: 3000,
    frequency: 'weekly',
    balance: 54000,
    status: 'locked',
  },
  {
    name: 'Zion',
    email: 'zion@gmail.com',
    planName: 'Birthday Bash',
    targetAmt: 18500,
    startDate: '02/04/2022',
    lastSavedAmt: 1000,
    frequency: 'weekly',
    balance: 4000,
    status: 'unlocked',
  },
]; */

const IndContributions = () => {
  const [currPageInfo, setCurrPageInfo] = useState<CurrPageInfoTypes>({
    pageNo: 1,
    pageSize: 10,
    pageTotalCount: 10,
    pageCount: 1,
    status: '',
    searchTerm: '',
    startDate: null,
    endDate: null,
    startDateString: '',
    endDateString: '',
  });
  const { data, isLoading, refetch, isSuccess }: any =
    useGetIndContributionQuery({
      pageNo: currPageInfo.pageNo,
      pageSize: currPageInfo.pageSize,
      status: currPageInfo.status,
      searchTerm: currPageInfo.searchTerm,
      duration:
        currPageInfo.startDate && currPageInfo.endDate
          ? `${moment(currPageInfo.startDate).format('YYYY-MM-DD')},${moment(
              currPageInfo.endDate
            ).format('YYYY-MM-DD')}`
          : '',
    });

  useEffect(() => {
    refetch();
  }, [refetch]);

  return (
    <Wrapper>
      {isSuccess && (
        <>
          <div className='top-header'>
            <h2 style={{ fontWeight: 600, marginBottom: '1rem' }}>
              Individual Contributions
            </h2>
            <div className='search'>
              <SearchBar
                width={'95%'}
                setCurrPageInfo={setCurrPageInfo}
                refetch={refetch}
                value={currPageInfo.searchTerm}
              />
            </div>
          </div>

          <div className='row'>
            <div className='col-md-6 col-lg-4 col-xl-3 p-3'>
              <DLCard
                title='Total Individual Contributions'
                amount={data?.data?.total_individual_savings}
                bColor={'#33277B'}
                image='wallet-white'
                minHeight={'100px'}
              />
            </div>
            <div className='col-md-6 col-lg-4 col-xl-3 p-3'>
              <DLCard
                title='Total Users'
                count={data?.data?.total_users}
                image='group-purple'
                minHeight={'100px'}
              />
            </div>
          </div>
          <FilterCard
            startDate={true}
            endDate={true}
            statusOptions={[
              { name: 'Status', value: '' },
              { name: 'Locked', value: 'active' },
              { name: 'Unlocked ', value: 'matured' },
            ]}
            setCurrPageInfo={setCurrPageInfo}
            refetch={refetch}
          />
        </>
      )}
      <div className='trans-table'>
        {isLoading ? (
          <Skeleton active />
        ) : data.data.individual_savings?.data.length > 0 ? (
          <>
            <table className='table-borderless'>
              <thead className='header'>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Plan Name</th>
                  <th>Target Amount</th>
                  <th>Start Date</th>
                  <th className='text-center'>Amount Last Saved</th>
                  <th>Frequency</th>
                  <th>Balance</th>
                  <th>Locked Status</th>
                </tr>
              </thead>
              <tbody>
                {data.data.individual_savings?.data?.map(
                  (item: any, index: number) => {
                    return (
                      <tr key={index}>
                        <td>{item?.owner_name}</td>
                        <td
                          className='break-all'
                          style={{
                            textTransform: 'lowercase',
                          }}
                        >
                          {item?.owner_email}
                        </td>
                        <td>{item.name}</td>
                        <td>{format4dpNumber(item.target_amount)}</td>
                        <td>
                          <Moment format='DD/MM/YYYY'>{item.created_at}</Moment>
                        </td>
                        <td className='text-center'>
                          {format4dpNumber(item.amount)}
                        </td>
                        <td>{item.plan}</td>
                        <td>{format4dpNumber(item.balance)}</td>
                        <td
                          className={`btn ${
                            item.status === 'active' ? 'locked' : 'unlocked'
                          }`}
                        >
                          {item.status === 'active' ? 'Locked' : 'Unlocked'}
                        </td>
                      </tr>
                    );
                  }
                )}
              </tbody>
            </table>
          </>
        ) : (
          <div className='mt-4 pt-4'>
            <NoData text='There are currently no blacklisted accounts.' />
          </div>
        )}
      </div>
      {isSuccess && (
        <Pagination
          transPerPage={data.data?.individual_savings?.per_page}
          totalTrans={data.data?.individual_savings?.total}
          currentPage={data.data?.individual_savings?.current_page}
          lastPage={data.data?.individual_savings?.last_page}
          setCurrPageInfo={setCurrPageInfo}
          // pageLimit={parseInt(data.data?.individual_savings?.per_page)}
          currPageInfo={currPageInfo}
          refetch={refetch}
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .top-header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    .search {
      margin-left: auto;
      min-width: 500px;
    }
  }

  .btn {
    /* width: 7rem; */
    margin: 0.2rem 0;
    display: flex;
    align-content: center;
    justify-content: center;

    &.locked {
      color: #f2990a;
      background: #fdf0da;
      border-radius: 5px;
      padding: 0.5rem;
    }
    &.unlocked {
      color: #059157;
      background: rgba(162, 209, 190, 0.425);
      border-radius: 5px;
      padding: 0.5rem;
    }
  }
  .trans-table {
    display: flex;
    flex-direction: column;
    background: #ffffff;
    box-shadow: 0px 16px 37px rgba(188, 188, 188, 0.1);
    border-radius: 15px;
    padding: 2rem 1rem !important;
    font-size: 16px;
    overflow-x: auto;
    margin-top: 1rem;

    .break-all {
      width: 12rem;
      word-break: break-all;
    }

    table {
      th,
      td {
        padding: 0.5rem;
      }
      td {
        color: #707070;
        text-transform: capitalize;
        font-size: 0.9rem;
      }
    }
    .header {
      color: #333333;
      border-top: 1px solid #999999;
      border-bottom: 1px solid #999999;
    }
    .status {
      // width: 20%;
      text-transform: capitalize;
    }

    @media screen and (min-width: 500px) {
      .gd {
        margin: 0rem 2rem 0rem 0rem;
        padding: 0px;
      }
    }

    @media screen and (max-width: 500px) {
      .trans-table {
        overflow-x: auto;
        // width: fit-content;
        table {
          th,
          td {
            // font-size: 12px !important;
          }
        }
      }
    }
  }
`;

export default IndContributions;
