import { Skeleton } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import Moment from 'react-moment';
import styled from 'styled-components';

// import { ReactComponent as ActiveIcon } from '../../assets/icons/c-active.svg';
// import { ReactComponent as InactiveIcon } from '../../assets/icons/c-inactive.svg';
import DLCard from '../../components/DLCard';
import FilterCard from '../../components/FilterCard';
import Pagination, { CurrPageInfoTypes } from '../../components/Pagination';
import SearchBar from '../../components/SearchBar';
import NoData from '../../components/bits/NoData';
import { format4dpNumber } from '../../helpers/formatNumbers';
import { useGetTargetContributionQuery } from '../../redux/services/dashboard-services';

// type TarGrpContributionsTypes = {
//   grpName: string;
//   adminEmail: string;
//   memberNo: number;
//   targetAmt: number;
//   startDate: string;
//   maturityDate: string;
//   frequency: string;
//   grpStatus: 'active' | 'paused';
//   lockedStatus: 'locked' | 'unlocked';
// };

/* const data: TarGrpContributionsTypes[] = [
  {
    grpName: 'Home bound',
    adminEmail: 'fola@gmail.com',
    memberNo: 5,
    targetAmt: 455000,
    startDate: '02/04/2022',
    maturityDate: '10/04/2022',
    frequency: 'weekly',
    grpStatus: 'active',
    lockedStatus: 'unlocked',
  },
  {
    grpName: 'Trip to Mars',
    adminEmail: 'fola@gmail.com',
    memberNo: 4,
    targetAmt: 3000000,
    startDate: '02/04/2022',
    maturityDate: '10/04/2022',
    frequency: 'weekly',
    grpStatus: 'paused',
    lockedStatus: 'locked',
  },
]; */

const TarGrpContributions = () => {
  const [currPageInfo, setCurrPageInfo] = useState<CurrPageInfoTypes>({
    pageNo: 1,
    pageSize: 10,
    pageTotalCount: 10,
    pageCount: 1,
    status: '',
    searchTerm: '',
    startDate: null,
    endDate: null,
    startDateString: '',
    endDateString: '',
  });

  const { data, isLoading, refetch, isSuccess }: any =
    useGetTargetContributionQuery({
      pageNo: currPageInfo.pageNo,
      pageSize: currPageInfo.pageSize,
      status: currPageInfo.status,
      searchTerm: currPageInfo.searchTerm,
      duration:
        currPageInfo.startDate && currPageInfo.endDate
          ? `${moment(currPageInfo.startDate).format('YYYY-MM-DD')},${moment(
              currPageInfo.endDate
            ).format('YYYY-MM-DD')}`
          : '',
    });

  useEffect(() => {
    refetch();
  }, [refetch]);

  return (
    <Wrapper>
      {isSuccess && (
        <>
          <div className='top-header'>
            <h2 style={{ fontWeight: 600, marginBottom: '1rem' }}>
              Target Group Contributions
            </h2>
            <div className='search'>
              <SearchBar
                width={'90%'}
                setCurrPageInfo={setCurrPageInfo}
                refetch={refetch}
                value={currPageInfo.searchTerm}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-md-6 col-lg-4 col-xl-3 p-3'>
              <DLCard
                title='Total Target Group Contributions'
                amount={data.data?.total_target_group_contribution}
                bColor={'#33277B'}
                image='wallet-white'
                minHeight={'100px'}
              />
            </div>
            <div className='col-md-6 col-lg-4 col-xl-3 p-3'>
              <DLCard
                title='Total Users'
                count={`${data.data?.total_users}`}
                image='group-purple'
                minHeight={'100px'}
              />
            </div>
            <div className='col-md-6 col-lg-4 col-xl-3 p-3'>
              <DLCard
                title='Total Groups'
                count={data.data?.total_group}
                image='group-purple'
                minHeight={'100px'}
              />
            </div>
          </div>
          <FilterCard
            startDate={true}
            endDate={true}
            statusOptions={[
              { name: 'Status', value: '' },
              { name: 'Matured', value: 'matured' },
              { name: 'Not Matured', value: 'not-matured' },
            ]}
            setCurrPageInfo={setCurrPageInfo}
            refetch={refetch}
          />
        </>
      )}
      <div className='trans-table'>
        {isLoading ? (
          <Skeleton active />
        ) : data.data.target_group_savings?.data.length > 0 ? (
          <>
            <table className='table-borderless'>
              <thead className='header'>
                <tr>
                  <th>Owner Name</th>
                  <th>Admin's Email</th>
                  <th>Group Name</th>
                  <th className='text-center'>No of Members</th>
                  <th>Target Amount</th>
                  <th>Start Date</th>
                  <th>Maturity Date</th>
                  {/* <th>Frequency</th> */}
                  <th className='text-center'>Group Status</th>
                </tr>
              </thead>
              <tbody>
                {data.data.target_group_savings?.data?.map(
                  (item: any, index: number) => {
                    return (
                      <tr key={index}>
                        <td className='status'>{item.owner_name}</td>
                        <td
                          style={{ textTransform: 'lowercase' }}
                          className='break-all'
                        >
                          {item.owner_email}
                        </td>
                        <td>{item.name}</td>
                        <td className='text-center'>
                          {item.no_of_participants}
                        </td>
                        <td>{format4dpNumber(item.target_amount)}</td>
                        <td>
                          <Moment format='DD/MM/YYYY'>{item.start_date}</Moment>
                        </td>
                        <td>
                          <Moment format='DD/MM/YYYY'>{item.end_date}</Moment>
                        </td>
                        {/* <td>{item.frequency}</td> */}
                        {/* <td className='text-center'>
                          {item.status === 'active' ? (
                            <ActiveIcon />
                          ) : (
                            <InactiveIcon />
                          )}
                        </td> */}
                        <td
                          className={`text-center btn ${
                            item.status === 'matured' ? 'unlocked' : 'locked'
                          }`}
                        >
                          {item.status === 'matured' ? 'Unlocked' : 'Locked'}
                        </td>
                      </tr>
                    );
                  }
                )}
              </tbody>
            </table>
          </>
        ) : (
          <div className='mt-4 pt-4'>
            <NoData text='There are currently no blacklisted accounts.' />
          </div>
        )}
      </div>
      {isSuccess && (
        <Pagination
          transPerPage={data.data?.target_group_savings?.per_page}
          totalTrans={data.data?.target_group_savings?.total}
          currentPage={data.data?.target_group_savings?.current_page}
          lastPage={data.data?.target_group_savings?.last_page}
          setCurrPageInfo={setCurrPageInfo}
          // pageLimit={parseInt(data.data?.target_group_savings?.per_page)}
          currPageInfo={currPageInfo}
          refetch={refetch}
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .top-header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    .search {
      margin-left: auto;
      min-width: 500px;
    }

    @media screen and (max-width: 1340px) {
      .search {
        margin-left: auto;
        min-width: 400px;
      }
    }
  }

  .btn {
    /* width: 7rem; */
    margin: 0.2rem;
    display: flex;
    align-content: center;
    justify-content: center;

    &.locked {
      color: #f2990a;
      background: #fdf0da;
      border-radius: 5px;
      padding: 0.5rem;
    }
    &.unlocked {
      color: #059157;
      background: rgba(207, 232, 222, 0.5);
      border-radius: 5px;
      padding: 0.5rem;
    }
  }

  @media screen and (max-width: 1340px) {
    .break-all {
      width: 500px;
      word-break: break-all;
    }
  }

  .trans-table {
    display: flex;
    flex-direction: column;
    background: #ffffff;
    box-shadow: 0px 16px 37px rgba(188, 188, 188, 0.1);
    border-radius: 15px;
    padding: 2rem 1rem !important;
    font-size: 16px;
    overflow-x: auto;
    margin-top: 0.5rem;

    table {
      th,
      td {
        padding: 0.5rem;
      }
      td {
        color: #707070;
        text-transform: capitalize;
        font-size: 0.95rem;
      }
    }
    .header {
      color: #333333;
      border-top: 1px solid #999999;
      border-bottom: 1px solid #999999;
    }
    .status {
      // width: 20%;
      text-transform: capitalize;
    }

    @media screen and (min-width: 500px) {
      .gd {
        margin: 0rem 2rem 0rem 0rem;
        padding: 0px;
      }
    }

    @media screen and (max-width: 500px) {
      .trans-table {
        overflow-x: auto;
        // width: fit-content;
        table {
          th,
          td {
            // font-size: 12px !important;
          }
        }
      }
    }
  }
`;

export default TarGrpContributions;
