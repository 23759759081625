// import React from 'react';
import styled from 'styled-components';

import { ReactComponent as NotificationIcon } from '../assets/icons/Notification.svg';
import profile_img from '../assets/img/profile_img.png';
import { userData } from '../helpers/authHelper';

// import SearchBar from './SearchBar';

interface TopNavInfo {
  name?: string;
  role?: string;
}

const TopNavbar = ({ name, role }: TopNavInfo) => {
  // Just for completion
  /*   const [currPageInfo, setCurrPageInfo] = useState({
    searchTerm: '',
  }); */

  return (
    <Wrapper>
      <div className='left-container'>
        {/* <SearchBar
          width='70%'
          value={currPageInfo.searchTerm}
          setCurrPageInfo={setCurrPageInfo}
        /> */}
      </div>
      <div className='right-container'>
        <div className='notif'>
          <NotificationIcon />
          <div className='notif-counter'></div>
        </div>
        <div className='img-container'>
          <img src={profile_img} alt='' className='img' />
        </div>
        <div className='info'>
          <span className='info-name'>
            {userData().last_name ?? 'Temitope'}
          </span>
          <span className='role '>{userData().name}</span>
        </div>
      </div>
    </Wrapper>
  );
};

export default TopNavbar;

const Wrapper = styled.div`
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 1rem 3rem 1rem 1rem;
  position: sticky;
  top: 0;
  left: 0;
  /* bottom: 0;
  right: 0; */
  z-index: 10;
  box-shadow: 0px 16px 37px rgba(188, 188, 188, 0.1);

  .left-container {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .right-container {
    box-sizing: content-box;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;

    .notif {
      position: relative;
      top: 0;
      left: 0;
      .notif-counter {
        position: absolute;
        top: -2px;
        right: 0;
        background: #d50000;
        width: 10px;
        height: 10px;
        border-radius: 50%;
      }
    }
    .img-container {
      width: auto;
      .img {
        object-fit: cover;
      }
    }

    .info {
      display: flex;
      align-items: start;
      flex-direction: column;
      justify-content: end;
      .info-name {
        font-size: 1.1rem;
        font-weight: bold;
        line-height: 1;
      }
      .role {
        font-size: 0.9rem;
        text-transform: lowercase;
        font-weight: 700;
        &::first-letter {
          text-transform: uppercase;
        }
      }
    }
  }
`;
