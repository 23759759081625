import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { useReactToPrint } from 'react-to-print';
import styled from 'styled-components';
import * as XLSX from 'xlsx';

interface Download {
  color?: string;
  csv?: any;
  excel?: any;
  pdf?: boolean;
  componentRef?: any;
  print?: boolean;
  documentTitle?: string;
}

const DownloadCard = ({
  csv,
  excel,
  pdf,
  componentRef,
  print,
  documentTitle,
}: Download) => {
  const handlePrint = useReactToPrint({
    content: () => componentRef?.current,
    documentTitle,
  });

  /*  const handlePdfDownload = useReactToPrint({
    onPrintError: error => console.log(error),
    content: () => componentRef.current,
    removeAfterPrint: true,
    print: async printIframe => {
      const document = printIframe.contentDocument;
      if (document) {
        const exporter = new Html2Pdf(pageId, {
          filename: documentTitle,
          enableLinks: true,
        });
        await exporter.getPdf(true);
      }
    },
  }); */

  const handlePdfDownload = () => {
    html2canvas(componentRef.current).then(canvas => {
      const imageData = canvas.toDataURL('image/png');
      const pdf = new jsPDF({
        orientation: 'landscape',
        unit: 'pt',
        format: 'a4',
      });
      pdf.addImage({
        imageData,
        x: 10,
        y: 0,
        width: 0,
        height: 0,
      });
      pdf.save(documentTitle);
    });
  };

  const handleExcel = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(excel);
    XLSX.utils.book_append_sheet(wb, ws, documentTitle);
    XLSX.writeFile(wb, `${documentTitle!}.xlsx`);
    console.log(excel);
  };

  const handleCSV = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(excel);
    XLSX.utils.book_append_sheet(wb, ws, documentTitle);
    XLSX.writeFile(wb, `${documentTitle!}.csv`);
    console.log(excel);
  };

  return (
    <Wrapper data-html2canvas-ignore='true'>
      {csv && (
        <button className='btn csv' onClick={handleCSV}>
          CSV
        </button>
      )}
      {excel && (
        <button className='btn excel' onClick={handleExcel}>
          Excel
        </button>
      )}
      {pdf && (
        <button className='btn pdf' onClick={handlePdfDownload}>
          PDF
        </button>
      )}
      {print && (
        <button className='btn print' onClick={handlePrint}>
          Print
        </button>
      )}
    </Wrapper>
  );
};

export default DownloadCard;

const Wrapper = styled.div<Download>`
  padding-bottom: 1rem;
  display: flex;
  gap: 0.4rem;

  .btn {
    border: 0;
    outline: 0;
    color: #fff;
    border-radius: 4px;
    font-size: 0.8rem;
    font-weight: 500;
  }

  .csv {
    background: #7939cb;
  }

  .excel {
    background: #059157;
  }
  .pdf {
    background: #f12f2f;
  }
  .print {
    background: #ffa412;
  }
`;
