import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import React, { useRef } from "react";
import { Chart } from "react-chartjs-2";
import styled from "styled-components";
// eslint-disable-next-line import/no-unassigned-import
import "chart.js/auto";

// import { useGetTarGroupJoinedQuery } from "../../redux/services/saving-service";

ChartJS.register(ArcElement, Tooltip, Legend);

// ChartJS.register({
//   afterUpdate: function (chart) {
//     if (chart.config.options?.elements?.arc.)
//   }
// });

interface UsersDoughnutProps {
  complete_registration: number;
  incomplete_registration: number;
}

const UsersDoughnut = ({ usersAnalytics }: any) => {
  const { complete_registration, incomplete_registration }: UsersDoughnutProps =
    usersAnalytics;

  const chartRef = useRef<ChartJS>(null);
  // const { data, refetch }: any = useGetTarGroupJoinedQuery();

  // useEffect(() => {
  //   refetch();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // let finalAmt: number[] = [];
  // let planNames: string[] = [];
  // let planColour: string[] = [];

  const data1 = {
    labels: ["Completed Registration", "Incomplete Registration"],
    datasets: [
      {
        label: "Registrations",
        data: [complete_registration, incomplete_registration],
        backgroundColor: ["#059157", "#33277B4D"],
        hoverOffset: 6,
        borderJoinStyle: "miter",
      },
    ],
  };

  const options: any = {
    responsive: true,
    aspectRatio: 1.5,
    cutout: "75%",
    plugins: {
      legend: {
        position: "bottom" as const,
        labels: {
          color: "#33277B",
          font: {
            size: 14,
            weight: "600",
          },
          padding: 20,
        },
      },
      layout: {
        padding: 0,
      },
    },
  };

  return (
    <Wrapper>
      <div className="chart-h">
        <h4 className="m-0">Registrations</h4>
      </div>
      <Chart
        ref={chartRef}
        type="doughnut"
        data={data1}
        options={options}
        style={{ padding: "1rem 2rem" }}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #212121cc;
  background: #ffffff;
  box-shadow: 0px 16px 37px rgba(188, 188, 188, 0.1);
  border-radius: 15px;
  padding: 1rem 3rem;
  font-weight: 600;
  // height: 490px;

  h4 {
    font-weight: 600;
  }

  .chart-h {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2.5rem;
  }
`;

export default UsersDoughnut;
