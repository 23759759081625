import styled from 'styled-components';

interface HeadingType {
  color?: string;
  htmlFor?: string;
}

export const Heading1 = styled.h1<HeadingType>`
  color: ${({ color }: HeadingType) => color ?? 'rgba(51, 39, 123, 1)'};
  font-weight: bold;
  margin-bottom: 50px;
`;
export const Heading2 = styled.h2<HeadingType>`
  color: ${({ color }: HeadingType) => color ?? 'rgba(51, 39, 123, 1)'};
  font-weight: bold;
  margin-bottom: 50px;
`;
export const Heading3 = styled.h3<HeadingType>`
  color: ${({ color }: HeadingType) => color ?? 'rgba(51, 39, 123, 1)'};
  font-weight: bold;
`;
export const Heading4 = styled.h4<HeadingType>`
  color: ${({ color }: HeadingType) => color ?? 'rgba(51, 39, 123, 1)'};
  font-weight: bold;
  margin-bottom: 40px;
`;
export const Heading5 = styled.h5<HeadingType>`
  color: ${({ color }: HeadingType) => color ?? 'rgba(51, 39, 123, 1)'};
  font-weight: bold;
  margin-bottom: 40px;
`;
export const Paragraph = styled.p<HeadingType>`
  color: ${({ color }: HeadingType) => color ?? 'rgba(71, 72, 107, 1)'};
  margin-bottom: 20px;
  line-height: 28px;
  font-size: 18px;
`;
export const Label = styled.label<HeadingType>`
  color: ${({ color }: HeadingType) => color ?? 'rgba(71, 72, 107, 1)'};
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 600;
  cursor: ${({ htmlFor }: HeadingType) => htmlFor && 'pointer'};
`;
