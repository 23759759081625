import { GetDashBoardSummary } from '../redux/services/dashboard-services';
import DCard from './DCard';

const Users = ({ user }: any) => {
  const {
    total_users,
    today_users,
    yesterday_users,
    this_week_users,
    this_month_users,
  }: GetDashBoardSummary = user;

  return (
    <div className='row'>
      <div className='col-md-6 col-lg-4 col-xl-3 p-3'>
        <DCard
          title='Total Users'
          count={`${total_users}`}
          bColor={'#33277B'}
        />
      </div>
      <div className='col-md-6 col-lg-4 col-xl-3 p-3'>
        <DCard title='Total Users Today' count={`${today_users}`} />
      </div>
      <div className='col-md-6 col-lg-4 col-xl-3 p-3'>
        <DCard title='Total Users Yesterday' count={`${yesterday_users}`} />
      </div>
      <div className='col-md-6 col-lg-4 col-xl-3 p-3'>
        <DCard title='Total Users This Week' count={`${this_week_users}`} />
      </div>
      <div className='col-md-6 col-lg-4 col-xl-3 p-3'>
        <DCard title='Total Users Last Week' count={`${this_month_users}`} />
      </div>
      <div className='col-md-6 col-lg-4 col-xl-3 p-3'>
        <DCard title='Total Users This Month' count={`${this_month_users}`} />
      </div>
    </div>
  );
};

export default Users;
