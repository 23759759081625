import { Route, Routes } from 'react-router-dom';

import Dashboard from './components/Dashboard';
import EditCommission from './components/EditCommission';
import GroupSavingsView from './components/GroupSavingsView';
import Profile from './components/Profile';
import AccountBreakdown from './pages/authpages/AccountBreakdown';
import ActivityReport from './pages/authpages/ActivityReport';
import AllAccounts from './pages/authpages/AllAccounts';
import AllCards from './pages/authpages/AllCards';
import AllTransactions from './pages/authpages/AllTransactions';
import AuthIndex from './pages/authpages/AuthIndex';
import BlacklistedAccounts from './pages/authpages/BlacklistedAccts';
import Commission from './pages/authpages/Commission';
import CoopGrpContributions from './pages/authpages/CoopGrpContributions';
import IndContributions from './pages/authpages/IndContributions';
import Purse from './pages/authpages/Purse';
import Referrals from './pages/authpages/Referrals';
import TarGrpContributions from './pages/authpages/TarGrpContributions';
import UserMonthlyBreakdown from './pages/authpages/UserMonthlyBreakdown';
import Login from './pages/unAuthPages/Login';
import {
  acctBrkdwn,
  actRep,
  admin,
  allAccts,
  allCards,
  allTrans,
  blacklisted,
  commission,
  coopGrpCon,
  editCommission,
  groupSavingsView,
  indCont,
  profile,
  purse,
  referrals,
  tarGrpCon,
  userMnthBrkdwn,
} from './utils/routes';

function App() {
  return (
    <Routes>
      <Route index element={<Login />} />
      <Route path={admin} element={<AuthIndex />}>
        <Route index element={<Dashboard />} />
        <Route path={allAccts} element={<AllAccounts />} />
        <Route path={acctBrkdwn} element={<AccountBreakdown />} />
        <Route path={referrals} element={<Referrals />} />
        <Route path={userMnthBrkdwn} element={<UserMonthlyBreakdown />} />
        <Route path={allCards} element={<AllCards />} />
        <Route path={blacklisted} element={<BlacklistedAccounts />} />
        <Route path={purse} element={<Purse />} />
        <Route path={indCont} element={<IndContributions />} />
        <Route path={tarGrpCon} element={<TarGrpContributions />} />
        <Route path={coopGrpCon} element={<CoopGrpContributions />} />
        <Route path={allTrans} element={<AllTransactions />} />
        <Route path={actRep} element={<ActivityReport />} />
        <Route path={profile} element={<Profile />} />
        <Route path={groupSavingsView} element={<GroupSavingsView />} />
        <Route path={commission} element={<Commission />} />
        <Route path={editCommission} element={<EditCommission />} />
      </Route>
    </Routes>
  );
}

export default App;
