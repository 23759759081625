import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import React, { useRef, ChangeEvent } from 'react';
import { Chart } from 'react-chartjs-2';
import styled from 'styled-components';

// eslint-disable-next-line import/no-unassigned-import
import 'chart.js/auto';
import { GetOverview } from '../../redux/services/dashboard-services';

// import { useGetTarGroupJoinedQuery } from "../../redux/services/saving-service";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const UsersBarChart = ({ overview, setOverviewData, overviewData }: any) => {
  const chartRef = useRef<ChartJS>(null);
  const month = Object.keys(overview[0]);

  const labels = overview[0][`${month}`]
    .map((key: string) => Object.keys(key))
    .flat();

  const data = overview[0][`${month}`]
    .map((val: number) => Object.values(val))
    .flat();

  // const { data, refetch }: any = useGetTarGroupJoinedQuery();

  // useEffect(() => {
  //   refetch();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // let finalAmt: number[] = [];
  // let planNames: string[] = [];
  // let planColour: string[] = [];

  const data1 = {
    // labels: [5, 10, 15, 20, 25, 30],
    // labels: overview.map((d: any, index: number) => index),
    labels,
    datasets: [
      {
        // label: 'Days of the Month',
        // data: [80, 160, 200, 100, 150, 230],
        data,
        backgroundColor: [
          '#059157',
          '#33277B',
          '#059157',
          '#33277B',
          '#059157',
          '#33277B',
        ],
      },
    ],
  };

  const options = {
    responsive: true,
    aspectRatio: 1.5,
    plugins: {
      legend: {
        display: false,
        // position: "bottom" as const,
        // labels: {
        //   color: "#33277B",
        //   font: {
        //     size: 14,
        //     weight: "600",
        //   },
        // },
      },
      layout: {
        padding: 0,
      },
      //   title: {
      //     display: true,
      //     text: "Individual Services",
      //   },
    },
  };

  const onChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    setOverviewData((prevState: GetOverview) => {
      return {
        ...prevState,
        month: value,
      };
    });
  };

  return (
    <Wrapper>
      <div className='chart-h'>
        <h4 className='m-0'>Overview</h4>
        <select
          onChange={e => onChange && onChange(e)}
          name='name'
          placeholder='Month Select'
          className='selected'
        >
          <option value={overviewData.month} className='selected'>
            {month}
          </option>
          <option value={1}>January</option>
          <option value={2}>Febraury</option>
          <option value={3}>March</option>
          <option value={4}>April</option>
          <option value={5}>May</option>
          <option value={6}>June</option>
          <option value={7}>July</option>
          <option value={8}>August</option>
          <option value={9}>September</option>
          <option value={10}>October</option>
          <option value={11}>November</option>
          <option value={12}>December</option>
        </select>
      </div>
      <Chart ref={chartRef} type='bar' data={data1} options={options} />
      <div className='chart-f'>
        <p className='m-0'>Days of the Month</p>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #212121cc;
  background: #ffffff;
  box-shadow: 0px 16px 37px rgba(188, 188, 188, 0.1);
  border-radius: 15px;
  padding: 1rem 3rem;
  font-weight: 600;
  // height: 490px;

  h4 {
    font-weight: 600;
  }

  .chart-h {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;

    select {
      outline: none;
      /* appearance: none; */
      padding: 0.3rem 1rem;
      cursor: pointer;
      option {
        padding: 1.5rem;
        &.selected {
          font-weight: bold;
          background: #3b3b3b;
          color: #ffffff;
        }
      }
      &.selected {
        font-weight: bold;
      }
    }
  }
  .chart-f {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`;

export default UsersBarChart;
