import styled from 'styled-components';

interface InputProps {
  className?: string;
  onChange?: any;
  name?: string;
  value?: any;
  id?: string;
  label?: string;
  placeholder?: string;
  pattern?: any;

  width?: any;
  minLength?: number;
  maxLength?: number;
  // maxLength?: any;
  refs?: any;
  onKeyUp?: any;
  properties?: any;
  readOnly?: boolean;
  accept?: any;
  height?: string;
  checked?: string;
  autoFocus?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
}

const Checkbox = ({
  onChange,
  label,
  value,

  name,
  pattern,
  placeholder,
  disabled,
  refs,
  onKeyUp,
  className,
  properties,
  readOnly,
  accept,
  autoFocus,
  id,
  checked,
}: InputProps) => {
  return (
    <Checkbox.Wrapper className='form'>
      <div className='form'>
        <input
          className={`form__input ${className}`}
          type='checkbox'
          placeholder={placeholder}
          name={name}
          id={id}
          readOnly={readOnly}
          value={value}
          checked={checked}
          pattern={pattern}
          onChange={onChange}
          ref={refs}
          {...properties}
          onKeyUp={onKeyUp}
          accept={accept}
          autoFocus={autoFocus}
          disabled={disabled}
        />
        <label htmlFor={name} className={`form__label`}>
          {label}
        </label>
      </div>
    </Checkbox.Wrapper>
  );
};

Checkbox.Wrapper = styled.div<any>`
  /* margin-top: 2rem; */
  .filled {
    /* background-color: #e6ffea; */
    border: 0.5px solid #059157;
  }
  .form {
    position: relative;
    &__label {
      position: absolute;
      top: 0;
      left: 0.5rem;
      padding: 0 0.3rem;
      // font-size: 1.2rem;
      // line-height: 1.5rem;
      font-size: 16px;
      line-height: 17px;
      background-color: #e6ffea;
      display: block;
      transition: all 0.3s;
      opacity: 0;
      color: #059157;
    }
    &__input {
      // font-size: 1.3rem;
      font-size: 16px;
      line-height: 17px;
      //   font-family: Orkney;
      color: #7b7b7b;
      cursor: pointer;
      background: #f9fffb;
      padding: 1rem;
      padding-left: 1rem;
      // border-radius: 1rem;
      border-radius: 5px;
      border: 1px solid #bec6df;
      /* width: ${(props: InputProps) =>
        props.fullWidth ? '-moz-available' : '500.3px'}; */
      width: 30px;
      display: block;
      transition: all 0.3s;
      /* box-shadow: 0px 3px 6px #0000000d; */
      // height: 40px;
      /* height: ${(props: any) => (props.height ? props.height : '40px')}; */
      height: 30px;
      margin-bottom: 10px;
      &:focus {
        outline: none;
        border: 0.5px solid #bec6df;
        background-color: #ffffff;
      }

      &:focus:invalid {
        border: 1px solid red;
      }

      &::-webkit-input-placeholder {
        // font-family: Orkney;
        color: #47486b;
        opacity: 0.4;
      }
      &:disabled {
        background-color: #dadceb;
        color: #47486b;
        cursor: not-allowed;
      }
    }
    .shrink {
      top: -0.8rem;
      opacity: 1;
    }
  }
  // @media (max-width: 1280px) {
  //   .form &__input {
  //     width: 100%;
  //   }
  // }
  @media (max-width: 520px) {
    .form {
      position: relative;

      &__input {
        font-size: 16px;
        line-height: 17px;
        // font-family: Orkney;
        color: #7b7b7b;
        padding: 1rem;
        padding-left: 1rem;
        // border-radius: 1rem;
        border-radius: 10px;
        border: 1px solid #bec6df;
        width: 100%;
        display: block;
        transition: all 0.3s;
        box-shadow: 0px 3px 6px #0000000d;
        height: 50px;
        background: #f9fffb;
      }
    }
  }
`;
export default Checkbox;
