import { Skeleton } from "antd";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import moment from "moment";
import { ChangeEvent, useEffect, useState } from "react";
import Moment from "react-moment";
import styled from "styled-components";

import { ReactComponent as DownloadIcon } from "../../assets/icons/download.svg";
import creditImg from "../../assets/img/credit.png";
import debitImg from "../../assets/img/debit.png";
import DLCard from "../../components/DLCard";
import FilterCard from "../../components/FilterCard";
import Pagination, { CurrPageInfoTypes } from "../../components/Pagination";
import SearchBar from "../../components/SearchBar";
import NoData from "../../components/bits/NoData";
import Select from "../../components/bits/Select";
import { format4dpNumber } from "../../helpers/formatNumbers";
import { useGetAllTransactionsHistoryQuery } from "../../redux/services/dashboard-services";

/* type StashTypes = {
  name: string;
  email: string;
  reference: string;
  source: string;
  date: string;
  amount: number;
  type: 'credit' | 'debit';
  balance: number;
  status: string;
}; */

/* const data: StashTypes[] = [
  {
    name: 'Folashade',
    email: 'fola@gmail.com',
    reference: 'WT-20220429165120480381947',
    source: 'Individual Savings',
    date: 'Jan 12, 2022',
    amount: 2000,
    type: 'credit',
    balance: 22000,
    status: 'success',
  },
  {
    name: 'Folashade',
    email: 'fola@gmail.com',
    reference: 'WT-20220429165120480381947',
    source: 'Individual Savings',
    date: 'Jan 12, 2022',
    amount: 2000,
    type: 'debit',
    balance: 22000,
    status: 'success',
  },
  {
    name: 'Folashade',
    email: 'fola@gmail.com',
    reference: 'WT-20220429165120480381947',
    source: 'Individual Savings',
    date: 'Jan 12, 2022',
    amount: 2000,
    type: 'credit',
    balance: 22000,
    status: 'failed',
  },
]; */

const AllTransactions = () => {
  // const [show, setShow] = useState(false);
  const [currPageInfo, setCurrPageInfo] = useState<CurrPageInfoTypes>({
    pageNo: 1,
    pageSize: 10,
    pageTotalCount: 10,
    pageCount: 1,
    status: "",
    type: "",
    searchTerm: "",
    startDate: null,
    endDate: null,
    startDateString: "",
    endDateString: "",
  });

  // const { data, isLoading, refetch, isSuccess }: any =
  //   useGetAllTransactionsQuery();

  const { data, isLoading, refetch, isSuccess }: any =
    useGetAllTransactionsHistoryQuery({
      pageNo: currPageInfo.pageNo,
      pageSize: currPageInfo.pageSize,
      status: currPageInfo.status,
      searchTerm: currPageInfo.searchTerm,
      type: currPageInfo.type,
      duration:
        currPageInfo.startDate && currPageInfo.endDate
          ? `${moment(currPageInfo.startDate).format("YYYY-MM-DD")},${moment(
              currPageInfo.endDate
            ).format("YYYY-MM-DD")}`
          : "",
    });

  useEffect(() => {
    refetch();
  }, [refetch]);

  const handleStatusChange = (e: ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    setCurrPageInfo((currInfo: CurrPageInfoTypes) => {
      return {
        ...currInfo,
        status: val,
        pageNo: 1,
      };
    });
    refetch();
  };

  const handlePdfDownload = () => {
    const allTransId = document.getElementById("allTrans") as HTMLElement;
    html2canvas(allTransId).then((canvas) => {
      const imageData = canvas.toDataURL("image/jpg");
      const pdf = new jsPDF({
        orientation: "landscape",
        unit: "pt",
        format: "a4",
      });
      pdf.addImage({
        imageData,
        x: 10,
        y: 10,
        width: 0,
        height: 0,
      });
      pdf.save("All Transactions");
    });
  };

  return (
    <Wrapper>
      {isSuccess && (
        <>
          <div className="top-header">
            <h2 style={{ fontWeight: 600, marginBottom: "1rem" }}>
              Transactions
            </h2>
            <div className="search">
              <SearchBar
                width={"90%"}
                setCurrPageInfo={setCurrPageInfo}
                refetch={refetch}
                value={currPageInfo.searchTerm}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-lg-4 col-xl-3 p-3">
              <DLCard
                title="Total Transactions Count"
                amount={data.data?.total_transaction}
                bColor={"#33277B"}
                image="share-icon-white"
                minHeight={"100px"}
              />
            </div>
            <div className="col-md-6 col-lg-4 col-xl-3 p-3">
              <DLCard
                title="Today's Count"
                count={`${data.data?.today_transaction}`}
                image="share-icon"
                minHeight={"100px"}
              />
            </div>
            <div className="col-md-6 col-lg-4 col-xl-3 p-3">
              <DLCard
                title="Yesterday's Count"
                count={`${data.data?.yesterday_transaction}`}
                image="share-icon"
                minHeight={"100px"}
              />
            </div>
            <div className="col-md-6 col-lg-4 col-xl-3 p-3">
              <DLCard
                title="Last Week's Count"
                count={`${data.data?.this_week_transaction}`}
                image="share-icon"
                minHeight={"100px"}
              />
            </div>
            <div className="col-md-6 col-lg-4 col-xl-3 p-3">
              <DLCard
                title="This Week's Count"
                count={`${data.data?.last_week_transaction}`}
                image="share-icon"
                minHeight={"100px"}
              />
            </div>
          </div>
          <FilterCard
            startDate={true}
            endDate={true}
            statusOptions={[
              { name: "Status", value: "" },
              { name: "Success", value: "success" },
              { name: "Failed ", value: "failed" },
            ]}
            transactionOptions={[
              { name: "Transaction Type", value: "" },
              { name: "Credit", value: "credit" },
              { name: "Debit ", value: "debit" },
            ]}
            setCurrPageInfo={setCurrPageInfo}
            refetch={refetch}
          />
        </>
      )}
      <div className="trans-table">
        {isLoading ? (
          <Skeleton active />
        ) : //  data.data?.transactions.data.length > 0
        data ? (
          <div id="allTrans">
            <div className="opt">
              <h3>Recent Transactions</h3>
              <div className="opt-container">
                <div
                  className="opt-download download"
                  onClick={handlePdfDownload}
                >
                  <span>Download</span>
                  <DownloadIcon />
                </div>
                {/* <div
                  className='opt-download sort'
                  onClick={() => setShow(!show)}
                >
                  <span>Sort By</span>
                  <ArrowDropDown className='sort-icon' />
                  {show && (
                    <div className='sort--options'>
                      <span>Success</span>
                      <span>Failed</span>
                    </div>
                  )}
                </div> */}
                <div className="opt-download sort">
                  <Select
                    // placeholder='Status'
                    options={[
                      { name: "Sort by", value: "" },
                      { name: "Success", value: "success" },
                      { name: "Failed ", value: "failed" },
                    ]}
                    onChange={handleStatusChange}
                  />
                </div>
              </div>
            </div>
            <div className="loading">loading</div>
            <table className="table-borderless">
              <thead className="header">
                <tr>
                  <th></th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Transaction Reference</th>
                  <th>Source</th>
                  <th>Date</th>
                  <th>Amount</th>
                  <th className="text-center">Transaction Type</th>
                  {/* <th>Balance</th> */}
                  <th className="text-center">Status</th>
                </tr>
              </thead>
              <tbody>
                {data.data?.transactions.data?.map(
                  (item: any, index: number) => {
                    return (
                      <tr key={index}>
                        <td>
                          <div className="icon align-middle">
                            {item.type === "debit" ? (
                              <img src={debitImg} alt="debit" />
                            ) : (
                              <img src={creditImg} alt="credit" />
                            )}
                          </div>
                        </td>
                        <td>{item.owner_name}</td>
                        <td
                          className="break-all min-break-all"
                          style={{
                            textTransform: "lowercase",
                          }}
                        >
                          {item.owner_email}
                        </td>
                        <td
                          className="break-all"
                          style={{
                            textTransform: "uppercase",
                          }}
                        >
                          {item.reference}
                        </td>
                        <td className="break-all">
                          {item.transactionable_type.slice().split("\\")[2]}
                        </td>
                        <td className="break-all">
                          <Moment format="DD/MM/YYYY">{item.created_at}</Moment>
                        </td>
                        <td className="break-all">
                          {format4dpNumber(item.amount)}
                        </td>
                        <td className="text-center break-all">{item.type}</td>
                        {/* <td>{format4dpNumber(item?.balance ?? 'None')}</td> */}
                        <td className="text-center status">
                          <div
                            className={`${
                              item.status === "success"
                                ? "good"
                                : item.status === "pending"
                                ? "processing"
                                : "bad"
                            }`}
                          >
                            {item.status}
                          </div>
                        </td>
                      </tr>
                    );
                  }
                )}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="mt-4 pt-4">
            <NoData text="There are currently no transactions." />
          </div>
        )}
      </div>
      {isSuccess && (
        <Pagination
          transPerPage={data.data?.transactions?.per_page}
          totalTrans={data.data?.transactions?.total}
          currentPage={data.data?.transactions?.current_page}
          lastPage={data.data?.transactions?.last_page}
          setCurrPageInfo={setCurrPageInfo}
          // pageLimit={parseInt(data.data?.transactions?.per_page)}
          currPageInfo={currPageInfo}
          refetch={refetch}
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .top-header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    .search {
      margin-left: auto;
      min-width: 500px;
    }
  }

  #allTrans {
    padding: 1rem;
  }

  @media screen and (min-width: 130px) {
    .min-break-all {
      width: 500px;
      word-break: break-all;
    }
  }
  @media screen and (max-width: 1340px) {
    .break-all {
      width: 500px;
      word-break: break-all;
    }
  }
  .trans-table {
    display: flex;
    flex-direction: column;
    background: #ffffff;
    box-shadow: 0px 16px 37px rgba(188, 188, 188, 0.1);
    border-radius: 15px;
    padding: 2rem 1rem !important;
    font-size: 16px;
    overflow-x: auto;
    margin-top: 0.5rem;

    .opt {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem 0;

      h3 {
        font-weight: 600;
      }

      .opt-container {
        display: flex;
        align-items: center;
        gap: 0.5rem;

        .opt-download {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          padding: 10px;
          border-radius: 5px;
          font-size: 1.2rem;
          cursor: pointer;
          background: transparent;

          button {
            background: transparent;
            outline: none;
            border: 0;
          }
        }
        .download {
          color: #ffffff;
          background: #059157;
        }

        .sort {
          color: #ccc;
          background: transparent;
          position: relative;

          &:hover {
            background: #555555;
          }

          .sort-icon {
            stroke: #ccc;
          }
        }
      }
    }

    table {
      th,
      td {
        padding: 0.5rem;
      }
      td {
        color: #707070;
        text-transform: capitalize;
      }
    }
    .header {
      color: #333333;
      border-top: 1px solid #999999;
      border-bottom: 1px solid #999999;
    }
    .status {
      // width: 20%;
    }
    .bad,
    .good,
    .processing {
      color: #fff;
      border-radius: 5px;
      padding: 0.5rem;
    }
    .bad {
      background: #f15050;
    }
    .good {
      background: #059157;
    }
    .processing {
      background: #f0590a;
    }
    .amt {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    @media screen and (min-width: 500px) {
      .gd {
        margin: 0rem 2rem 0rem 0rem;
        padding: 0px;
      }
    }

    @media screen and (max-width: 500px) {
      .trans-table {
        overflow-x: auto;
        // width: fit-content;
        table {
          th,
          td {
            // font-size: 12px !important;
          }
        }
      }
    }
  }
`;

export default AllTransactions;
