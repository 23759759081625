import { Skeleton } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { ReactComponent as ActiveIcon } from "../../assets/icons/c-active.svg";
import { ReactComponent as InactiveIcon } from "../../assets/icons/c-inactive.svg";
import { ReactComponent as ShowIcon } from "../../assets/icons/eye-icon.svg";
import FilterCard from "../../components/FilterCard";
import Pagination, { CurrPageInfoTypes } from "../../components/Pagination";
import SearchBar from "../../components/SearchBar";
import Button from "../../components/bits/Button";
import NoData from "../../components/bits/NoData";
import { format4dpNumber } from "../../helpers/formatNumbers";
import { useGetCoopContributionQuery } from "../../redux/services/dashboard-services";
import { groupSavingsView } from "../../utils/routes";

// import { SwitchChangeEventHandler } from 'antd/lib/switch';
import DLCard from "./../../components/DLCard";

/* type CoopGrpContributionsTypes = {
  grpName: string;
  adminEmail: string;
  memberNo: number;
  targetAmt: number;
  dateCreated: string;
  maturityDate: string;
  frequency: string;
  grpStatus: 'active' | 'paused';
}; */

/* const data: CoopGrpContributionsTypes[] = [
  {
    grpName: 'TM30 Staff Coop',
    adminEmail: 'fola@gmail.com',
    memberNo: 5,
    targetAmt: 455000,
    dateCreated: '02/04/2022',
    maturityDate: '10/04/2022',
    frequency: 'monthly',
    grpStatus: 'active',
  },
  {
    grpName: 'Weekly Ajo',
    adminEmail: 'fola@gmail.com',
    memberNo: 4,
    targetAmt: 3000000,
    dateCreated: '02/04/2022',
    maturityDate: '10/04/2022',
    frequency: 'weekly',
    grpStatus: 'paused',
  },
]; */

const CoopGrpContributions = () => {
  const [currPageInfo, setCurrPageInfo] = useState<CurrPageInfoTypes>({
    pageNo: 1,
    pageSize: 10,
    pageTotalCount: 10,
    pageCount: 1,
    status: "",
    searchTerm: "",
    startDate: null,
    endDate: null,
    startDateString: "",
    endDateString: "",
  });
  const navigate = useNavigate();

  const totalVirtualUsers = 364;
  const { data, isLoading, refetch, isSuccess, isError, error }: any =
    useGetCoopContributionQuery({
      pageNo: currPageInfo.pageNo,
      pageSize: currPageInfo.pageSize,
      status: currPageInfo.status,
      searchTerm: currPageInfo.searchTerm,
      duration:
        currPageInfo.startDate && currPageInfo.endDate
          ? `${moment(currPageInfo.startDate).format("YYYY-MM-DD")},${moment(
              currPageInfo.endDate
            ).format("YYYY-MM-DD")}`
          : "",
    });

  useEffect(() => {
    refetch();
  }, [refetch]);

  return (
    <Wrapper>
      {isError ? (
        <div className="mt-8 pt-8">
          <NoData text={`${error.status}. Try Again`} />
        </div>
      ) : (
        <>
          {isSuccess && (
            <>
              <div className="top-header">
                <h2 style={{ fontWeight: 600, marginBottom: "1rem" }}>
                  Cooperative Group Contributions
                </h2>
                <div className="search">
                  <SearchBar
                    width={"90%"}
                    setCurrPageInfo={setCurrPageInfo}
                    refetch={refetch}
                    value={currPageInfo.searchTerm}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 col-lg-4 col-xl-3 p-3">
                  <DLCard
                    title="Total Cooperative Group Contributions"
                    amount={data.data?.total_coop}
                    bColor={"#33277B"}
                    image="wallet-white"
                    RealUsersAmount={data.data?.total_coop_users}
                    VirCommAmount={data.data?.total_coop_virtual}
                    minHeight={"200px"}
                  />
                </div>
                <div className="col-md-6 col-lg-4 col-xl-3 p-3">
                  <DLCard
                    title="Total Users"
                    count={`${totalVirtualUsers + data.data?.total_users}`}
                    image="group-purple"
                    RealUsersCount={`${data.data?.total_users}`}
                    VirUserCount={`${totalVirtualUsers}`}
                    minHeight={"200px"}
                  />
                </div>
                <div className="col-md-6 col-lg-4 col-xl-3 p-3">
                  <DLCard
                    title="Total Groups"
                    count={`${data.data?.total_group}`}
                    image="group-purple"
                    minHeight={"200px"}
                  />
                </div>
              </div>
              <FilterCard
                startDate={true}
                endDate={true}
                statusOptions={[
                  { name: "Status", value: "" },
                  { name: "Matured", value: "matured" },
                  { name: "Not Matured ", value: "paused" },
                ]}
                setCurrPageInfo={setCurrPageInfo}
                refetch={refetch}
              />
            </>
          )}
          <div className="trans-table">
            {isLoading ? (
              <Skeleton active />
            ) : data.data.group_savings.data.length > 0 ? (
              <>
                <table className="table-borderless">
                  <thead className="header">
                    <tr>
                      <th>
                        <input
                          type="checkbox"
                          className="checkbox"
                          // onClick={() =>
                          //   setIsChecked(prevState => ({
                          //     ...prevState,
                          //     checkAll: !prevState,
                          //   }))
                          // }
                          // checked={isChecked.checkAll}
                        />
                      </th>
                      <th>Group Name</th>
                      <th>Admin's Email</th>
                      <th className="text-center">No of Members</th>
                      <th className="text-center">Amt. Per Member</th>
                      <th>Target Amount</th>
                      <th>Date Created</th>
                      <th>Maturity Date</th>
                      <th>Frequency</th>
                      <th className="text-center">Group Status</th>
                      <th>View</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.data.group_savings.data?.map(
                      (item: any, index: number) => {
                        return (
                          <tr key={index}>
                            <td>
                              <input
                                type="checkbox"
                                className="checkbox"
                                // onClick={() =>
                                //   setIsChecked(prevState => ({
                                //     ...prevState,
                                //     checked: !prevState.checked,
                                //   }))
                                // }
                                // checked={isChecked.checked}
                              />
                            </td>
                            <td>{item.name}</td>
                            <td
                              className="break-all"
                              style={{
                                textTransform: "lowercase",
                              }}
                            >
                              {item.owner_email}
                            </td>
                            <td className="text-center">
                              {item.no_of_participants}
                            </td>
                            <td>{format4dpNumber(item.amount)}</td>
                            <td>{format4dpNumber(item.amount)}</td>
                            <td>
                              <Moment format="DD/MM/YYYY">
                                {item.created_at}
                              </Moment>
                            </td>
                            <td>
                              <Moment format="DD/MM/YYYY">
                                {item.end_date}
                              </Moment>
                            </td>
                            <td className="text-center">{item.plan}</td>
                            <td className="text-center">
                              {item.status === "matured" ? (
                                <ActiveIcon />
                              ) : (
                                <InactiveIcon />
                              )}
                            </td>
                            <td
                              className="status"
                              onClick={() => navigate(`../${groupSavingsView}`)}
                            >
                              <ShowIcon />
                            </td>
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </table>
              </>
            ) : (
              <div className="mt-4 pt-4">
                <NoData text="There are currently no blacklisted accounts." />
              </div>
            )}
          </div>
          {isSuccess && (
            <>
              <div className="ft mt-4">
                <Button fontSize="16px" className="me-4">
                  Activate
                </Button>
                <Button
                  border="#F12F2F"
                  color="#F12F2F"
                  fontSize="16px"
                  bg="transparent"
                  className="me-4"
                >
                  Deactivate
                </Button>
              </div>
              <Pagination
                transPerPage={data.data?.group_savings?.per_page}
                totalTrans={data.data?.group_savings?.total}
                currentPage={data.data?.group_savings?.current_page}
                lastPage={data.data?.group_savings?.last_page}
                setCurrPageInfo={setCurrPageInfo}
                // pageLimit={parseInt(data.data?.group_savings?.per_page)}
                currPageInfo={currPageInfo}
                refetch={refetch}
              />
            </>
          )}
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .top-header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    .search {
      margin-left: auto;
      min-width: 500px;
    }

    @media screen and (max-width: 1300px) {
      .search {
        min-width: 400px;
      }
    }
  }

  /*   @media screen and (max-width: 1340px) {
    .break-all {
      width: 50rem;
      word-break: break-all;
    }
  }
 */
  .trans-table {
    display: flex;
    flex-direction: column;
    background: #ffffff;
    box-shadow: 0px 16px 37px rgba(188, 188, 188, 0.1);
    border-radius: 15px;
    padding: 2rem 1rem !important;
    font-size: 16px;
    overflow-x: auto;
    margin-top: 0.5rem;

    table {
      th,
      td {
        padding: 0.5rem;
      }
      td {
        color: #707070;
        text-transform: capitalize;
        font-size: 0.9rem;
      }
      .checkbox {
        height: 1.3rem;
        width: 1.3rem;
        display: flex;
      }
    }
    .header {
      color: #333333;
      border-top: 1px solid #999999;
      border-bottom: 1px solid #999999;
    }
    .status {
      // width: 20%;
      text-transform: capitalize;
      cursor: pointer;
    }

    @media screen and (min-width: 500px) {
      .gd {
        margin: 0rem 2rem 0rem 0rem;
        padding: 0px;
      }
    }

    @media screen and (max-width: 500px) {
      .trans-table {
        overflow-x: auto;
        // width: fit-content;
        table {
        }

        th,
        td {
          // font-size: 12px !important;
        }
      }
    }
  }
  @media screen and (min-width: 1200px) {
    .col-xl-3 {
      flex: 0 0 auto;
      width: 32%;
    }
  }

  @media screen and (max-width: 1300px) {
    .col-xl-3 {
      flex: 0 0 auto;
      width: 25rem;
    }
  }
`;

export default CoopGrpContributions;
