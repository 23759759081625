import { Skeleton } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import Moment from 'react-moment';
import styled from 'styled-components';

import DLCard from '../../components/DLCard';
import FilterCard from '../../components/FilterCard';
import Pagination, { CurrPageInfoTypes } from '../../components/Pagination';
import SearchBar from '../../components/SearchBar';
import NoData from '../../components/bits/NoData';
import { format4dpNumber } from '../../helpers/formatNumbers';
import { useGetPurseQuery } from '../../redux/services/dashboard-services';

/* type StashTypes = {
  name: string;
  email: string;
  phone: string;
  startDate: string;
  lastSavedDate: string;
  lastSavedAmt: number;
  frequency: string;
  stashBalance: number;
}; */

/* const data: StashTypes[] = [
  {
    name: 'Folashade',
    email: 'fola@gmail.com',
    phone: '07012456987',
    startDate: '02/04/2022',
    lastSavedDate: '02/10/2022',
    lastSavedAmt: 3000,
    frequency: 'weekly',
    stashBalance: 5400,
  },
  {
    name: 'Zion',
    email: 'zion@gmail.com',
    phone: '07012456987',
    startDate: '02/11/2021',
    lastSavedDate: '01/10/2022',
    lastSavedAmt: 1000,
    frequency: 'monthly',
    stashBalance: 3000,
  },
]; */

const Purse = () => {
  const [currPageInfo, setCurrPageInfo] = useState<CurrPageInfoTypes>({
    pageNo: 1,
    pageSize: 10,
    pageTotalCount: 10,
    pageCount: 1,
    status: '',
    searchTerm: '',
    startDate: null,
    endDate: null,
    startDateString: '',
    endDateString: '',
  });

  const { data, isLoading, refetch, isSuccess }: any = useGetPurseQuery({
    pageNo: currPageInfo.pageNo,
    pageSize: currPageInfo.pageSize,
    status: currPageInfo.status,
    searchTerm: currPageInfo.searchTerm,
    duration:
      currPageInfo.startDate && currPageInfo.endDate
        ? `${moment(currPageInfo.startDate).format('YYYY-MM-DD')},${moment(
            currPageInfo.endDate
          ).format('YYYY-MM-DD')}`
        : '',
  });

  useEffect(() => {
    refetch();
  }, [refetch]);

  return (
    <Wrapper>
      {isSuccess && (
        <>
          <div className='top-header'>
            <h2 style={{ fontWeight: 600, marginBottom: '1rem' }}>Purse</h2>
            <div className='search'>
              <SearchBar
                width={'90%'}
                setCurrPageInfo={setCurrPageInfo}
                refetch={refetch}
                value={currPageInfo.searchTerm}
              />
            </div>
          </div>

          <div className='row'>
            <div className='col-md-6 col-lg-4 col-xl-3 p-3'>
              <DLCard
                title='Total Savings'
                amount={data.data?.total_savings}
                bColor={'#33277B'}
                image='wallet-white'
                minHeight={'100px'}
              />
            </div>
            <div className='col-md-6 col-lg-4 col-xl-3 p-3'>
              <DLCard
                title='Total Users'
                count={data.data?.total_users}
                image='group-purple'
                minHeight={'100px'}
              />
            </div>
          </div>
          {data.data?.wallet?.data.length > 0 && (
            <FilterCard
              startDate={true}
              endDate={true}
              setCurrPageInfo={setCurrPageInfo}
              refetch={refetch}
            />
          )}
        </>
      )}
      <div className='trans-table'>
        {isLoading ? (
          <Skeleton active />
        ) : data.data?.wallet?.data.length > 0 ? (
          <>
            <table className='table-borderless'>
              <thead className='header'>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone Number</th>
                  <th>Start Date</th>
                  <th className='text-center'>Last Saved Date</th>
                  <th className='text-center'>Amount Last Saved</th>
                  {/* <th>Frequency</th> */}
                  <th>Purse Balance</th>
                </tr>
              </thead>
              <tbody>
                {data?.data?.wallet?.data.map((item: any, index: number) => {
                  return (
                    <tr key={index}>
                      <td>{`${item.last_name} ${item.name}`}</td>
                      <td
                        className='break-all'
                        style={{
                          textTransform: 'lowercase',
                        }}
                      >
                        {item.email}
                      </td>
                      <td>{item.phone}</td>
                      <td>
                        <Moment format='DD/MM/YYYY'>
                          {item.wallet.created_at}
                        </Moment>
                      </td>
                      <td className='text-center'>
                        <Moment format='DD/MM/YYYY'>
                          {item.wallet.updated_at}
                        </Moment>
                      </td>
                      <td className='text-center'>
                        {format4dpNumber(item.wallet.balance)}
                      </td>
                      {/* <td>{item.frequency ?? 'monthly'}</td> */}
                      <td className='text-center'>
                        {format4dpNumber(item.wallet.balance)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </>
        ) : (
          <div className='mt-4 pt-4'>
            <NoData text='There are currently no blacklisted accounts.' />
          </div>
        )}
      </div>
      {isSuccess && (
        <Pagination
          transPerPage={data.data?.wallet?.per_page}
          totalTrans={data.data?.wallet?.total}
          currentPage={data.data?.wallet?.current_page}
          lastPage={data.data?.wallet?.last_page}
          setCurrPageInfo={setCurrPageInfo}
          // pageLimit={parseInt(data.data?.wallet?.per_page)}
          currPageInfo={currPageInfo}
          refetch={refetch}
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .top-header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    .search {
      margin-left: auto;
      min-width: 500px;
    }
  }

  @media screen and (max-width: 1340px) {
    .break-all {
      width: 500px;
      word-break: break-all;
    }
  }

  .trans-table {
    display: flex;
    flex-direction: column;
    background: #ffffff;
    box-shadow: 0px 16px 37px rgba(188, 188, 188, 0.1);
    border-radius: 15px;
    padding: 2rem 1rem !important;
    font-size: 16px;
    overflow-x: auto;
    margin-top: 0.5rem;

    table {
      th,
      td {
        padding: 0.5rem;
      }
      td {
        text-transform: capitalize;
        font-size: 1rem;
        color: #707070;
      }
    }
    .header {
      color: #333333;
      border-top: 1px solid #999999;
      border-bottom: 1px solid #999999;
    }
    .status {
      // width: 20%;
    }

    @media screen and (min-width: 500px) {
      .gd {
        margin: 0rem 2rem 0rem 0rem;
        padding: 0px;
      }
    }

    @media screen and (max-width: 500px) {
      .trans-table {
        overflow-x: auto;
        // width: fit-content;
        table {
        }
        th,
        td {
          // font-size: 12px !important;
        }
      }
    }
  }
`;

export default Purse;
