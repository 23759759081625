import React, { useState } from "react";
import { Navigate, Outlet, useOutletContext } from "react-router-dom";
import styled from "styled-components";

import LNavbar from "../../components/LNavbar";
import ModalA from "../../components/ModalA";
import TopNavbar from "../../components/TopNavbar";
import WarningA from "../../components/WarningA";
import { logoutUser } from "../../helpers/authHelper";
import { getParams } from "../../helpers/otherHelpers";
// import { getParams } from "../../helpers/otherHelpers";

type ContextType = { show: boolean; setShow: Function };

// type ContextType2 = { showNav: boolean; setShowNav: Function };

type AuthWrapperProps = {
  show: boolean;
};

export function useShow() {
  return useOutletContext<ContextType>();
}

// export function useShowNav() {
//   return useOutletContext<ContextType2>();
// }

const AuthIndex = () => {
  const [show, setShow] = useState<boolean>(false);
  const [logoutOpen, setLogoutOpen] = useState<boolean>(false);

  const events = [
    "load",
    "mousemove",
    "click",
    "scroll",
    "keypress",
    "keydown",
    "DOMMouseScroll",
    "mousewheel",
    "mousedown",
    "touchstart",
    "touchmove",
    "MSPointerDown",
    "MSPointerMove",
    "visibilitychange",
  ];

  let timerRef = React.useRef<any>(null);

  // this function sets the timer that open the logout modal after 4 minutes
  const handleLogoutTimer = () => {
    timerRef.current = setTimeout(() => {
      // clears any pending timer.
      resetTimer();
      // Listener clean up. Removes the existing event listener from the window
      Object.values(events).forEach((item) => {
        window.removeEventListener(item, resetTimer);
      });
      // opens the modal
      setLogoutOpen(true);
    }, 4 * 60 * 1000);
  };

  // when component mounts, it adds an event listener to the window
  // each time any of the event is triggered, i.e on mouse move, click, scroll, keypress etc, the timer to opens the modal after 4 minutes of inactivity.
  // However, if none of the event is triggered within 4 minutes, that means app is inactive, the modal automatically opens.

  // this resets the timer if it exists.
  const resetTimer = () => {
    if (timerRef.current) clearTimeout(timerRef.current);
  };

  React.useEffect(() => {
    Object.values(events).forEach((item) => {
      window.addEventListener(item, () => {
        resetTimer();
        handleLogoutTimer();
      });
    });
    // eslint-disable-next-line
  }, []);

  const Authenticated = () => {
    return (
      <>
        <AuthIndex.Wrapper show={show}>
          <div className="left" onClick={() => setShow(false)}>
            <div className="l-nav">
              <LNavbar setOpen={setShow} />
            </div>
          </div>
          <div className="right">
            <TopNavbar />
            <div className="right-content">
              <Outlet />
            </div>
          </div>
        </AuthIndex.Wrapper>
        <ModalA isShown={logoutOpen} hide={() => {}}>
          <WarningA
            warningTitle={"Confirm Logout"}
            closeModal={() => {
              setLogoutOpen(false);
            }}
            confirm={() => logoutUser()}
            confirmText={"OK"}
          />
        </ModalA>
      </>
    );
  };

  // return <Authenticated />;
  // // for testing, use below when token is ready

  return localStorage.getItem("token") ? (
    <Authenticated />
  ) : (
    <Navigate
      to={
        window.location.pathname !== "/admin"
          ? "/" + getParams(window.location)
          : `/`
      }
      replace={true}
    />
  );
};

AuthIndex.Wrapper = styled.div`
  display: flex;
  // display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: row;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;

  .left {
    /* position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0; */
    /* width: 320px; */
    flex: 0.2;
    padding: 2rem 0.5rem 4rem 0.5rem;
    background: #fff;
    /* overflow-y: auto; */
  }
  .right {
    /* width: calc(100vw - 350px); */
    // background: #e5e5e5;
    /* position: absolute; */
    /* top: 0;
    left: 20%; */
    flex: 0.8;
    /* width: calc(100vw - 320px); */
    height: -webkit-fill-available;
    /* overflow-x: auto; */
    overflow-y: auto;
    width: fit-content;

    /* min-height: 100vh; */

    .right-content {
      padding: 1rem 2rem;
    }
  }

  @media screen and (max-width: 1160px) {
    flex-direction: column-reverse;
    .left {
      position: fixed;
      top: 0;
      width: 100vw;
      // border: 1px solid #f2f2f2;
      z-index: 20;
      background: rgba(0, 0, 0, 0.75);
      min-height: 100vh;
      // height: -webkit-fill-available;
      transition: all 0.3s linear;
      display: block;
      left: ${({ show }: AuthWrapperProps) => (show ? "0" : "-100vw")};
      border: none;
      // overflow-y: auto;
      .l-nav {
        width: 350px;
        border-radius: 20px;
      }
    }
    .right {
      width: 100vw;
      min-height: 100vh;
    }
  }
`;

export default AuthIndex;
