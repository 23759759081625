import styled from 'styled-components';

interface ButtonType {
  color?: string;
  bg?: string;
  border?: string;
  block?: boolean;
  fontSize?: string;
  align?: string;
}
const Button = styled.button`
  color: ${({ color }: ButtonType) => color ?? '#ffffff'};
  // font-weight: 600;
  font-size: ${({ fontSize }: ButtonType) => fontSize ?? '20px'};
  display: inline-block;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: ${({ bg }: ButtonType) => bg ?? '#059157'};
  border: 1px solid ${({ border }: ButtonType) => border ?? 'transparent'};
  padding: 0.375rem 1.5rem;
  //   font-size: 2rem;
  border-radius: 5px;
  // border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  ${({ block }: ButtonType) => block && 'width: -webkit-fill-available;'};
  cursor: pointer;

  &:disabled {
    background: #c7c6c9;
    border: 1px solid #ffffff;
    color: rgba(255, 255, 255, 0.8);
    cursor: not-allowed;
    pointer-events: none;
  }
`;

export default Button;
