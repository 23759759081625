import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import NoDataImg from '../../assets/img/no-data.png';

interface NoDataProps {
  Click?: Function;
  text?: string;
  link?: string;
  reload?: any;
}

const NoData = (props: NoDataProps) => {
  const { text, link, reload } = props;
  return (
    <Wrapper>
      <img src={NoDataImg} alt='No data available' />
      <div className='mt-4'>
        {text}&nbsp;{link && <Link to={link}>here</Link>}
        {reload && <button onClick={reload}>refresh here</button>}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // margin-top: 6rem;
  text-transform: none;

  button {
    border: none;
    outline: 0;
    background: transparent;
    color: #343869;

    &:hover {
      color: #1b226c;
    }
  }
`;
export default NoData;
