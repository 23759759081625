import React from 'react';
import styled from 'styled-components';
import { format4dpNumber } from '../helpers/formatNumbers';

interface DataType {
  membersName: string;
  payOutOrder: number;
  payoutDate: string;
  status: string;
  payStatus: string;
}

const data: DataType[] = [
  {
    membersName: 'Temitope A ',
    payOutOrder: 2,
    payoutDate: '02/04/2022',
    status: 'collected',
    payStatus: 'collected',
  },
  {
    membersName: 'Temitope A ',
    payOutOrder: 2,
    payoutDate: '02/04/2022',
    status: 'pending',
    payStatus: 'pending',
  },
  {
    membersName: 'Temitope A ',
    payOutOrder: 2,
    payoutDate: '02/04/2022',
    status: 'pending',
    payStatus: 'pending',
  },
];

const MembersDetails = () => {
  return (
    <Wrapper>
      <h4 className='heading'>Members Details</h4>
      <table className='mem-table'>
        <thead className='header'>
          <tr>
            <th>Members Name</th>
            <th>Payout Order</th>
            <th>Payout Date</th>
            <th> Status </th>
            <th>Payout Status </th>
          </tr>
        </thead>
        <tbody>
          {data.map((item: any, index: any) => (
            <tr key={index}>
              <td>{item.membersName}</td>
              <td>{item.payOutOrder}</td>
              <td>{item.payoutDate}</td>

              <td>
                <div
                  className={`options ${
                    item.status === 'pending'
                      ? 'option--failed'
                      : 'option--success'
                  }`}
                ></div>
              </td>
              <td
                className={`btn ${
                  item.payStatus === 'pending' ? 'failed' : 'success'
                }`}
              >
                {item.payStatus}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .heading {
    font-size: 1.6rem;
    font-weight: 600;
    color: #585757;
  }

  .mem-table {
    width: 100%;
    box-shadow: 0px 16px 37px rgba(188, 188, 188, 0.1);
    border-radius: 15px;
    text-transform: capitalize;
    padding: 1rem;

    .header {
      color: #707070;
      font-size: 1.1rem;
      border-bottom: 1px solid #707070;
      border-top: 1px solid #707070;
    }
    th {
      padding: 0.5rem;
    }
    td {
      padding: 0.5rem;
      color: #707070;
      font-size: 0.95rem;
      font-weight: 600;
    }

    .options {
      border-radius: 50%;
      width: 20px;
      height: 20px;

      &.option--failed {
        background: #ea0505b2;
      }
      &.option--success {
        background: #059157;
      }
    }

    .btn {
      width: 7rem;
      margin: 0.2rem 0.1rem;
      display: flex;
      align-content: center;
      justify-content: center;

      &.failed {
        color: #f2990a;
        background: #fdf0da;
        border-radius: 5px;
        padding: 0.5rem;
      }
      &.success {
        color: #059157;
        background: rgba(207, 232, 222, 0.5);
        border-radius: 5px;
        padding: 0.5rem;
      }
    }
  }
`;

export default MembersDetails;
