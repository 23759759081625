import React, { useEffect, useState } from "react";
import styled from "styled-components";

import {
  GetOverview,
  useGetDashBoardSummaryQuery,
} from "../redux/services/dashboard-services";

import DLanding from "./DLanding";
import RecentTransactions from "./RecentTrans";
import Revenues from "./Revenues";
import Users from "./Users";
import UsersAnalytics from "./UsersAnalytics";
import LoadingRoller from "./bits/LoadingRoller";
import NoData from "./bits/NoData";

const Dashboard = () => {
  const [overviewData, setOverviewData] = useState<GetOverview>({
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
  });
  // const { data = [], isLoading, refetch }: any = useGetDashBoardSummaryQuery();

  const {
    data = [],
    isLoading,
    refetch,
  }: any = useGetDashBoardSummaryQuery({
    month: overviewData.month,
    year: overviewData.year,
  });

  useEffect(() => {
    refetch();
  }, [data, refetch]);

  return (
    <Wrapper>
      {isLoading ? (
        <div className="loading">
          <LoadingRoller />
        </div>
      ) : data ? (
        <>
          <h2>Dashboard</h2>
          <DLanding dashboard={data?.dashboard} />
          <hr className="line" />
          <h3>Revenue</h3>
          <Revenues revenue={data?.revenue} />
          <hr className="line" />
          <h3>Users</h3>
          <Users user={data?.users} />
          <h3 style={{ marginTop: "1rem" }}>Users Analytics</h3>
          <UsersAnalytics
            usersAnalytics={data?.user_analytics}
            overviewData={overviewData}
            setOverviewData={setOverviewData}
          />
          <RecentTransactions
            recentTransactions={data?.recent_transactions}
            type="recent"
          />
        </>
      ) : (
        <div className="loading">
          <NoData
            text="No internet Connection."
            // link='/user/savings'
            reload={() => refetch()}
          />
        </div>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  /* height: 100vh; */
  height: 90vh;
  // background: #e5e5e5;
  h2,
  h3 {
    font-weight: 600;
  }
  .line {
    border: 1px solid #cccccc;
  }

  .loading {
    height: 90vh;

    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export default Dashboard;
